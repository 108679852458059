import * as session from "./session";
import axios from "axios";

const serialize = function(obj, prefix) {
	var str = [],
	  p;
	for (p in obj) {
	  if (obj.hasOwnProperty(p)) {
		var k = prefix ? prefix + "[" + p + "]" : p,
		  v = obj[p];
		str.push((v !== null && typeof v === "object") ?
		  serialize(v, k) :
		  encodeURIComponent(k) + "=" + encodeURIComponent(v));
	  }
	}
	return str.join("&");
  }	

export const fetch = async (url, method = "GET", data = {}, query, files = {}) => {
	const jwtToken = session.getJwtToken();

	const querySerialized = query ? `?${serialize(query)}` : ''

	const result = await axios({
		method,
		url: `${url}${querySerialized}`,
		data,
		headers: {
			Authorization: jwtToken ? `Bearer ${jwtToken}` : undefined,
		},
	});

	return result.data;
};



export default fetch;