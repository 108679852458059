import React, { useState, useMemo, useEffect }from "react";
import Table from "../../components/Table";
import { deleteGabarits, fetchBase64Gabarit, fetchGabarits } from "../../services/crm";
import { Button } from "reactstrap";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import { toast } from "react-toastify";

const GabaritsList = (props) => {
    const {userRoles, commandId, isSaler, userId} = props
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const [pagination, setPagination] = useState();
    const [data, setData] = useState([]);

    const loadData = async () => {
        const { data, ...paginationParams } = await fetchGabarits();
        setData(data)
        setPagination(paginationParams)
    }   

    const handleDelete = async (props) => {
      try{
          const confirmed = window.confirm(
              `Êtes vous sûr de vouloir supprimer le media ${props.name} ?`
            );
      
            if (confirmed) {         
              await deleteGabarits(props.id)
              window.location.reload()
              toast.success("Le media a été supprimé avec succès");

            }
      }catch(error){
          console.log(error)
      }
    };

    const columns = useMemo(() => [
        {
            id: "name",
            accessor: "name",
            Header: "Nom",
        },
        {
            id: "format",
            accessor: "format",
            Header: "Format",
        },
        {
            id: "weight",
            accessor: "weight",
            Header: "Poid",
        },
        {
        id: "action",
        Header: "Action",
        accessor: "action",
        Cell: (row) => {
          const handleDlGabarit = async () => {
            try{
                const file = await fetchBase64Gabarit(row.row.original.id)
                var a = document.createElement("a");
                a.href = "data:image/png;base64," + file;
                a.download = row.row.original.name;
                a.click();
            }catch(error){
                toast.error("Une erreur est survenue")
            }
        }
          return(
            <div className={"text-center"}>
                <Button
                  onClick={() => handleDlGabarit()}
                  color={"transparent"}
                  >
                  <i className={"fas fa-cloud-download-alt fa-xl"} />
                </Button>
                {isAdmin ?
                  <Button
                    onClick={() => handleDelete(row.row.original)}
                    color={"transparent"}
                  >
                    <i className={"far fa-trash-alt"} />
                  </Button>
                : null}
            </div>
          )
        }
      },

    ])
    return(
        <>
            <Table
            className="dashMarg"
            data={data}
            columns={columns}
            loadData={loadData}
            paginated
            pagination={pagination}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(GabaritsList);