import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { fetchProductTypes } from "../../services/pim";

const ProductTypeSelection = (props) => {
    const [types, setTypes] = useState()

    useEffect(() => {
       getAllTypes() 
    }, [])

    const getAllTypes = async () => {
        try{
            const data = await fetchProductTypes()
            const types = data.data
            const typeList = types.map((type, index) => {
                return(
                    <>
                        <button className="iconSelection2" onClick={() => props.handleClickType(type.baseRef)}>
                            <img src={type.picto} alt="wtf" className="tunnelPicto"></img>
                            <label className="labelPicto">{type.label}</label>
                        </button>
                    </>
                )
            })
            setTypes(typeList)
        }catch(error){
            console.log(error)
        }
    }

    return(
        <>
            <Row>
                {types}
            </Row>
        </>
    )
}

export default ProductTypeSelection;