import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { WhFieldTxt, WhFieldSelect, WhFieldSelect2 ,WhFieldFile} from "../../../components/Form/whFields";
import {
	ModalBody,
	ModalFooter,
	Card,
	CardHeader,
	CardBody,
	Col,
	Form,
	Alert,
	FormGroup,
	InputGroup,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Loading from "../../../components/Loading";
import { connect } from "react-redux";
import * as mediaService from "../../../services/media";

class FormItem extends React.Component {
	state = {
		options: [],

	};
	componentDidMount() {
		this.getFiles()
	}

	getFiles = () =>{
		return mediaService.search().then(data => {
			let lst =  data['hydra:member'].map((el, index) => {
				console.log(el)
				let res = el;
				res.value = el['@id'];
				res.label = el.title;
				res.name = el.title;
				return res;
			});

			console.log(lst);
			this.setState({options : lst})
			// return { options: lst };
		});
	}

	render() {
		const { error, handleSubmit, submitting } = this.props;
		const {options} = this.state
		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					{submitting && <Loading />}

					<Field
						label="Quantité : *"
						name="quantity"
						step="1"
						component={WhFieldTxt}
						type="number"
						type="text"
						labelSize="6"
						inputSize="6"
					/>

					<Field
						label="Référence commande interne (facultatif) : "
						name="internRef"
						component={WhFieldTxt}
						type="text"
						labelSize="6"
						inputSize="6"
					/>

					<Field
						label="Nom client final (facultatif) : "
						name="finalCustomer"
						component={WhFieldTxt}
						type="text"
						labelSize="6"
						inputSize="6"
					/>

					<Field
						label="Commentaire : "
						name="comment"
						component={WhFieldTxt}
						type="textarea"
						labelSize="6"
						inputSize="6"
					/>

					<Field
						label="Choississez votre fichier"
						name="media"
						component={WhFieldFile}
						type="text"
						placeholder="Nom du bloc"
					/>

					<Card>
						<CardHeader>INFORMATION LIVRAISON</CardHeader>
						<CardBody>
							<Field
								label="Nom destinataire : *"
								name="lastName"
								component={WhFieldTxt}
								type="text"
								labelSize="3"
								inputSize="8"
							/>
							<Field
								label="Prénom : *"
								name="firstName"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>
							<Field
								label="Adresse : *"
								name="deliveryAddress.street"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>
							<Field
								label="Code postal : *"
								name="deliveryAddress.zipCode"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>

							<Field
								label="Ville : *"
								name="deliveryAddress.city"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>

							<Field
								label="Pays : *"
								name="deliveryAddress.country"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>
							<Field
								label="Complément / Détail(s) livraison : "
								name="deliveryAddress.complement"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>
							<Field
								label="Téléphone : "
								name="deliveryAddress.phone"
								component={WhFieldTxt}
								type="text"
								labelSize="4"
								inputSize="8"
							/>
						</CardBody>
					</Card>

					<FormGroup row>
						<Col xs="8">Adresse de facturation identique :</Col>
						<Col xs="4">
							<InputGroup>
								<Field
									name="isSameAddress"
									component={WhFieldTxt}
									type="checkbox"
									onChange={this.toggle}
								/>
							</InputGroup>
						</Col>
					</FormGroup>

					{!this.props.isSameAddress && (
						<Card>
							<CardHeader>Adresse de facturation</CardHeader>
							<CardBody>
								<Field
									label="Adresse : *"
									name="billAddress.street"
									component={WhFieldTxt}
									type="text"
									labelSize="4"
									inputSize="8"
								/>

								<Field
									label="Code postal : *"
									name="billAddress.zipCode"
									component={WhFieldTxt}
									type="text"
									labelSize="4"
									inputSize="8"
								/>

								<Field
									label="Ville : *"
									name="billAddress.city"
									component={WhFieldTxt}
									type="text"
									labelSize="4"
									inputSize="8"
								/>

								<Field
									label="Pays : *"
									name="billAddress.country"
									component={WhFieldTxt}
									type="text"
									labelSize="4"
									inputSize="8"
								/>

								<Field
									label="Complément : "
									name="billAddress.complement"
									component={WhFieldTxt}
									type="text"
									labelSize="4"
									inputSize="8"
								/>
							</CardBody>
						</Card>
					)}

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormItem = reduxForm({
	form: "FormItem",
})(FormItem);

const selector = formValueSelector("FormItem"); // <-- same as form name

FormItem = connect(state => {
	const isSameAddress = selector(state, "isSameAddress");

	return {
		isSameAddress,
	};
})(FormItem);

export default FormItem;
