import { useState } from "react";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import {
  createUser,
  deleteUser,
  fetchUsers,
  updateUser,
} from "../../services/iam";
import { fetchOrganisations } from "../../services/crm";

const useUserService = ({ selectedData, toggle, selectedClientValue, selectedOrgaValue }) => {
  const [pagination, setPagination] = useState();
  const [data, setData] = useState([]);

  const loadData = async (pageNumber, limit = 50) => {
    const query = {
      $skip: (pageNumber - 1) * limit,
      $limit: limit,
    };
    
    if(selectedOrgaValue){
      query.organisationId = selectedOrgaValue.value
    }
    if(selectedClientValue){
      query.id = selectedClientValue.value
    }
    try {
      const { data, ...paginationParams } = await fetchUsers(query);

      setData(data);
      setPagination(paginationParams);
    } catch (error) {
      toast.error("Erreur dans le chargement des données");
    }
  };

  const onDelete = async (user) => {
    try {
      const confirmed = window.confirm(
        `Êtes vous sûr de vouloir supprimer l'utilisateur ${user.firstname} ${user.lastname} ?`
      );

      if (confirmed) {
        await deleteUser(user.id);

        loadData(1);
        toast.success("L'utilisateur a été supprimé avec succès");
      }
    } catch (error) {
      toast.error("Erreur");
    }
  };

  const onSubmit = async (newData) => {
    const payload = {
      ...newData,
      organisationId: newData.organisation.value,
    };
    try {
      if (selectedData) {
        await updateUser(selectedData.id, payload);
      } else {
        await createUser({ ...payload, password: "" });
      }

      loadData(1);
      toggle();
      toast.success("Enregistrement réussi");
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  return {
    pagination,
    data,
    loadData,
    onDelete,
    onSubmit,
  };
};

export default useUserService;
