import React, { Component } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class FieldTranslate extends Component {
	render = () => {
		const { name, translatable, local } = this.props;

		if (local === translatable) {
			return (
				<Field
					{...this.props}
					style={{
						backgroundSize: "20px",
						backgroundImage: `url("/img/${translatable}.png")`,
						backgroundRepeat: " no-repeat ",
						backgroundPosition: "right top",
					}}
				/>
			);
		} else {
			let newName;
			const path = name.split(".");

			if (path.length === 1) {
				newName = `translate.${translatable}.${name}`;
			} else {
				newName = path[path.length - 1];
				path.pop();
				path.join(".");

				newName = `${path}.translate.${translatable}.${newName}`;
			}

			return (
				<Field
					{...this.props}
					name={newName}
					style={{
						backgroundSize: "20px",
						backgroundImage: `url("/img/${translatable}.png")`,
						backgroundRepeat: " no-repeat ",
						backgroundPosition: "right top",
					}}
				/>
			);
		}
	};
}

FieldTranslate.defaultProps = {
	local: "fr",
	translatable: null,
};

FieldTranslate.propTypes = {
	name: PropTypes.string.isRequired,
	local: PropTypes.string,
	translatable: PropTypes.string,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(FieldTranslate);
