import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, getFormInitialValues, reduxForm } from "redux-form";
import * as customerService from "../../services/costumer";

import {
  WhFieldTxt,
  WhFieldSelect2,
  WhFieldSelectAsync,
  WhFile,
  WhFieldTranslate,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { fetchOrganisations } from "../../services/crm";
import { required } from "../../components/Form/whFields/WhValidator";
import { validEmail, validMultipleSelect, validTel } from "../../utils/validators";
import { curryRight, initial } from "lodash";

class UserForm extends Component {
  getOptions = (input) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }

    return fetchOrganisations({ name: { $like: `%${input}%` } }).then(
      (result) => {
        const options = result.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        return { options };
      }
    );
  };

  render() {
    const { error, handleSubmit, submitting, roles, currentValues, initialValues} = this.props;
    return (
      <Form onSubmit={handleSubmit} className="form-horizontal">
        <ModalBody>
          <Field
            label="Prénom :"
            name="firstname"
            component={WhFieldTxt}
            type="text"
            require="true"
            validate={required}
          />
          <Field
            label="Nom :"
            name="lastname"
            component={WhFieldTxt}
            type="text"
            require="true"
            validate={required}
          />
          <Field
            label="Téléphone :"
            name="phone"
            component={WhFieldTxt}
            type="text"
            require="true"
            validate={[required, validTel]}
          />
          <Field
            label="Email :"
            name="email"
            component={WhFieldTxt}
            type="text"
            require="true"
            validate={[required, validEmail]}
          />

          <Field
            label="Rôles :"
            name="roles"
            component={WhFieldSelect2}
            type="select"
            options={roles}
            validate={validMultipleSelect}
          />

          <Field
            label="Société : "
            name="organisation"
            component={WhFieldSelectAsync}
            multi={false}
            type="select"
            loadOptions={this.getOptions}
            validate={required}
          />

          {error && (
            <Alert color="danger">
              <div dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <LaddaButton
            className="btn btn-gray btn-padded btn-ladda"
            loading={submitting}
            data-style={EXPAND_LEFT}
            type="submit"
          >
            Enregistrer
          </LaddaButton>
        </ModalFooter>
      </Form>
    );
  }
}

const formName = "FormUser";

const rxf = reduxForm({ form: formName })(UserForm);

UserForm.defaultProps = {
  error: "",
  currentValues: {},
};
UserForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  currentValues: PropTypes.shape({}),
};

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(rxf);
