import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory as createHistory } from "history";

import { Provider } from "react-redux";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/lib/integration/react";
import thunk from "redux-thunk";
import Loading from "./components/Loading";
import "react-toastify/dist/ReactToastify.css";
import { render } from "react-snapshot";
import ApiConfig from "./constants/ApiConfig";
import rootReducer from "./reducers";
import ForgotPassword from "./modules/Authentication/Login/ForgotPassword";
import UpdateLostPassword from "./modules/Account/PasswordUpdate/UpdateLostPassword";

// import './init/reactotron';
// Styles
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.scss";
// Import Main styles for this application
import "./scss/style.scss";
// Temp fix for reactstrap
import "./scss/core/_dropdown-menu-right.scss";

import Layout from "./modules/Layout/";
import Login from "./modules/Authentication/Login/";
import Page404 from "./modules/Exception/Page404/";
import Page500 from "./modules/Exception/Page500/";
import MaintenanceScreen from "./modules/Layout/maintenanceScreen";
import { BASKET_RK } from "./reducers/basket";
import CreatePassword from "./modules/Account/PasswordUpdate/CreatePassword";

const persistConfig = {
	key: "root",
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: ["user", BASKET_RK],
};
const history = createHistory();
const pReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [routerMiddleware(history), thunk];
const store = createStore(
	pReducer,
	composeWithDevTools(applyMiddleware(...middlewares)),
);
const persistor = persistStore(store);

render(
	<Provider store={store}>
		<PersistGate loading={<Loading />} persistor={persistor}>
			<MetaTags>
				<title>{ApiConfig.title}</title>
			</MetaTags>
			<textarea
				id="copyText"
				style={{
					position: "absolute",
					left: "-10000px",
				}}
			/>
			<textarea
				id="copyRequest"
				style={{
					position: "absolute",
					left: "-10000px",
				}}
			/>
			<BrowserRouter>
				<Routes>
					{/* Exception */}
					<Route exact path="/404" name="Page 404" element={<Page404/>}/>
					<Route exact path="/500" name="Page 500" element={<Page500/>}/>
					<Route exact path="/maintenancescreen" name="MaintenanceScreen" element={<MaintenanceScreen />}
              />

					{/* Paths */}

					<Route exact path="/login" name="Login Page" element={<Login/>}/>
					<Route exact path="/forgot-password" name="forgoten passwword" element={<ForgotPassword/>}/>
					<Route exact path="/update-password" name="Update password" element={<UpdateLostPassword/>}/>
					<Route exact path="/create-password" name="Update password" element={<CreatePassword/>}/>
					<Route  path="*"  name="Home"  element={<Layout/>}/>
				</Routes>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root"),
);
