import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Card, CardHeader, CardFooter, Button } from "reactstrap";
import WHTable from "../../components/WHTable";
import EditDiscountPopup from "./EditPopup";
import ImportDiscountPopup from "./ImportPopup";
import ShopPopup from "./shop/shopPopup";
import * as priceListService from "../../services/priceManufacture";
import EuroFormat from "../../components/EuroFormat/EuroFormat";
import { getUserRoles, getUser } from "../Authentication/redux";

class Prices extends Component {
	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
	};

	columns = () => {
		const { userRoles } = this.props;

		const isAdmin = userRoles.includes("ROLE_ADMIN");

		let defaultlst = [];
		{
			isAdmin &&
				defaultlst.push({
					Header: "Client",
					accessor: "client.currentName",
					minWidth: 200,
				});
		}

		defaultlst.push(
			{
				Header: "Produit",
				accessor: "product.manufacture.name",
			},
			{
				Header: "0-4",
				accessor: "price1",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "5-9",
				accessor: "price2",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "10-19",
				accessor: "price3",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "20-49",
				accessor: "price4",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "50-99",
				accessor: "price5",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "100-199",
				accessor: "price6",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "199-500",
				accessor: "price7",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "500 et +",
				accessor: "price8",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
		);
		defaultlst.push(
			{
				Header: "Actions",
				accessor: "id",
				filterable: false,
				maxWidth: 100,
				Cell: row => (
					<div>
						<Button
							color={"transparent"}
							onClick={() =>
								this.refs.shopPopup.handleOpen(row.original)
							}
						>
							<i className="fas fa-shopping-cart"></i>
						</Button>
	
						{isAdmin && (
							<Button
								color={"transparent"}
								onClick={() =>
									this.refs.editPopup.handleOpen(row.value)
								}
							>
								<i className={"fa fa-edit"} />
							</Button>
						)}
	
						{isAdmin && (
							<Button
								color={"transparent"}
								onClick={() => this.remove(row.value)}
							>
								<i className={"far fa-trash-alt"} />
							</Button>
						)}
					</div>
				),
			});
	
		return defaultlst;
	};

	load = (state, instance) => {
		this.setState({ loaded: false });

		return priceListService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	};

	remove = toDelete => {
		if (window.irm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			priceListService
				.remove(toDelete)
				.then(data => {
					toast.success("Traifs supprimés !!");

					this.load(this.state);
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error("Une erreur est survenue");
				});
		}
	};

	newData = data => {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	};

	render() {
		const { data, pages } = this.state;
		const { userRoles } = this.props;
		const isAdmin = userRoles.includes("ROLE_ADMIN");

		return (
			<div className="animated fadeIn">
				<EditDiscountPopup ref={"editPopup"} newData={this.newData} />
				<ImportDiscountPopup ref={"importPopup"} />
				<ShopPopup ref={"shopPopup"} />
				<Card>
					<CardHeader>
						<h1>Produits confectionnés</h1>
						<a
							href={`/help/#/catalogue?id=liste-des-produits-confectionnés`}
							target={"_blank"}
							className={"card-header-tooltip"}
						>
							?
						</a>

					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
					<CardFooter>
						<div className="card-actions">
							{isAdmin ? (
								<button
									onClick={() =>
										this.refs.importPopup.handleOpen()
									}
									className={"btn btn-gray btn-padded"}
								>
									Importer
								</button>
							) : (
								""
							)}
							{isAdmin ? (
								<button
									onClick={() =>
										this.refs.editPopup.handleOpen()
									}
									className={"btn btn-green btn-padded"}
								>
									Ajouter
								</button>
							) : (
								""
							)}

						</div>
					</CardFooter>

				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Prices);
