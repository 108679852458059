import React, { useState, useEffect } from "react";
import ProductTypeSelection from "./productTypeSelection";
import BasketAdd from "./basketAdd";
import FormatSelection from "./formatSelection";
import ProductTissuTypeSelec from "./productTissuTypeSelec"
import ProductTissuSelec from "./productTissuSelec";
import DragDropFiles from "../MediasTunnel";
import { getBasket, setBasket } from "../../reducers/basket";
import { connect } from "react-redux";
import confectionPicto from "../../assets/picto/confectionPicto.png"
import naturalPicto from "../../assets/picto/naturalPicto.png"
import polyesterPicto from "../../assets/picto/polyesterPicto.png"
import coussinPicto from "../../assets/picto/coussinPicto.png"
import rideauPicto from "../../assets/picto/rideauPicto.png"
import ClientNote from "./clientNote";
import { fetchCustomQuery, fetchProducts } from "../../services/pim";
import { getUser } from "../Authentication/redux";
import { Row } from "reactstrap";
import { createMedias } from "../../services/crm";
import { toast } from "react-toastify";

const ConfectionIndex = ({basket, onSetBasket, user}) => {
    const [index, setIndex] = useState(1);
    const [typeChoice, setTypeChoice] = useState();
    const [formatChoice, setFormatChoice] = useState();
    const [tissuTypeChoice, setTissuTypeChoice] = useState();
    const [tissuChoice, setTissuChoice] = useState();
    const [files, setFiles] = useState();
    const [maxIndex, setMaxIndex] = useState(0)
    const [finalArticle, setFinalArticle] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(index > maxIndex){
            setMaxIndex(index);
        }
    },[index]);

    const handleClickType = (type) => {
        setTypeChoice(type)
        setIndex(2)
    }

    const handleClickFormat = (format) => {
        setFormatChoice(format)
        setIndex(3)
    }

    const handleClickTissuType = (tissuType) => {
        setTissuTypeChoice(tissuType)
        setIndex(4)
    }
    const handleClickTissu = async (value) => {
        if(value != null){
            setTissuChoice(value)

            //On cherche l'id du produit
            let ref
            if(formatChoice.accessory){
                const accessRef = formatChoice.accessory.ref.replace(/[0-9]/g, "")
                ref = `${formatChoice.genericReference}${accessRef}_${value.ref}`
            }else{
                ref = `${formatChoice.genericReference}_${value.ref}`
                
            }
            const payload = {query:`SELECT * FROM products WHERE reference LIKE "${ref}%"`} 
            const res = await fetchCustomQuery(payload)
            const query2 = {id: res[0].id}
    
            //On récupère le produit avec ses infos prix et autres
            const produit = await fetchProducts(query2)
            setFinalArticle(produit.data)
            setIndex(5)
        }
    }

    const handleClickFiles = async (files) => {
        if(files[0] === undefined){
            setFiles(files[1])
        }else{
            if(files[1] === undefined){
                setFiles(files[0])
            }else{
                const newArray = [...files[0], ...files[1]]
                setFiles(newArray)
            }
        }
        setIndex(6)

    }


    const handleClickClientNote = async (props) => {
        let filesToPush = []
        let nbrItem = 0 
        setLoading(true)
        await Promise.all(files.map(async (file, index) => {
            if(file != undefined){
                nbrItem = nbrItem + file.inputValue
                if(file.base64){
                    const payload = {
                        name: file.path,
                        file: file.base64,
                        organisationId:user.organisationId
                    }
    
                    const createdMedia = await createMedias(payload)
                    const newMedia = {...createdMedia, inputValue: file.inputValue, custom: false, path:file.path}
                    filesToPush.push(newMedia)

                }else{
                    filesToPush.push(file)
                }
            }
        }))

        const fileArray = [filesToPush]
        let productAccessory
        if(formatChoice.isAccessory === false){
            nbrItem = 0
        }
        if(formatChoice.accessory){
            const res = await fetchProducts({
                reference: `ACCES${formatChoice.accessory.ref}`
            })

            if(res.data){
                productAccessory = res.data[0]
            }

        }

        const infoAccessory = {...formatChoice, nbrItem:nbrItem, productAccessory}
        setLoading(false)
        toast.success("Fichier(s) ajouté(s) à vos médias")
        if(props === "none"){
            if(formatChoice.accessoryId === null){
                onSetBasket({item:{article:finalArticle, fichier:{...fileArray}}})
            }else{
                onSetBasket({item:{article:finalArticle, fichier:{...fileArray}, accessory:infoAccessory}})
            }
            setIndex(7)
        }else{
            const noteParse = JSON.stringify({ props })
            if(formatChoice.accessoryId === null){
                onSetBasket({item:{article:finalArticle, note:{noteParse},fichier:{...fileArray}}})
            }else{
                onSetBasket({item:{article:finalArticle, note:{noteParse},fichier:{...fileArray}, accessory:infoAccessory}})
            }
            setIndex(7)
        }
    }

    const handleNavigate = (etape, index) => {
        if(etape === 0){
            window.location.reload()
        }else{
            if(maxIndex > etape){
                switch(etape){
                    case 1:
                        setTypeChoice(null)
                        setFormatChoice(null)
                        setTissuTypeChoice(null)
                        setTissuChoice(null)
                        break;
                    case 2:
                        setFormatChoice(null)
                        setTissuTypeChoice(null)
                        setTissuChoice(null)
                        break;
                    case 3:
                        setTissuTypeChoice(null)
                        setTissuChoice(null)
                        break;
                    case 4:
                        setTissuChoice(null)
                        break;
                    case 5:
                        break;
                    default:
                        console.log("default")
                }
                setIndex(etape)
                setMaxIndex(etape)
            }
        }
    };

    return(
        <>
            <div className="borderEtape">
                <div className="row">
                    <div className="bulletInActive"></div>
                    <div onClick={() => handleNavigate(0, index)} className="etapeInactive"> Etape 1 : Choisissez un produit - Produits confectionnés </div>
                </div>
                <div>
                    <div className="row">
                            <img src={confectionPicto} className="inactivePicto"></img>
                            <label className="labelPicto">Confection</label>
                    </div>
                </div>
                <div className="row">
                    <div className={index === 1 ? "bulletActive" : "bulletInActive"}></div>
                    <div onClick={() => handleNavigate(1, index)} className={index === 1 ? "etapeActive" : "etapeInactive"}>Etape 2 : Choisissez un produit confectionné {typeChoice ? `- ${typeChoice}` : null}</div>
                </div>
                    { index === 1 && <ProductTypeSelection handleClickType={handleClickType}/> }
                    <div>
                        <div className="row">
                            {typeChoice === "HOCC" &&
                                <>
                                    <img src={coussinPicto} className="inactivePicto"></img>
                                    <label className="labelPicto">Coussin (HOCC)</label>
                                </>
                            }
                            {typeChoice === "RIDOL" &&
                                <>
                                    <img src={rideauPicto} className="inactivePicto"></img>
                                    <label className="labelPicto">Rideau (RIDOL)</label>
                                </>
                            }
                        </div>
                    </div>
                <div className="row">
                    <div className={index === 2 ? "bulletActive" : "bulletInActive"}></div>
                    <div onClick={() => handleNavigate(2, index)} className={index === 2 ? "etapeActive" : "etapeInactive"}>Etape 3 : Choix du format {formatChoice ? `- ${formatChoice.genericReference}` : null}</div>
                </div>
                    { index === 2 && <FormatSelection handleClickFormat={handleClickFormat} type={typeChoice}/> }
                    <div>
                        {formatChoice ? 
                            <>
                                <Row>
                                    <div className="iconSizeInactiveTunnel">
                                        {`${formatChoice.dimensions.width} x ${formatChoice.dimensions.height}`}
                                    </div>
                                    <div className="labelPicto">
                                        {`${formatChoice.accessory ? `avec ${formatChoice.accessory.label}` : "Sans accessoires "}`}
                                    </div>
                                </Row>
                            </>
                            : null}
                    </div>
                <div className="row">
                    <div className={index === 3 ? "bulletActive" : "bulletInActive"}></div>
                    <div onClick={() => handleNavigate(3, index)} className={index === 3 ? "etapeActive" : "etapeInactive"}>Etape 4 : Choix du type de tissu  {tissuTypeChoice ? tissuTypeChoice[0].ref[0] === "C" ? `- Naturel` : `- Polyester` : null}</div>
                </div>
                    { index === 3 && <ProductTissuTypeSelec handleClickType={handleClickTissuType} item={formatChoice}/> }
                    {tissuTypeChoice ? tissuTypeChoice[0].ref[0] === "C" ?
                        <div className="row">
                            <img src={naturalPicto} className="inactivePicto"/>
                            <label className="labelPicto">Naturel</label>
                        </div>
                        :
                        <div className="row">
                            <img src={polyesterPicto} className="inactivePicto"/>
                            <label className="labelPicto">Polyester</label>
                        </div>
                        
                    :null}

                            
                <div className="row">
                    <div className={index === 4 ? "bulletActive" : "bulletInActive"}></div>
                    <div onClick={() => handleNavigate(4, index)} className={index === 4 ? "etapeActive" : "etapeInactive"}>Etape 5 : Choix du tissu {tissuChoice ? `- ${tissuChoice.label}` : null}  </div>
                </div>
                    { index === 4 && <ProductTissuSelec handleClickTissu={handleClickTissu} selectedValue={tissuTypeChoice}/> }
                    <div>{tissuChoice ? tissuChoice.label : null}</div>
                <div className="row">
                        <div className={index === 5 ? "bulletActive" : "bulletInActive"}></div>
                        <div onClick={() => handleNavigate(5, index)} className={index === 5 ? "etapeActive" : "etapeInactive"}>Etape 6 : Upload du fichier</div>
                    </div>
                        {index === 5 && <DragDropFiles isTissu={false} handleClickFiles={handleClickFiles}/> }
                <div className="row">
                    <div className={index === 6 ? "bulletActive" : "bulletInActive"}></div>
                    <div onClick={() => handleNavigate(6, index)} className={index === 6 ? "etapeActive" : "etapeInactive"}>Etape 7 : Note client</div>
                </div>
                    { index === 6 ? <ClientNote handleClickClientNote={handleClickClientNote}/> : null}
                <div className="row">
                    <div className={index === 7 ? "bulletActive" : "bulletInActive"}></div>
                    <div className={index === 7 ? "etapeActive" : "etapeInactive"}>Etape 8 : Panier</div>
                </div>
                    {loading ? 
                        <div className="containerColCenter">
                            <div className="tableMarg">Cela peut prendre plus ou moins de temps selon la taille et le nombre de fichiers </div>
                            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div> :null}
                    {index === 7 ? <BasketAdd/> : null}
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
	basket: getBasket(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBasket: (item) => dispatch(setBasket(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfectionIndex);