import React from "react";
import FormForgot from "./FormForgot";
import { Row, Col, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { SubmissionError } from "redux-form";
import { lostPassword } from "../../../services/iam";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const handleSubmit = async (values, userRoles) => {
    try {
      const payload = {
        email: values.username,
      };
      await lostPassword(payload);
      navigate("/login", {
        state: { successAlert: "Email de récupération envoyé" },
      });
    } catch (error) {
      throw new SubmissionError({
        _error: error && error.message ? error.message : error,
      });
    }
  };

  return (
    <div className="app flex-row align-items-center loginContainer">
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} />
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="justify-content-center">
            <div className={"loginHeader"}>
              <img src="img/logo.png" alt="" style={{ maxWidth: "100%" }} />

              <h1>Mot de passe oublié</h1>

              <p className="text-muted">
                Merci de rentrer votre adresse email afin de récupérer votre mot
                de passe
              </p>
            </div>

            <div className="loginBody">
              <FormForgot onSubmit={handleSubmit} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
