import React, { useEffect } from "react";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";
import { SubmissionError } from "redux-form";
import FormLogin from "./FormLogin";
import { connect } from "react-redux";
import * as session from "../../../utils/session";
import { getUserRoles, getUser } from "../redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const Login = (props) => {
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    if (location.state) {
      toast.success(location.state.successAlert);
    }
  }, [location.state]);

  const handleSubmit = async (values, userRoles) => {
    try {
      await session.onLogin(values.username, values.password);
      navigate("/dashboard");
    } catch (error) {
      throw new SubmissionError({
        _error: error && error.message ? error.message : error,
      });
    }
  };

  const handleForgotPass = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="app flex-row align-items-center loginContainer">
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} />
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="justify-content-center">
            <div className={"loginHeader"}>
              <img src="img/logo.png" alt="" style={{ maxWidth: "100%" }} />
              <p className="text-muted">Merci de vous identifier</p>
            </div>

            <div className="loginBody">
              <FormLogin onSubmit={handleSubmit} />
              <div onClick={handleForgotPass} className="loginForgotPass">
                Mot de passe oublié ?
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Login);
