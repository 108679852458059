const basketReducer = (state = [], { payload, type }) => {
    switch (type) {
        case 'ADD_ITEM_BASKET':
            const newState = [...state, payload]
            return newState;

        case 'DEL_ITEM_BASKET':
            return [
                ...state.slice(0,payload),
                ...state.slice(payload + 1)
            ];
        
        case 'DEL_BASKET':
            return [];
        
        case 'NEW_BASKET':
            return [payload]

        case 'UPDATE_BASKET':
            let newBasket = []
            state.map((item, index) => {
                if(index === payload.index){
                    newBasket = [...newBasket, payload.item]
                }else{
                    newBasket = [...newBasket, item]
                }
            }) 

            return newBasket
        default:
            return state;
    }
};

export default basketReducer;
