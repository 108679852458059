import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardFooter, Label, Row, Collapse } from "reactstrap";
import useModal from "../../hooks/useModal";
import useOrganisationService from "../../hooks/services/useOrganisationService";
import FormPopup from "./FormPopup";
import CustomersList from "../../components/Customers/CustomersList";
import { Modal, ModalHeader, Button, Col } from "reactstrap";
import Select from "react-select";
import { getUserById, fetchUsers } from "../../services/iam";
import { getUser, getUserRoles } from "../Authentication/redux";
import roles from "../../constants/roles";
import { fetchOrganisations } from "../../services/crm";

const Customers = ({ userRoles, user }) => {
  const isSaler = userRoles.includes(roles.ROLE_SALER);
  const [salerFilter, setSalerFilter] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [saler, setSaler] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedClientValue, setSelectedClientValue] = useState(null);
  const [inputClientValue, setInputClientValue] = useState('')
  const [selectedSaler, setSelectedSaler] = useState();
  const { toggle, active } = useModal();
  const [tagCheck, setTagCheck] = useState(false);


  const [isOpen, setIsOpen] = useState(false);

  const { loadData, onSubmit, onDelete, data, pagination } =
    useOrganisationService({
      selectedData,
      toggle,
      isSaler,
      userId: user.id,
      salerFilter,
      selectedSaler, 
      selectedClientValue
    });

  const setSalerData = async () => {
    setLoading(true);
    if (![null, undefined].includes(selectedData.salerId)) {
      const result = await getUserById(selectedData.salerId);
      setSaler(result);
    } else {
      setSaler(undefined);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedData) {
      setSalerData();
    } else {
      setSaler(undefined);
    }
  }, [selectedData]);

  useEffect(() => {
    loadData(1)
  }, [salerFilter, selectedClientValue]);
  
  useEffect(() => {
    loadData(1)
  }, [selectedSaler]);
  

  const onEdit = (data) => {
    setSelectedData(data);
    toggle();
  };


  const onCreate = () => {
    setSelectedData();
    toggle();
  };

  const handleFilter = () => {
    setSalerFilter(!salerFilter);
    setTagCheck(!tagCheck);
  }

  const loadSellerOptions = (input) => {
    return fetchUsers({
      roles: { $like: `%ROLE_SALER%` },
      $or: [
        { firstname: { $like: `%${input}%` } },
        { lastname: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.firstname} ${item.lastname}`,
      }));

      return { options };
    });
  }

  const loadClientOptions = (input) => {
    return fetchOrganisations({
      $or: [
        { name: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.name}`,
      }));

      return { options };
    });
  }
  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const handleInputClientChange = (value) => {
    setInputClientValue(value)
  }

  const handleChange = (value) => {
    setSelectedValue(value)
    if(value != undefined){
      setSelectedSaler(value.value)
    }else{
      setSelectedSaler(null)
    }
  }

  const handleClientChange = (value) => {
    setSelectedClientValue(value)
  }

  const handleCollapse = () => {
    setIsOpen(!isOpen)
  }
  const initialValues = useMemo(() => {
    
    return selectedData ? {
      ...selectedData,
      saler:
        selectedData.salerId && saler
          ? {
              label: `${saler.firstname} ${saler.lastname}`,
              value: saler.id,
            }
          : null,
    }
  : {}
  }, [selectedData]) 
  
  
  
  

  return (
    <div className="animated fadeIn">
      <Modal isOpen={active} fade={false}>
        <ModalHeader toggle={toggle}>
          {selectedData ? "Editer" : "Nouveau"}
        </ModalHeader>
        {active && !loading && (
          <FormPopup
            onSubmit={onSubmit}
            initialValues={initialValues}
          />
        )}
      </Modal>

      <Card>
        <CardHeader>
          <h1>Clients</h1>
        </CardHeader>
        
          <Col xs="10">
              <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
              <Collapse isOpen={isOpen} className="filterButton">
                <Row className="filterRow">
                { isSaler? 
                  <button className={!tagCheck ? `label status-${100} buttonRound margBot filterLabel` : `label status-${200} buttonRound margBot` } onClick={handleFilter}> Mes clients </button> :
                  <>
                    <Label className="filterLabel">Recherche par commercial en charge </Label>
                    <Select.Async
                    placeholder="Commercial en charge"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedValue}
                    loadOptions={loadSellerOptions} />
                  </>  
                }
                  <Label className="filterLabel">Recherche par nom de client </Label>
                  <Select.Async
                    placeholder="Nom du client"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputClientChange}
                    onChange={handleClientChange}
                    value={selectedClientValue}
                    loadOptions={loadClientOptions}
                  />  
                </Row>
              </Collapse> 
            </Col>
            
        <CustomersList
          onEdit={onEdit}
          onDelete={onDelete}
          loadData={loadData}
          pagination={pagination}
          data={data}
          isSaler={isSaler}
          userId={user.id}
        />

        <CardFooter>
          <div className="card-actions">
            <button onClick={onCreate} className={"btn btn-gray btn-padded"}>
              Ajouter un client
            </button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Customers);
