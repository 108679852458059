import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { fetchProducts } from "../../services/pim";
import Select from "react-select/lib/Select";

const ProdSpeChoice = (props) => {
    const [options, setOptions] = useState();

    useEffect(() => {
        loadProduct()
      }, []);


    const loadProduct = async () => {
        const query = {
            type: 400,
            organisationId: props.orgaId
        }
        await fetchProducts(query).then((result) => {
            const options = result.data.map((item) => ({
              prix:item.prices,
              value: item.id,
              ref: item.reference,
              label: `${item.reference} ${item.label}`,
            }));
            setOptions(options)
          });
    }

    return(
        <>
            <Row>
                <Col>
                    <Select
                        placeholder="Choisissez un produit"
                        noResultsText="Pas de résultat"
                        className="searchSaler"
                        onChange={props.handleClickProd}
                        value={props.selectedValue}
                        options={options}
                    />
                </Col>
            </Row>
        </>
    )

}
export default ProdSpeChoice;