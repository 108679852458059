import React, {useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone'
import { toast } from "react-toastify";
import { ModalFooter, Button } from "reactstrap";
import { createGabarits } from "../../services/crm";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";

const GabaritAdd = (props) => {

    const {userRoles, commandId, isSaler, userId} = props
    const organisationId = props.user.organisationId
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone();
    const [file, setFile] = useState([]);
    const [base64, setBase64] = useState();

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
      };
      
    useEffect(() => {
        setFile(acceptedFiles);
        if(acceptedFiles[0]){
            getBase64(acceptedFiles[0])
        }
    }, [acceptedFiles])

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setBase64(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }

    const handleSubmit = async () => {
        try{
            const payload = {
                name: file[0].name,
                file: base64,
                organisationId:organisationId
            }
            await createGabarits(payload)
            toast.success("Fichier enregistré")
            window.location.reload()
        }catch(error){
            console.log(error)
            toast.error("Une erreur est survenue")

        }
    }
    
    return(
        <>
        <div className="centerTitle">
            <div>
            {file.length === 0  ?
                <div>Informations sur l'upload</div>
                :
                <div>{file[0].path}</div>
            }
            </div>
            <div className='divShadow' {...getRootProps()}>
                <i class='fas fa-cloud-download-alt fa-xl'></i>
                <input {...getInputProps()} />
            </div>
        </div> 
        <ModalFooter>
          <Button color='dark' onClick={handleSubmit}>
            Enregistrer
          </Button>
        </ModalFooter>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(GabaritAdd);