import React, { useState, useEffect } from "react";
import reactstrap from "reactstrap";
import ShippingDelayForm from "./shippingDelayForm";
import { Card } from "reactstrap";
import { createShippingDelay, fetchShippingDelay } from "../../services/crm";
import { toast } from "react-toastify";

const ShippingDelay = () => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData()
    },[])

    const loadData = async () => {
        setLoading(true);
        const newData = await fetchShippingDelay()
        setData(newData)
        setLoading(false);
    }

    const handleSubmit = async (props) => {
        if(props.delayText.length <= 0){
            toast.error("Valeur non rensignée")
        }else{
            const query = {
                delayText: props.delayText
            }
            try{
                await createShippingDelay(query)
                toast.success("Mise à jour éffectuée")
            }catch(error){
                toast.error("Une erreur est survenue")
            }
        }
    }

    return(
        <>
            <h1>Delais de livraison</h1>
            <Card className="bannerPageCard">
                {!loading ?
                    <ShippingDelayForm onSubmit={handleSubmit} initialValues={data}/>
                    :
                        null
                    }
            </Card>
        </>
    )
}

export default ShippingDelay;