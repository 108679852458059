import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useResizeColumns,
  useBlockLayout,
  useSortBy,
  useFilters,
} from "react-table";
import usePagination from "../../hooks/usePagination";
import usePrevious from "../../hooks/usePrevious";
import useEffectNotOnFirstRender from "../../hooks/useEffectNotOnFirstRender";
import "./styles.css";
import { format } from "date-fns";
import Pagination from "../Pagination";

const Table = ({
  columns,
  data = [],
  loadData,
  className = "",
  paginated = false,
  isLoading = false,
  enableFilters = true,
  customFilters,
  renderRowFooter,
  infiniteScroll,
  headerDisplay = true,
  defaultPagination = 50,
  ...props
}) => {
  const pagination = useMemo(() => {
    if (
      !props.pagination ||
      props.pagination.limit === undefined ||
      props.pagination.skip === undefined ||
      props.pagination.total === undefined
    ) {
      return undefined;
    } else {
      const { limit, total, skip } = props.pagination;

      const numberOfPages = Math.ceil(
        limit >= total ? 1 : (total + (limit - (total % limit))) / limit
      );

      const currentPage = skip / limit + 1;

      return {
        numberOfPages,
        currentPage,
        skip,
        limit,
        total,
      };
    }
  }, [props.pagination]);

  const [paginatedData, setPaginatedData] = useState([]);

  const scrollRef = useRef(null);

  const scrollTableToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    if (infiniteScroll) {
      if (!pagination || !data) {
        setPaginatedData([]);
      } else if (pagination.currentPage === 1) {
        scrollTableToTop();
        setPaginatedData(data);
      } else {
        const currentData = paginatedData ? paginatedData : [];

        setPaginatedData([...currentData, ...data]);
      }
    }
  }, [data]);

  const [sortingSettings, setSortingSettings] = useState();

  const defaultColumn = useMemo(
    () => ({
      type: "default",
      filterType: "default",
      width: 80,
      Cell: (cellProps) => {
        const column = cellProps.column;

        switch (column.type) {
          case "date":
            return format(new Date(cellProps.value), "dd/MM/yyyy");

          case "datetime":
            return format(new Date(cellProps.value), "dd/MM/yyyy HH:mm");

          default:
            return cellProps.value ? cellProps.value : "";
        }
      },
      filterPlaceHolder: "Filtrer",
    }),
    []
  );

  const onLoadData = (pageNumber, newLimit) => {
    const filters = addTypesToFilters(state.filters);

    let limit;

    if (newLimit) {
      limit = newLimit;
    } else if (paginated) {
      limit = pagination ? pagination.limit : defaultPagination;
    }

    loadData(pageNumber, limit, filters, sortingSettings);
  };

  const handlePageChange = (pageNumber) => {
    onLoadData(pageNumber);
  };

  const { pageNumber, onPageChange, setPageNumber } =
    usePagination(handlePageChange);

  useEffect(() => {
    if (pagination && pagination.skip !== undefined && pagination.limit > 0) {
      const newPageNumber = pagination.skip / pagination.limit + 1;

      setPageNumber(newPageNumber);
    }
  }, [pagination]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data: paginated && infiniteScroll ? paginatedData : data,
      defaultColumn,
      manualFilters: true,
      autoResetFilters: false,
    },
    useResizeColumns,
    useBlockLayout,
    useFilters,
    useSortBy
  );

  const addTypesToFilters = (filters) => {
    return filters.map((filter) => {
      const columnItem = columns.find((column) => column.id === filter.id);

      const type =
        columnItem && columnItem.filterType ? columnItem.filterType : "default";

      return {
        ...filter,
        type,
      };
    });
  };

  // load the initial page on mount
  useEffect(() => {
    onLoadData(1);
  }, []);

  const prevFilters = usePrevious(state.filters);

  const onFiltersChange = () => {
    onLoadData(1);
    scrollTableToTop();
  };

  // Resets the page number to 1 when inter filters change
  useEffect(() => {
    // We don't trigger the call on the first render
    if (prevFilters) {
      onFiltersChange();
    }
  }, [state.filters]);

  useEffectNotOnFirstRender(() => {
    onFiltersChange();
  }, [customFilters, sortingSettings]);

  const onSortClick = (id) => {
    const currentId = sortingSettings ? sortingSettings.id : null;

    if (currentId === id && sortingSettings && !sortingSettings.asc) {
      setSortingSettings(undefined);
    } else {
      setSortingSettings({
        id,
        asc: currentId === id ? false : true,
      });
    }
  };

  const onScroll = () => {
    if (scrollRef && scrollRef.current && pagination) {
      const currentHeight = scrollRef.current.scrollTop;
      const totalHeight = scrollRef.current.scrollHeight;
      const clientHeight = scrollRef.current.clientHeight;
      const remainingToScroll = totalHeight - currentHeight - clientHeight;

      if (
        remainingToScroll < 30 &&
        pagination.currentPage < pagination.numberOfPages &&
        !isLoading
      ) {
        const newPageNumber = pagination.currentPage + 1;
        onLoadData(newPageNumber);
      }
    }
  };

  const onLimitChange = (newLimit) => {
    onLoadData(1, newLimit);
  };

  return (
    <div style={{ position: "relative" }}>
      {!infiniteScroll && paginated && pagination && (
        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={pagination.limit}
          totalItemsCount={pagination.total}
          onChange={onPageChange}
          onLimitChange={onLimitChange}
        />
      )}

      <div {...getTableProps()} className={`table-container ${className} tableMarg`}>
        {headerDisplay ? (
          <div className="table-headers">
            {headerGroups.map((headerGroup) => (
              <div key={headerGroup.getHeaderGroupProps().key}>
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-header-group"
                >
                  {headerGroup.headers.map((column, index) => {
                    const onColumnClick = () => {
                      if (column.isSortable) {
                        onSortClick(column.id);
                      }
                    };

                    const isSorted =
                      sortingSettings && sortingSettings.id === column.id;
                    const isAscending = Boolean(
                      isSorted && sortingSettings && sortingSettings.asc
                    );

                    return (
                      <div
                        {...column.getHeaderProps()}
                        className={`table-header ${
                          column.isSortable ? "cursor-pointer" : ""
                        }`}
                        key={index}
                        onClick={onColumnClick}
                      >
                        <div className="table-header-text">
                          {column.render("Header")}
                          {/* {isSorted && (
													<i
														className={`lnr lnr-arrow-${
															isAscending
																? "down"
																: "up"
														} ml-3`}
													></i>
												)} */}
                          <div
                            // @ts-ignore
                            {...column.getResizerProps()}
                            // @ts-ignore
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* {enableFilters && hasActiveFilter && (
								<div
									className="w-full max-w-full min-w-full text-xs bg-gray-100"
									{...headerGroup.getHeaderGroupProps()}
									key={"filterRow"}
								>
									{headerGroup.headers.map(
										(column, index) => {
											return (
												<div
													className={`flex justify-center text-xs items-center flex-grow px-4 py-2`}
													{...column.getHeaderProps()}
													key={index}
												>
													{column.filtered
														? column.render(
																"Filter"
														  )
														: null}
												</div>
											);
										}
									)}
								</div>
							)} */}
              </div>
            ))}
          </div>
        ) : null}
        <div
          {...getTableBodyProps()}
          style={{
            maxHeight: infiniteScroll ? "630px" : undefined,
            overflowY: infiniteScroll ? "scroll" : undefined,
            paddingBottom: infiniteScroll ? "60px" : undefined,
          }}
          onScroll={onScroll}
          ref={scrollRef}
        >
          {(!isLoading || infiniteScroll) &&
            rows.map((row, i) => {
              prepareRow(row);
              const isOdd = i % 2;

              const rowProps = row.getRowProps();

              return (
                <Fragment key={i}>
                  <div
                    className="table-row"
                    {...rowProps}
                    style={{
                      ...rowProps.style,
                      width: undefined,
                      minHeight: "60px",
                      width: "100%",
                      maxWidth: "100%",
                      height: "65px",
                      borderBottom: "solid 1px rgba(0,0,0,.05)",
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className={`table-cell-container`}
                        >
                          <div className="table-cell-content">
                            {cell.render("Cell")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {renderRowFooter && renderRowFooter(row)}
                </Fragment>
              );
            })}
          {!rows.length && !isLoading && (
            <div className="table-no-data" style={{ margin: 25 }}>
              Aucune donnée
            </div>
          )}
          {isLoading && <div className="table-loader">... Chargement</div>}
        </div>
      </div>
    </div>
  );
};

export default Table;
