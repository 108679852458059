import React, { useState, useEffect } from "react";
import DropzoneBasket from "../../DropZone/dropZoneBasket";
import { Button } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { createMedias } from "../../../services/crm";
import { toast } from "react-toastify";
import { getUser } from "../../Authentication/redux";
import { connect } from "react-redux";

const CommandInfoFile = ({handleSubmitInfoFile, user}) => {

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState();
    const [uploadedMedia, setUploadedMedia] = useState()
    
    useEffect(() => {
        if(files.length === 0){

        }else{
            uploadFile()
        }
    }, [files])



    const handleFileAccepted = (acceptedFiles) => {
        setLoading(true)
        setFiles(acceptedFiles);
    }


    const convertToBase64 = (file, callback) => {
        setLoading(true)
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            callback(base64String);
        };
        reader.readAsDataURL(file);
    };

    const uploadFile = async () => {
        const file = files[0];
        convertToBase64(file, async (base64String) => {
            
            try{
                const paylaod = {
                    file:base64String,
                    organisationId:user.organisationId,
                    name:files[0].name
                }
                const createdMedia = await createMedias(paylaod)
                setUploadedMedia(createdMedia.id)
                setLoading(false)
            }catch(error){
                console.log(error)
                toast.error("Une erreur inattendu est surevenue")
            }
        })
    }

 return(
    <>
        <div>
        {files.length === 0 ? 
            <DropzoneBasket onFileAccepted={handleFileAccepted} />
        : 
            <div className="modalMarg">
                {files[0].name}
            </div>
        }
        </div>
        {loading === true ? 
            null
        :
            <LaddaButton
                color="dark"
                className="btn btn-gray btn-padded btn-ladda submitParam"
                onClick = {() => handleSubmitInfoFile(uploadedMedia)}
            >
                Suivant
            </LaddaButton>
        }
    </>
 )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
});

export default connect(mapStateToProps)(CommandInfoFile);