import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "../../Commands/views/FormItem";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import * as fabricService from "../../../services/fabric";
import * as commandService from "../../../services/commandItem";

class shopPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price: false,
			product: false,
			open: false,
			data: {},
			loaded: true,
		};

		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
	}

	handleOpen(price = null) {
		this.setState({
			price: price,
			create: true,
			open: true,
		});
	}

	handleEdit(id) {
		this.setState({ create: false, open: true });

		if (id) {
			this.setState({ loaded: false, create: false });

			commandService.view(id).then(data => {
				this.setState({
					price: data.price,
					product: data.product,
					data: data,
					loaded: true,
				});
			});
		}
	}

	handleClose() {
		this.setState({ open: false, data: {} });
	}

	onSubmit(data) {
		if (data.isSameAddress && data.billAddress) delete data.billAddress;

		data.price = this.state.price;
		let edit = data.id ? commandService.update : commandService.create;
		return edit(data)
			.then(data => {
				toast.success("Produit ajouté à votre panier !");

				this.handleClose();

				this.props.newData(data);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	}

	render() {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					{this.state.create ? "Commander" : "Modifier la commande"}
				</ModalHeader>
				{!loaded && <Loading />}
				{loaded && (
					<FormPopup
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

export default shopPopup;
