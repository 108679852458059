import React, {useState, useEffect} from "react";

const IndicateursStock = (props) => {
    const [totalStock, setTotalStock] = useState(0);
    const [alertNbr, setAlertNbr] = useState(0);

    const data = props.data;

    useEffect( () => {
		loadStockIndicators();
	}, [data]);
    
    const loadStockIndicators = () => {
        let stock = 0;
        let alerte = 0;
        for(let i = 0; i < data.length; i++){
            if(data[i].alert == true){
                alerte = alerte + 1;
            }
            stock = stock + data[i].stock;
        }
        setAlertNbr(alerte)
        setTotalStock(stock);
    }
    
    return(
        <>
            <div className="row">
                <div className="stockIndicator">
                    <div>Total stock (ml)</div>
                    <div><p className="fontEuro">{totalStock}</p></div>
                </div>
                <div className="stockIndicator">
                    <div>Total stock faibles</div>
                    <div><p className="fontEuro">{alertNbr}</p></div>
                </div>
            </div>
        </>
    )
}

export default IndicateursStock;