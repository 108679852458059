import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, } from "redux-form";
import {
  WhFieldTxt,
  WhFieldSelectAsync,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { fetchMakers } from "../../services/crm";
import { FieldNestedSelect } from "../../components/Form/whFields/FieldNestedSelect";

class JobForm extends Component {
  constructor(props) {
		super(props);
		this.state = {
			open: false,
			active:false
		};
	}
  async loadMaker(input) {
    return fetchMakers({ name: { $like: `${input}%` } })
    .then((result) => {
      const options = result.data.map((item) => ({
      value: item.id,
      label: item.name,
      }));
      return { options };
    });
    }

  render() {
    const options = [
        {value: 10, label: "En impression"},
        {value: 20, label: "En transfert"},
        {value: 30, label: "En visite"},
        {value: 40, label: "Depart confectionneur"},
        {value: 50, label: "Retour confectionneur"},
        {value: 60, label: "Fin"},
    ]
    const { error, handleSubmit, submitting, initialValues, roles } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="form-horizontal">
        <ModalBody>
            <Field
                label="Status : "
                name="status"
                placeholder={initialValues.status.label}
                className="test"
                component={FieldNestedSelect}
                list={options}
            />
            <Field
                label="Commentaire :"
                name="comment"
                component={WhFieldTxt}
                type="text"
            />
          <Field
            label="Confectionneur : "
            name="maker"
            component={WhFieldSelectAsync}
            multi={false}
            type="select"
            value={initialValues.maker ? initialValues.maker.name : null}
            loadOptions={this.loadMaker}
          />

          {error && (
            <Alert color="danger">
              <div dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <LaddaButton
            className="btn btn-gray btn-padded btn-ladda"
            loading={submitting}
            data-style={EXPAND_LEFT}
            type="submit"
          >
            Enregistrer
          </LaddaButton>
        </ModalFooter>
      </Form>
    );
  }
}

const formName = "JobForm";


JobForm = reduxForm({
  form: formName,
  enableReinitialize: true
})(JobForm);

export const mapStateToProps = (state) => ({
  currentValues: getFormValues(formName)(state)
});

export default connect(mapStateToProps)(JobForm);
