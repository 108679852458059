import APICONFIG from "../constants/ApiConfig";
import fetch from "../utils/fetch";

const endPoint = APICONFIG.PIM;

const endPoints = {
  products: `${endPoint}/products`,
  fabrics: `${endPoint}/fabrics`,
  manufacture: `${endPoint}/manufactures`,
  accessory: `${endPoint}/accessory`,
  confections: `${endPoint}/confections`,
  prices: `${endPoint}/prices`,
  customPrices: `${endPoint}/custom-prices`,
  customQuery: `${endPoint}/custom-query`,
  tissuConfection: `${endPoint}/tissu-confection`,
  brands: `${endPoint}/brand`,
  brandProducts: `${endPoint}/brand-products`,
  importFabric: `${endPoint}/fabrics-import`,
  importAccessory: `${endPoint}/accessory-import`,
  importProduct: `${endPoint}/products-import`,
  importPrice: `${endPoint}/prices-import`,
  importBrand: `${endPoint}/brand-import`,
  synchroProduct: `${endPoint}/synchro-penny-products`,
  productTypes: `${endPoint}/product-type`,
  synchroBrandproduct: `${endPoint}/synchro-penny-brandprod`,
  importBrandProduct: `${endPoint}/brand-products-import`
};


//Products
export const fetchProducts = (query) => fetch(endPoints.products, "get", {}, query);
export const fetchProductTypes = (query) => fetch(endPoints.productTypes, "get", {}, query);
export const createProduct = (payload) => fetch(endPoints.products, "post", payload);
export const updateProduct = (id, payload) => fetch(`${endPoints.products}/${id}`, "patch", payload); 
export const deleteProduct = (id) => fetch(`${endPoints.products}/${id}`, "delete");


//Fabrics
export const fetchFabrics = (query) => fetch(endPoints.fabrics, "get", {}, query)
export const createFabrics = (payload) => fetch(endPoints.fabrics, "post", payload);
export const updateFabrics = (id, payload) => fetch(`${endPoints.fabrics}/${id}`, "patch", payload);
export const deleteFabrics = (id) => fetch(`${endPoints.fabrics}/${id}`, "delete");


//Manufacture
export const fetchManufacture = (query) => fetch(endPoints.manufacture, "get", {}, query)
export const createManufacture = (payload) => fetch(endPoints.manufacture, "post", payload);
export const updateManufacture = (id, payload) => fetch(`${endPoints.manufacture}/${id}`, "patch", payload);
export const deleteManufacture = (id) => fetch(`${endPoints.manufacture}/${id}`, "delete");

//Accessory

export const fetchAccessory = (query) => fetch(endPoints.accessory, "get", {}, query);
export const createAccessory = (payload) => fetch(endPoints.accessory, "post", payload);
export const updateAccessory = (id, payload) => fetch(`${endPoints.accessory}/${id}`, "patch", payload);
export const deleteAccessory = (id) => fetch(`${endPoints.accessory}/${id}`, "delete");

//confections 
export const fetchConfection = (query) => fetch(endPoints.confections, "get", {}, query)
export const createConfection = (payload) => fetch(endPoints.confections, "post", payload);
export const updateConfection = (id, payload) => fetch(`${endPoints.confections}/${id}`, "patch", payload);
export const deleteConfection = (id) => fetch(`${endPoints.confections}/${id}`, "delete");

//prices
export const fetchPrices = (query) => fetch(endPoints.prices, "get", {}, query)
export const createPrices = (payload) => fetch(endPoints.prices, "post", payload);
export const updatePrices = (id, payload) => fetch(`${endPoints.prices}/${id}`, "patch", payload);
export const deletePrices = (id) => fetch(`${endPoints.prices}/${id}`, "delete");


//customPrices
export const updateCustomPrices = (payload) => fetch(endPoints.customPrices, "post", payload);

//customQuery
export const fetchCustomQuery = (payload) => fetch(endPoints.customQuery, "post", payload);

//TissuConfection
export const fetchTissuConfection = (payload) => fetch(endPoints.tissuConfection, "post", payload);

//Marques
export const fetchBrands = (query) => fetch(endPoints.brands, "get", {}, query);

//Produits marques
export const fetchBrandProducts = (query) => fetch(endPoints.brandProducts, "get", {}, query);

//Imports
export const createFabricImport = (payload) => fetch(endPoints.importFabric, "post", payload);
export const createAccessoryImport = (payload) => fetch(endPoints.importAccessory, "post", payload);
export const createProductImport = (payload) => fetch(endPoints.importProduct, "post", payload);
export const createPriceImport = (payload) => fetch(endPoints.importPrice, "post", payload);
export const createBrandImport = (payload) => fetch(endPoints.importBrand, "post", payload);
export const createBrandProductImport = (payload) => fetch(endPoints.importBrandProduct, "post", payload);

//Synchro penny lane 
export const createProductSynchro = (payload) => fetch(endPoints.synchroProduct, "post", payload);
export const createBrandprodSynchro = (payload) => fetch(endPoints.synchroBrandproduct, "post", payload);