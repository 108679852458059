import React, { useState, useEffect } from "react";
import TypeSelection from "./typeSelection";
import TissuSelection from "./tissuSelection";
import ClientNote from "./clientNote";
import BasketAdd from "./basketAdd";
import DragDropFiles from "../MediasTunnel";
import { getBasket, setBasket } from "../../reducers/basket";
import { connect } from "react-redux";
import tissuPicto from "../../assets/picto/tissuPicto.png"
import naturalPicto from "../../assets/picto/naturalPicto.png"
import polyesterPicto from "../../assets/picto/polyesterPicto.png"
import { createMedias } from "../../services/crm"; 
import { getUser } from "../Authentication/redux";
import { toast } from "react-toastify";
import { fetchProducts } from "../../services/pim";

const TissuIndex = ({basket, onSetBasket, user}) => {
    const [index, setIndex] = useState(1);
    const [typeChoice, setTypeChoice] = useState();
    const [tissuChoice, setTissuChoice] = useState();
    const [files, setFiles] = useState();
    const [items, setItems] = useState([]);
    const [maxIndex, setMaxIndex] = useState(0);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(index > maxIndex){
            setMaxIndex(index);
        }
      },[index]);

    const handleClickType = (type) => {
        setTypeChoice(type)
        setIndex(2)
    }

    const handleClickTissu = async (value) => {
        setTissuChoice(value) 
        setIndex(3)
    } 

    const handleClickFiles = (files) => {
        if(files[0] === undefined){
            setFiles(files[1])
        }else{
            if(files[1] === undefined){
                setFiles(files[0])
            }else{
                const newArray = [...files[0], ...files[1]]
                setFiles(newArray)
            }
        }
        setIndex(4)
    }
    
    const handleClickClientNote = async (props) => {

        let filesToPush = []
        setLoading(true)
        
        await Promise.all(files.map(async (file, index) => {
            if(file != undefined){
                if(file.base64){
                    const payload = {
                        name: file.path,
                        file: file.base64,
                        organisationId:user.organisationId
                    }
    
                    const createdMedia = await createMedias(payload)
                    const newMedia = {...createdMedia, inputValue: file.inputValue, custom: false, path:file.path}
                    filesToPush.push(newMedia)

                }else{
                    filesToPush.push(file)
                }
            }
        }))

        const fileArray = [filesToPush]

        setLoading(false)
        toast.success("Fichier(s) ajouté(s) à vos médias")
        if(props === "none"){
            //pb dans l'affectation à regler 
            onSetBasket({item:{article:"none",tissu:{...tissuChoice}, fichier:{...fileArray}}})
            setIndex(5)
        }else{
            onSetBasket({item:{article:"none", note:{props},tissu:{...tissuChoice}, fichier:{...fileArray}}})
            setIndex(5)
        }
    }

    const handleNavigate = (etape, index) => {
        if(etape === 0){
            window.location.reload()
        }else{
            if(maxIndex > etape){
                switch(etape){
                    case 1:
                        setTypeChoice(null)
                        setTissuChoice(null)
                        break;
                    case 2:
                        setTissuChoice(null)
                        break;
                    default:
                        console.log("default")
                }
                setIndex(etape)
                setMaxIndex(etape)
            }
        }
    };
            return(
                <>  
                    <div className="borderEtape">
                        <div className="row">
                            <div className="bulletInActive"></div>
                            <div onClick={() => handleNavigate(0, index)} className="etapeInactive"> Etape 1 : Choisissez un produit - Tissu seul </div>
                        </div>
                        <div className="row">
                            <img src={tissuPicto} className="inactivePicto"></img>
                            <label className="labelPicto">Ml / Tissu seul</label>
                        </div>
                        <div className="row">
                            <div className={index === 1 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(1, index)} className={index === 1 ? "etapeActive" : "etapeInactive"}>Etape 2 : Choix du type de tissu  {typeChoice ? `- ${typeChoice}` : null}</div>
                        </div>
                            { index === 1 && <TypeSelection handleClickType={handleClickType}/> }
                            {typeChoice === "polyester" && 
                                <div className="row">
                                <img src={polyesterPicto} className="inactivePicto"/>
                                <label className="labelPicto">Polyester</label>
                                </div>}

                            {typeChoice === "naturel" && 
                            <div className="row">
                            <img src={naturalPicto} className="inactivePicto"/>
                            <label className="labelPicto">Naturel</label>
                            </div>}
                            
                            <div className="row">
                            <div className={index === 2 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(2, index)} className={index === 2 ? "etapeActive" : "etapeInactive"}>Etape 3 : Choix du tissu {tissuChoice ? `- ${tissuChoice.label}` : null}  </div>
                        </div>
                            { index === 2 && <TissuSelection handleClickTissu={handleClickTissu} selectedValue={typeChoice}/> }
                            <div>{tissuChoice ? tissuChoice.label : null}</div>
                        <div className="row">
                            <div className={index === 3 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(3, index)} className={index === 3 ? "etapeActive" : "etapeInactive"}>Etape 4 : Upload du fichier</div>
                        </div>
                            { index === 3 && <DragDropFiles isTissu={true} handleClickFiles={handleClickFiles}/> }

                            <div className="row">
                            <div className={index === 4 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(4, index)} className={index === 4 ? "etapeActive" : "etapeInactive"}>Etape 5 : Note client</div>
                        </div>
                            { index === 4 ? <ClientNote handleClickClientNote={handleClickClientNote}/> : null}

                        <div className="row">
                            <div className={index === 4 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(5, index)} className={index === 5 ? "etapeActive" : "etapeInactive"}>Etape 6 : Panier</div>
                        </div>
                        {loading ? 
                                    <div className="containerColCenter">
                                        <div className="tableMarg">Cela peut prendre plus ou moins de temps selon la taille et le nombre de fichiers </div>
                                        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div> :null}
                            { index === 5 ?  <BasketAdd/> : null}
                    </div>
                </>
            )
}

const mapStateToProps = (state) => ({
	basket: getBasket(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBasket: (item) => dispatch(setBasket(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(TissuIndex);