const enhanceUserRoles = (user) => {
  const roles =
    typeof user.roles === "string" ? JSON.parse(user.roles) : user.roles;
  return {
    ...user,
    roles,
  };
};

export default enhanceUserRoles;
