import React, { Component, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Indicator from "../Indicator";
import RefSelection from "../RefSelection";
import SubCommandsList from "../SubCommands/SubCommandsList";
import TextileCommandsList from "../TextileCommandsList";
import {useLocation} from 'react-router-dom';
import { toast } from "react-toastify";
import { getUser, getUserRoles } from "../Authentication/redux";
import roles from "../../constants/roles";
import { connect } from "react-redux";
import InProgressCommands from "../Account/InProgressCommands";
import ClientIndicator from "./clientIndicator";
import InformationBanner from "./informationBanner";

const Dashboard = ({ userRoles, user }) =>  {
  const isSaler = userRoles.includes(roles.ROLE_SALER);
  const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
  const isClient = userRoles.includes(roles.ROLE_USER);
  const userId = user.id
  const location = useLocation();
  
  useEffect(() => {
    if(location.state != undefined){
      const data = location.state.data
      toast.success(`Connecté en tant que ${data.firstname} ${data.lastname}`)
    }
  },[location.state]);
  
    return (
      <div className="animated fadeIn">
        <InformationBanner/>
        <Row>
          <Col>
            <Row>
              <Col md="auto">
                <div>
                  <h1>Tableau de bord</h1>
                </div>
                {!isClient ? <Indicator isSaler={isSaler} userId={userId} /> : <ClientIndicator/>}
              </Col>
              {!isClient ? <Col className="metrage">
                <RefSelection />    
              </Col> : null}
            </Row>
            
            {!isClient ? <SubCommandsList isSaler={isSaler} userId={userId}/> : <InProgressCommands/> }
            {/* {isAdmin ? <div style={{ marginTop: 30 }}>
              <h1>Commandes clients par référence textile</h1>

              <div style={{ marginTop: 25 }}>
                <TextileCommandsList />
              </div>
            </div> : null } */}
          </Col>
        </Row>
      </div>
    );
  }
  const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });


export default connect(mapStateToProps)(Dashboard);
