import React, { useState } from "react";
import { Card, CardHeader, CardFooter, Row, Label, Input } from "reactstrap";
import useProductService from "../../hooks/services/useProductService";
import ProductList from "../../components/Products/ProductList";
import useModal from "../../hooks/useModal";
import EditProdSpePopup from "./EditPopUp";

const SpecificProducts = () => {
    const [selectedData, setSelectedData] = useState();
    const { toggle, active } = useModal();
    const [isCreated, setIsCreated] = useState(false);
    const [inputProductValue, setInputProductValue] = useState('');
    const type = 400;

    const { loadData, onSubmit, onDelete, data, pagination, customPrices } = useProductService({
      selectedData,
      toggle,
      type,
      inputProductValue
    });

    const onEdit = (data) => {
        setSelectedData({
          ...data
        });
    
        toggle();
      };
    
      const onClose = () => {
        setIsCreated(false);
        setSelectedData();
        toggle();
      };
    
      const onCreate = () => {
        setIsCreated(true);
        setSelectedData();
        toggle();
      };
    
      const onSuccess = () => {
        loadData(1);
        toggle();
      };
  
  return (
    <div className="animated fadeIn">
        <EditProdSpePopup
            data={{ ...selectedData, customPrices}}
            active={active}
            onClose={onClose}
            onSuccess={onSuccess}
            onSubmit={onSubmit}
            isCreated={isCreated}
        />
        <Card>
            <CardHeader>
                <h1>
                    Produits Spécifiques
                </h1>
            </CardHeader>
            <ProductList
                type="specific"
                loadData={loadData}
                data={data}
                onEdit={onEdit}
                onDelete={onDelete}
                pagination={pagination}
            />
            <CardFooter>
                <div className="card-actions">
                    <button className={"btn btn-gray btn-padded"} onClick={onCreate}>
                    Ajouter
                    </button>
                </div>
            </CardFooter>
        </Card> 


    </div>
    
  );
};

export default SpecificProducts;