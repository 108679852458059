import React, { useState } from "react"
import { Label, Collapse, Button, Row } from "reactstrap"
import DropzoneExcel from "../../DropZone/dropzoneExcel"
import { toast } from "react-toastify"
import { createBrandProductImport } from "../../../services/pim"

const BrandProductImport = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [files, setFiles] = useState([]);
    const [inputValue, setInputValue] = useState('');
 
    const handleToggle = () => {
        setIsOpen(!isOpen)
    }
    
    const handleFileAccepted = (acceptedFiles) => {
        setFiles(acceptedFiles);
    }
    
    const handleChange = (event) => {
        setInputValue(event.target.value);
    }
    
    const convertToBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            callback(base64String);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = () => {
        if(files.length === 0){
            toast.error('Veuillez ajouter un fichier à importer')
        }else if(inputValue === ''){
            toast.error('Veuillez entrer un nom de feuille')
        }else{
            const file = files[0];
            convertToBase64(file, async(base64String) => {
                const payload = {
                    base64File:base64String,
                    sheetName:inputValue
                }

                try{
                    const resp = await createBrandProductImport(payload)
                    downloadFile(JSON.stringify(resp), 'response.txt');
                }catch(error){
                    toast.error("Une erreur inattendu est surevenue")
                }
            });
        }
    }

    const  downloadFile = (text, filename) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element); 
        element.click()
        document.body.removeChild(element);
    }

    return(
        <>
            <div className="accordion">
                <div className="accordionDiv" onClick={handleToggle}>
                    <div className="dropButton">
                        <i className={'fa fa-angle-down'} />
                    </div>
                    <Label className="accordionLabel">Etape 2 : Import des produits d'une marque</Label>
                </div>
                <Collapse 
                      className="marg"
                      isOpen={isOpen}>
                    <div>
                        {files.length === 0 ? 
                            <DropzoneExcel onFileAccepted={handleFileAccepted} />
                        : 
                            <div className="modalMarg">
                                <img className="iconImport" width="24" height="24" src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png" alt="microsoft-excel-2019--v1"/>
                                {files[0].name}
                            </div>
                        }
                        
                        <label htmlFor="textInput" className="labelImport">Nom de la feuille à importer: </label>
                        <input
                            type="text"
                            id="textInput"
                            className="inputSheetName"
                            value={inputValue}
                            onChange={handleChange}
                        />
                    </div>
                    <Button onClick={handleSubmit} className="importButton" color="dark">Importer</Button>
                </Collapse>
            </div>
        </>
    )
}

export default BrandProductImport