import React  from 'react'
import { Card, CardHeader, CardFooter, Modal, ModalBody, ModalHeader } from "reactstrap";
import useModal from "../../hooks/useModal";
import GabaritAdd from './gabaritAdd';
import GabaritsList from './gabaritsList';
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";

const Gabarits = (props) => {
    const {userRoles } = props
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const { toggle, active } = useModal();

    const onCreate = () => {
        toggle()
    }

    return(
        <>
        <Card>
            <CardHeader>
                <h1>Gabarits</h1>
            </CardHeader>
            <GabaritsList/>
            {isAdmin ? 
                <CardFooter>
                    <div className="card-actions">
                        <button onClick={onCreate} className={"btn btn-gray btn-padded"}>
                            Ajouter un gabarit
                        </button>
                    </div>
                </CardFooter>
            :null}
        </Card>

        <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">Ajouter un gabarit</h1>
                </ModalHeader>
                <ModalBody>
                    <GabaritAdd/>
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(Gabarits);