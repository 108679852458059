import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import { Card, CardHeader, CardBody, Form, Alert } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { validPassword, required } from "../../utils/validators";

class FormPassword extends React.Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Card>
				<Form onSubmit={handleSubmit} className="form-horizontal">
					<CardHeader>
						<h1>Modifier mon mot de passe</h1>
					</CardHeader>

					<CardBody>
						<Field
							label="Saisir votre mot de passe actuel :"
							name="currentPassword"
							component={WhFieldTxt}
							type="password"
							validate={required}
						/>
						<Field
							label="Saisir votre nouveau mot de passe :"
							name="password"
							component={WhFieldTxt}
							type="password"
							validate={validPassword}
						/>
						<Field
							label="Confirmer votre nouveau mot de passe :"
							name="confirmPassword"
							component={WhFieldTxt}
							type="password"
						/>

						{error && <Alert color="danger">{error}</Alert>}

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
							}}
						>
							<LaddaButton
								className="btn btn-gray btn-padded btn-ladda"
								loading={submitting}
								data-style={EXPAND_LEFT}
								type="submit"
							>
								Enregistrer
							</LaddaButton>
						</div>
					</CardBody>
				</Form>
			</Card>
		);
	}
}

const afterSubmit = (result, dispatch) => dispatch(reset("FormPassword"));

FormPassword = reduxForm({
	form: "FormPassword",
	validate: (values, test) => {
		const errors = {};

		if (values.password && values.password !== values.confirmPassword) {
			errors.confirmPassword =
				"Le nouveau mot de passe et la confirmation sont différents";
		}

		return errors;
	},
	onSubmitSuccess: afterSubmit,
	onSubmitFail: afterSubmit,
})(FormPassword);

export default FormPassword;
