import React, {useState} from "react";
import { Modal, ModalHeader, Row, Col, Card, CardFooter } from "reactstrap";
import useAccessoryService from "../../hooks/services/useAccessoryService";
import useModal from "../../hooks/useModal";
import FormAccessoire from "./formAccessoire";
import Stock from "./stock";

const StockAccessoires = () => {
    const {active, toggle} = useModal();
    const [isCreated, setIsCreated] = useState(false);

    const {onSubmit, toUpdate} = useAccessoryService({
        toggle,
        isCreated,
      });

    const onCreate = () => {
        setIsCreated(!isCreated)
        toggle();
    };

		return (
			<div className="animated fadeIn">
                <Modal isOpen={active} fade={false}>
                    <ModalHeader toggle={toggle}>
                        Nouvel accessoire
                    </ModalHeader>
                    <FormAccessoire
                        onSubmit={onSubmit}
                    />
                </Modal>
                <Card>
                    <h1>Gestion des stocks accessoires</h1>
                    <Stock isCreated={toUpdate}/>    
                    <CardFooter>
                        <div className="card-actions">
                            <button onClick={onCreate} className={"btn btn-gray btn-padded"}>
                                Ajouter un accessoire
                            </button>
                        </div>
                    </CardFooter>
                </Card>
			</div>
		);
	}

export default StockAccessoires;