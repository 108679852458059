export default {
  JIRA_TICKET_COLLECTOR_SRC_URL:
    "https://whatsonio.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=d2fe1fd3",
  // Command status
  COMMAND_STATUS_CREATE: 100,
  COMMAND_STATUS_VALIDATE: 200,
  COMMAND_STATUS_PREPARATION: 300,
  COMMAND_STATUS_SENT: 400,
  COMMAND_STATUS_FINISH: 500,
};
