import React, { useEffect, useState } from "react";
import { fetchAccessory } from "../../services/pim";
import { Row, Progress, Col, Button, ModalHeader, ModalBody } from "reactstrap";
import { Modal } from "reactstrap"
import useModal from "../../hooks/useModal";
import EditPopUp from "./editPopUp";
import useAccessoryService from "../../hooks/services/useAccessoryService";

const Stock = (props) => {

    const { toggle, active } = useModal();
    const [selectedData, setSelectedData] = useState([]);
    const [toggleAllRef, setToggleAllRef] = useState(5);
    
    const { onSubmit, loadStock, data, onDelete} =
    useAccessoryService({
        selectedData,
        toggle,
    });

    useEffect( () => {
		loadStock()
	}, [props.isCreated]);

    const handleToggle = (props) => {
        const minStockPercent = props[1];
        setSelectedData({...props[0],minStockPercent});
        toggle();
    };

    const handleToggleAllRef = () => {
        if(toggleAllRef == 5){
            setToggleAllRef(data.length)
        }else{
            setToggleAllRef(5);
        }
    };


    const stockProd = data.slice(0,toggleAllRef).map((stockFaible) => {
        const pourcentage = (stockFaible.stock / stockFaible.stockMaxi) * 100  
        const pourcentageRounded = Math.round(pourcentage * 10) / 10
        const minStockPercent = Math.round(((stockFaible.stockAlert / stockFaible.stockMaxi) * 100)* 10) / 10;

        let color;
        if(stockFaible.ref != "_NONE"){
            switch(true){
                case pourcentageRounded < minStockPercent :
                    color = "red"
                break;
                case pourcentageRounded >= minStockPercent && pourcentageRounded < stockFaible.perfectStock :
                    color = "warning"
                break;
                case pourcentageRounded >= stockFaible.perfectStock :
                    color = "teal"
                break;
            }
            return(
                <>
                    <Row className="marg">
                        <Col>
                            {stockFaible.label}
                        </Col>
                        <Col>
                            {stockFaible.ref}
                        </Col>
                        <Col>
                            <Progress className="radius" color={color} value={pourcentageRounded} />
                        </Col>
                        <Col>
                            {`${stockFaible.stock} unités (${pourcentageRounded}%)`}
                        </Col>
                        <Col>
                            <Button className="alerteButton" onClick={() => onDelete(stockFaible)}>Supprimer accessoire</Button>
                        </Col>
                        <Col>
                            <Button className="buttonAddRound" value={[stockFaible, minStockPercent]} onClick={() => handleToggle([stockFaible, minStockPercent])}>+</Button>
                        </Col>
                    </Row>
                </>
            )
        }
    })

    return(
        <>
            <h2 className="dashMarg">Liste des accessoires</h2>
            <div className="dashMarg">
                {stockProd}
            </div>
            <span className="showMore" onClick={handleToggleAllRef}>
                Voir tous les stocks
            </span>
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">Stock {selectedData.label}</h1>
                </ModalHeader>
                <ModalBody>
                    <EditPopUp
                        onSubmit={onSubmit}
                        initialValues={selectedData}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}
export default Stock;