import React, { useEffect, useState } from "react";
import { fetchSubCommands } from "../../services/crm";

const Indicator = (props) => {
    const [totalPrice, setTotalPrice] = useState();
    const [totalProdPrice, setTotalProdPrice] = useState();
    const userId = props.userId;
    const isSaler = props.isSaler;

    useEffect( () => {
		loadPrice();
	}, []);

    const loadPrice = async () => {
        if(isSaler === true){
            const query = {salerId:userId};
            const result = await fetchSubCommands(query);

            setTotalPrice(result.totalAmount)
            
            const query2 = {
                salerId:userId,
                status:{
                    $gte: 30,
                    $lte: 200
                }}
            const result2 = await fetchSubCommands(query2);
            setTotalProdPrice(result2.totalAmount)
        }else{
            const result = await fetchSubCommands();
            setTotalPrice(result.totalAmount)
    
            const query = {status:{
                $gte: 30,
                $lte: 200
                }}
            const result2 = await fetchSubCommands(query);
            setTotalProdPrice(result2.totalAmount)
        }
    }
    
    return(
        <>
            <div className="row dashMarg">
                <div className="divIndicator">
                    <div>Total commandes en cours</div>
                    <div className="fontEuro">{totalProdPrice != null ? `   ${parseFloat(totalProdPrice).toFixed(2)} ` : " 0 " }<i class="fa fa-eur"></i></div>
                </div>
                <div className="divIndicator">
                    <div>Total commandes</div>
                    <div className="fontEuro">{totalPrice != null ? `   ${parseFloat(totalPrice).toFixed(2)} ` : " 0 " }<i class="fa fa-eur"></i></div>
                </div>
            </div>
        </>
    )
} 

export default Indicator;