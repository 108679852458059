import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CreateEditPopup from "./CreateEditPopup";
import { Card, CardHeader, CardFooter, Col, Collapse, Button, Label, Row } from "reactstrap";
import { getRoles } from "../../reducers/params";
import UsersList from "../../components/Users/UserList";
import useModal from "../../hooks/useModal";
import useUserService from "../../hooks/services/useUserService";
import { getUser, getUserRoles } from "../Authentication/redux";
import roles from "../../constants/roles";
import { fetchToken } from "../../services/iam";
import { setJwtToken, setUserData } from "../../utils/session";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { fetchUsers } from "../../services/iam";
import { fetchOrganisations } from "../../services/crm";

const Users = ({ userRoles, user }) => {
  const [selectedData, setSelectedData] = useState();
  const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
  const [isCreated, setIsCreated] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedClientValue, setSelectedClientValue] = useState(null);
  const [inputClientValue, setInputClientValue] = useState('')
  const [selectedOrgaValue, setSelectedOrgaValue] = useState(null);
  const [inputOrgaValue, setInputOrgaValue] = useState('')
  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    loadData(1)
  }, [selectedClientValue, selectedOrgaValue]);


  const navigate = useNavigate();
  const rolesValues = [
    {
      value: "ROLE_USER",
      label: "Client",
    },
    {
      value: "ROLE_ADMIN",
      label: "Admin",
    },
    {
      value: "ROLE_SALER",
      label: "Commercial",
    },
    {
      value: "ROLE_USERB2C",
      label: "Client B2B",
    },
    {
      value: "ROLE_USERB2B",
      label: "Client B2C",
    },
    {
      value: "ROLE_USERB2B2C",
      label: "Client B2B2C",
    },
  ];

  const { toggle, active } = useModal();

  const { loadData, onSubmit, onDelete, data, pagination } = useUserService({
    selectedClientValue,
    selectedOrgaValue,
    selectedData,
    toggle,
  });

  const onEdit = (data) => {
    setIsCreated(false);
    setSelectedData(data);
    toggle();
  };

  const onClose = () => {
    setIsCreated(false);
    setSelectedData();
    toggle();
  };

  const onCreate = () => {
    setIsCreated(true);
    setSelectedData();
    toggle();
  };

  const onSuccess = () => {
    loadData(1);
    toggle();
  };

  const loadClientOptions = (input) => {
    return fetchUsers({
      $or: [
        { lastname: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.lastname}`,
      }));

      return { options };
    });
  }

  const loadOrgaOptions = (input) => {
    return fetchOrganisations({
      $or: [
        { name: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.name}`,
      }));

      return { options };
    });
  }
  
  const handleInputOrgaChange = (value) => {
    setInputOrgaValue(value)
  }

  const handleOrgaChange = (value) => {
    setSelectedOrgaValue(value)
  }

  const handleInputClientChange = (value) => {
    setInputClientValue(value)
  }

  const handleClientChange = (value) => {
    setSelectedClientValue(value)
  }

  const handleCollapse = () => {
    setIsOpen(!isOpen)
  }

  const handleTakeControl = async (data) => {
    const userId = data.id;
    const token = await fetchToken(userId)
    setUserData(data);
    setJwtToken(token.accessToken)
    navigate("/dashboard", {state:{data}});
    window.location.reload(true);
  };

  return (
    <div className="animated fadeIn">
      {active && (
        <CreateEditPopup
          data={selectedData}
          roles={rolesValues}
          active={active}
          onClose={onClose}
          onSuccess={onSuccess}
          onSubmit={onSubmit}
          isCreated={isCreated}
        />
      )}

      <Card>
        <CardHeader>
          <h1>Membres</h1>
        </CardHeader>
        <Col xs="10">
              <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
              <Collapse isOpen={isOpen} className="filterButton">
                <Row className="filterRow">
                  <Label className="filterLabel">Recherche par nom de membre </Label>
                  <Select.Async
                    placeholder="Nom du membre"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputClientChange}
                    onChange={handleClientChange}
                    value={selectedClientValue}
                    loadOptions={loadClientOptions}
                  />  
                </Row>
                <Row className="filterRow">
                  <Label className="filterLabel">Recherche par organisation </Label>
                  <Select.Async
                    placeholder="Nom de l'entreprise"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputOrgaChange}
                    onChange={handleOrgaChange}
                    value={selectedOrgaValue}
                    loadOptions={loadOrgaOptions}
                  />  
                </Row>
                </Collapse>
        </Col>
        <UsersList
          onEdit={onEdit}
          onDelete={onDelete}
          loadData={loadData}
          handleTakeControl={handleTakeControl}
          pagination={pagination}
          data={data}
          isAdmin={isAdmin}
        />

        <CardFooter>
          <div className="card-actions">
            <button onClick={onCreate} className={"btn btn-gray btn-padded"}>
              Ajouter un membre
            </button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Users);
