import React, { useEffect, useState } from "react";
import { fetchBanner } from "../../services/crm";

const InformationBanner = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState()
    const [isOn, setIsOn] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        const newData = await fetchBanner()
        setData(newData)
        setLoading(false)
        checkBannerInfo(newData)
    }

    const checkBannerInfo = (props) => {
        if(props.enabled){
            if(props.enabled === true){
                if(props.message){
                    if(props.message.length > 0){
                        setIsOn(true)
                    }
                }
            }
        }
    }

    const closeBanner = () => {
        setIsOn(false)
    }

    return(
        <>
            {isOn ? 
                <div className="information-banner">
                    <div onClick={() => closeBanner()} className="closeBannerButton">X</div>
                    <div className="bannerMessage">{data ? data.message :null}</div>
                </div>
            : null}
        </>
    )
}

export default InformationBanner;