import React, { Component } from "react";
import { Row, Col, Input, InputGroup, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import "ladda/dist/ladda-themeless.min.css";
import { WhFieldTxt } from "../../../components/Form/whFields";
import { required } from "../../../components/Form/whFields/WhValidator";


class FormUpdatePass extends Component {
	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<form onSubmit={handleSubmit}>
				{error && <Alert color="danger">{error}</Alert>}
				<Col>
					<Field
						name="passOld"
						placeholder="Entrer un nouveau mot de passe"
						component={WhFieldTxt}
						type="password"
						validate={required}
					/>
					<Field
						name="passNew"
						placeholder="Confirmer le mot de passe"
						component={WhFieldTxt}
						type="password"
						validate={required}
					/>
				</Col>
				<LaddaButton
					className="btn btn-gray btn-padded btn-fullWidth	"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type={"submit"}
				>
					<i class='fa fa-paper-plane-o'></i>
				</LaddaButton>
			</form>
		);
	}
}

FormUpdatePass = reduxForm({
	form: "FormUpdatePass",
})(FormUpdatePass);

export default FormUpdatePass;