import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavbarBrand,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import { connect } from "react-redux";
import { getUserRoles, getUser } from "../../Authentication/redux";
import * as sessionService from "../../../utils/session/index";
import { getBasket } from "../../../reducers/basket/index";

const logo = `url(img/logoRond.png)`;

const MyFooter = (props) => {
  const navigate = useNavigate();

  const sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  };

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  };

  const logout = () => {
    sessionService.logout().then((res) => {
      navigate("/login");
    });
  };


  const { userRoles } = props;
  const basket = props.basket;
  let isItems = false 
  if(basket[0]){
    isItems = true
  }else{
    isItems = false
  }

  return (
    <header className="app-header navbar">
      <div className={"navbar-brand-group"}>
        <NavbarBrand href="#" style={{ backgroundImage: logo }} />
        <span className={"navbar-brand-name"}>
          <p>
            <strong>MYDITEX</strong>
          </p>
          <small>MY DIGITAL TEXTILE</small>
        </span>
      </div>

      <Nav navbar>
        {userRoles.includes("ROLE_ADMIN") && (
          <NavItem className="d-md-down-none">
            <NavLink
              to="/dashboard"
              className="nav-link"
              style={{ padding: "0 20px" }}
            >
              Tableau de bord
            </NavLink>
          </NavItem>
        )}
        {(userRoles.includes("ROLE_ADMIN") ||
          userRoles.includes("ROLE_SALER")) && (
          <NavItem className="d-md-down-none">
            <NavLink
              to="/customers"
              className="nav-link"
              style={{ padding: "0 20px" }}
            >
              Client
            </NavLink>
          </NavItem>
        )}
        {userRoles.includes("ROLE_ADMIN") && (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Stocks
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavItem className="d-md-down-none">
                  <NavLink
                    to="/stocks"
                    className="nav-link"
                    style={{ padding: "0 20px" }}
                  >
                    Stocks Tissus
                  </NavLink>
                </NavItem>
              </DropdownItem>
              <DropdownItem>
                <NavItem className="d-md-down-none">
                  <NavLink
                    to="/accessoires"
                    className="nav-link"
                    style={{ padding: "0 20px" }}
                  >
                    Stocks accessoires
                  </NavLink>
                </NavItem>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {userRoles.includes("ROLE_ADMIN") && (
          <NavItem className="d-md-down-none">
            <NavLink
              to="/users"
              className="nav-link"
              style={{ padding: "0 20px" }}
            >
              Membres
            </NavLink>
          </NavItem>
        )}
        {userRoles.includes("ROLE_ADMIN") && (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Catalogue
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavItem className="d-md-down-none">
                  <NavLink
                    to="/products_manufactures"
                    className="nav-link"
                    style={{ padding: "0 20px" }}
                  >
                    Produits confectionnés
                  </NavLink>
                </NavItem>
              </DropdownItem>

              <DropdownItem>
                <NavItem className="d-md-down-none">
                  <NavLink
                    to="/fabrics"
                    className="nav-link"
                    style={{ padding: "0 20px" }}
                  >
                    Tissus
                  </NavLink>
                </NavItem>
              </DropdownItem>
              <DropdownItem>
                <NavItem className="d-md-down-none">
                  <NavLink
                    to="/specific-products"
                    className="nav-link"
                    style={{ padding: "0 20px" }}
                  >
                    Produits spécifiques
                  </NavLink>
                </NavItem>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Nav>

      <Nav className="ml-auto accountBar" navbar>
        <HeaderDropdownAccount />

        {(userRoles.includes("ROLE_USER") ||
          userRoles.includes("ROLE_USERB2B")||
          userRoles.includes("ROLE_USERB2B2C")||
          userRoles.includes("ROLE_USERB2C")) && (
          <NavItem className="d-md-down-none">
            <NavLink
              to="/account/basket"
              className="nav-link"
              style={{ paddingLeft: "25px" }}
            >
              <i class="fa fa-shopping-basket"></i>
              {isItems ? 
              <div className="basketInfo">
                {parseInt(basket.length)}
              </div>:null}
            </NavLink>
          </NavItem>
        )}
        <div className="btn btn-gray logout-btn" onClick={() => logout()}>
          <img src={`img/logout-icon.svg`} />
        </div>
      </Nav>
    </header>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  basket: getBasket(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(MyFooter);
