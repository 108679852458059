export default {
  items: [
    {
      name: "Page d'accueil",
      icon: "fa fa-home",
      url: "/dashboard",
      roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
    },
    {
      name: "Paramètres",
      icon: "fa fa-gear",
      roles: ["ROLE_ADMIN"],
      children: [
        {
          name: "Délais de livraison",
          url: "/shipping-delay",
          roles: ["ROLE_ADMIN"],
        },
        {
          name: "Maintenance",
          url: "/maintenance",
          roles: ["ROLE_ADMIN"],
        },
        {
          name: "Bannière d'information",
          url: "/information-banner",
          roles: ["ROLE_ADMIN"],
        },
        {
          name: "Gestion import de données",
          url: "/data-import",
          roles: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      name: "Le Catalogue",
      icon: "fa fa-list",
      roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
      children: [
        {
          name: "Produits confectionnés",
          url: "/products_manufactures",
          roles: ["ROLE_ADMIN", "ROLE_USERB2B", "ROLE_SALER", "ROLE_USER"],
        },
        {
          name: "Tissus",
          url: "/fabrics",
          roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USERB2B", "ROLE_USER"],
        },
        {
          name: "Produits spécifiques",
          url: "/specific-products",
          roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USERB2B", "ROLE_USER"],
        },
        {
          name: "Nos marques",
          url: "/product-selection",
          roles: ["ROLE_USERB2B2C", "ROLE_USERB2C"],
        },
      ],
    },
    {
      name: "Job liste",
      icon: "fa fa-print",
      url: "/jobs",
      roles: ["ROLE_ADMIN"],
    },
    {
      name: "Commandes",
      icon: "fa fa-inbox",
      roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER",  "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
      children: [
        {
          name: "Commander",
          url: "/product-selection",
          roles: ["ROLE_USER", "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
        },
        {
          name: "Commandes en cours",
          url: "/account/cmd",
          roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
        },
        {
          name: "Historique des commandes",
          url: "/account/cmd-history",
          roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B", "ROLE_USERB2B2C", "ROLE_USERB2C"],
        },
      ],
    },
    {
      name: "Fichiers",
      icon: "fa fa-file-image",
      url: "/files",
      roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B"],
    },
    {
      name: "Import DropBox",
      icon: "fa fa-file-image",
      url: "/fast-import",
      roles: ["ROLE_ADMIN"],
    },
    {
      name: "Gabarits",
      icon: "fa fa-file-image",
      url: "/gabarits",
      roles: ["ROLE_ADMIN", "ROLE_SALER", "ROLE_USER", "ROLE_USERB2B"],
    },
  ],
};
