import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import naturalPicto from "../../assets/picto/naturalPicto.png"
import polyesterPicto from "../../assets/picto/polyesterPicto.png"

const typeSelection = (props) => {
      
    return(
        <>
            <Row>
                <button className="iconSelection2" onClick={() => props.handleClickType('polyester')}>
                    <img src={polyesterPicto} className="tunnelPicto"></img>
                    <label className="labelPicto">Polyester</label>
                </button>
                <button className="iconSelection2" onClick={() => props.handleClickType('naturel')}>
                    <img src={naturalPicto} className="tunnelPicto"></img>
                    <label className="labelPicto">Naturel</label>
                </button>
            </Row>
        </>
    )

}
export default typeSelection;