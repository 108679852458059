import { fetch } from "../fetch.old";
import configApi from "../../constants/ApiConfig";

const endPoints = {
	login: "/login_check",
	logout: "/logout",
};

export const login = (username, password) =>
	fetch(`${configApi.url}${endPoints.login}`, "post", {
		email: username,
		password: password,
	});

export const me = () => fetch(`${configApi.url}/api/me`, "get");
