/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./local.css";

class Locals extends Component {
	render = () => {
		const { locals, local } = this.props;

		return <div className="locals" />;
	};
}

Locals.defaultProps = {
	local: "fr",
	locals: [],
};

Locals.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Locals);
