import React, { useMemo } from "react";
import { Button } from "reactstrap";
import Table from "../../Table";
import { sendResetPasswordLink } from "../../../services/iam";
import { toast } from "react-toastify";

const UsersList = ({
  onEdit,
  onDelete,
  pagination,
  loadData,
  data,
  isAdmin,
  handleTakeControl,
}) => {
  const handleSendPassword = async (data) => {
    try {
      await sendResetPasswordLink(data.id);
      toast.success(
        `Réinitialisation de mot de passe envoyé à ${data.firstname} ${data.lastname}`
      );
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  const formatRole = (roles) => {
    const rolesValues = [
      {
        value: "ROLE_USER",
        label: "Client",
      },
      {
        value: "ROLE_ADMIN",
        label: "Admin",
      },
      {
        value: "ROLE_SALER",
        label: "Commercial",
      },
      {
        value: "ROLE_USERB2C",
        label: "Client B2B",
      },
      {
        value: "ROLE_USERB2B",
        label: "Client B2C",
      },
      {
        value: "ROLE_USERB2B2C",
        label: "Client B2B2C",
      },
    ];

    return roles
      .map((r, index) => {
        let res = rolesValues.find((role) => role.value == r);
        return res ? res.label : "";
      })
      .join(", ");
  };

  const columns = useMemo(() => {
    return [
      {
        id: "firstname",
        accessor: "firstname",
        Header: "Prénom",
      },
      {
        id: "lastname",
        accessor: "lastname",
        Header: "Nom",
      },
      {
        id: "email",
        accessor: "email",
        Header: "Email",
      },
      {
        id: "organisationName",
        accessor: "organisationName",
        Header: "Société",
      },
      {
        id: "enabled",
        accessor: "enabled",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                padding: "5px 20px",
                color: "white",
                backgroundColor: value ? "#28CC97" : "#F12B2B",
                borderRadius: 5,
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {value ? "ACTIF" : "INACTIF"}
            </div>
          );
        },
      },
      {
        id: "roles",
        accessor: "roles",
        Cell: ({ value }) => {
          return formatRole(value);
        },
      },
      {
        id: "action",
        accessor: "action",
        width: 150,
        Header: "Actions",

        Cell: ({ row }) => {
          const currentData = row.original;
          return (
            <div className={"text-center"}>
              <Button color={"transparent"} onClick={() => onEdit(currentData)}>
                <i className={"fa fa-edit"} />
              </Button>
              {isAdmin ? (
                <Button
                  color={"transparent"}
                  onClick={() => handleTakeControl(currentData)}
                >
                  <i class={"fa fa-desktop"} />
                </Button>
              ) : null}
              <Button
                color={"transparent"}
                onClick={() => handleSendPassword(row.original)}
              >
                <i class="fa fa-paper-plane-o"></i>
              </Button>
              <Button
                color={"transparent"}
                onClick={() => onDelete(currentData)}
              >
                <i className={"far fa-trash-alt"} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        loadData={loadData}
        pagination={pagination}
        paginated
      />
    </div>
  );
};

export default UsersList;
