import React from "react";
import { Row, Col, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { SubmissionError } from "redux-form";
import { useNavigate, useLocation } from "react-router-dom";
import CreatePasswordForm from "./CreatePasswordForm";
import { createPassword } from "../../../services/iam";
import * as qs from "qs";

const CreatePassword = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleSubmit = async (values, userRoles) => {
    if (!values.cguAccepted) {
      toast.error("Vous devez accepter les conditions générales d'utilisation");
    } else if (values.passNew.length < 6) {
      toast.error("Veuillez saisir un mot de passe d'au moins 6 caractères");
    } else if (values.passOld === values.passNew) {
      try {
        const payload = {
          password: values.passNew,
          token: queryParams.token,
          cguAccepted: true,
          userId: queryParams.userId,
        };
        await createPassword(payload);

        navigate("/login", {
          state: {
            successAlert:
              "Vous pouvez désormais vous connecter avec vos identifiants",
          },
        });
      } catch (error) {
        if(error.response.data.message){
          throw new SubmissionError({
            _error: error.response.data.message,
          });
        }else{
          throw new SubmissionError({
            _error: error && error.message ? error.message : error,
          });
        }
      }
    } else {
      toast.error("Le mot de passe doit être identique");
    }
  };

  return (
    <div className="app flex-row align-items-center loginContainer">
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} />
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="justify-content-center">
            <div className={"loginHeader"}>
              <img src="../img/logo.svg" alt="" style={{ maxWidth: "100%" }} />

              <h1>Création de votre compte</h1>

              <p className="text-muted">
                Merci de saisir un mot de passe et d'accepter les conditions
                générales d'utilisation
              </p>
            </div>

            <div className="loginBody">
              <CreatePasswordForm onSubmit={handleSubmit} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreatePassword;
