import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { fetchBrandProducts, fetchBrands } from "../../services/pim";
import { Row, Button, ModalHeader, ModalBody, ModalFooter, Modal, Col, Input, Label, Collapse } from "reactstrap";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
import { setBasket, getBasket } from "../../reducers/basket"
import { connect } from "react-redux";
import { getUser } from "../Authentication/redux";
import Select from "react-select";

const ProductList = ({onSetBasket}) => {
    const [brand, setBrand] = useState()
    const [brandProducts, setBrandProducts] = useState()
    const { id } = useParams(); 
    const { toggle, active } = useModal();
    const [selectedData, setSelectedData] = useState();
    const [colorList, setColorList] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [pictoList, setPictoList] = useState();
    const [selectedPicto, setSelectedPicto] = useState();
    const [isChecked, setChecked] = useState(false);
    const [inputTextValue, setInputTextValue] = useState();
    const [quantityValue, setQuantityValue] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedRangeValue, setSelectedRangeValue] = useState(null);
    const [inputRangeValue, setInputRangeValue] = useState('');
    const [selectedTypeValue, setSelectedTypeValue] = useState(null);
    const [inputTypeValue, setInputTypeValue] = useState('');
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState();
    const [maxPage, setMaxPage] = useState(0)

    useEffect(() =>{
        loadData(page)
    }, [page])

    useEffect(() => {
        createColorList()
    }, [selectedData, selectedColor])

    useEffect(() => {
        createPictoList()
    }, [selectedData, selectedPicto])
    
    useEffect(() => {
        loadData(1)
    },[selectedValue, selectedRangeValue, selectedTypeValue, inputRangeValue, inputTypeValue, inputValue])

    useEffect(() => {
        if(active === false){
            handleResetProductInfo()
        }
    }, [active])

    const handleCollapse = () => {
        setIsOpen(!isOpen)
        setSelectedValue(null)
      }
      
    const handleChange = (value) => {
        setSelectedValue(value)
    }

    const fetchProductSelect = async (query) => {
        const product = await fetchBrandProducts(query)

        setSelectedData(product.data[0])

    }

    const handleInputChange = (value) => {
        setInputValue(value)
    }
  
    const handleTypeChange = (value) => {
        setSelectedTypeValue(value)
    }
    
    const handleInputTypeChange = (value) => {
        setInputTypeValue(value)
    }

    const handleRangeChange = (value) => {
        setSelectedRangeValue(value)
    }

    const handleInputRangeChange = (value) => {
      setInputRangeValue(value)
    }
  
    const loadRefOptions = (input) => {

      return fetchBrandProducts({
        brandId:id,
        $or: [
          { genericReference: { $like: `%${input}%` } },
        ],

      }).then((result) => {
            // Regroupement des données par genref
            const groupedData = result.data.reduce((acc, item) => {
            // Supprimer les espaces en fin de la valeur du genref
            const trimmedRef = item.genericReference.trim();

            if (!acc[trimmedRef]) {
                acc[trimmedRef] = { label: trimmedRef, value: trimmedRef };
            }
            return acc;
        }, {});

        // Transformation de l'objet regroupé en tableau
        const options = Object.values(groupedData);

        return { options };
      });
    }

    const loadRangeOptions = (input) => {
        return fetchBrandProducts({
            $select: ['model'],
            brandId:id,
            $or: [
            { model: { $like: `%${input}%` } },
            ],

        }).then((result) => {
            // Regroupement des données par model
            const groupedData = result.data.reduce((acc, item) => {
            // Supprimer les espaces en fin de la valeur du modèle
            const trimmedModel = item.model.trim();

            if (!acc[trimmedModel]) {
                acc[trimmedModel] = { label: trimmedModel, value: trimmedModel };
            }
            return acc;
        }, {});

        // Transformation de l'objet regroupé en tableau
        const options = Object.values(groupedData);

        return { options };
        });
    }

    const loadTypeOptions = (input) => {
        return fetchBrandProducts({
            $select: ['type'],
            brandId:id,
            $or: [
                { type: { $like: `%${input}%` } },
            ],
        }).then((result) => {
            // Regroupement des données par type
            const groupedData = result.data.reduce((acc, item) => {
            // Supprimer les espaces en fin de la valeur du type
            const trimmedType = item.type.trim();

            if (!acc[trimmedType]) {
                acc[trimmedType] = { label: trimmedType, value: trimmedType };
            }
            return acc;
        }, {});

        // Transformation de l'objet regroupé en tableau
        const options = Object.values(groupedData);

        return { options };
        });
    }
    
  
    const handleResetProductInfo = () => {
        setQuantityValue(1)
        setSelectedColor()
        setSelectedPicto()
        setInputTextValue()
        setChecked(false)
    }

    const loadData = async (pageNumber, limit =50) => {
        const brandQuery = {
            id:id,
        }

        const brandData = await fetchBrands(brandQuery)
        setPage(pageNumber);
        setBrand(brandData.data[0])
        let productQuery

        productQuery = {
            brandId:id,
            $skip: (pageNumber - 1) * limit, $limit: limit,
        }

        if(selectedValue){
            productQuery = {
                ...productQuery,
                genericReference: selectedValue.label
            }
        }

        if(selectedRangeValue){
            productQuery = {
                ...productQuery,
                model: selectedRangeValue.label
            }
        }
 
        if(selectedTypeValue){
            productQuery = {
                ...productQuery,
                type: selectedTypeValue.label
            }
        }
 
 
        const {data, ...paginationParams} = await fetchBrandProducts(productQuery)
        setPagination(paginationParams)
        const newMax = Math.ceil(paginationParams.total / paginationParams.limit)
        setMaxPage(newMax)

        /* const productList = data.map((product, index) => {
            const handleClick = (props) => {
               toggle()
               setSelectedData(props)
            }

            return(
                <>
                    <div className="productCard" onClick={() => handleClick(product)}>
                        <img className="imageProduct" src={product.visual}></img>
                        <div className="productCardType">{product.type}</div>
                        <div className="productCardModel">{product.model}</div>
                        <div className="productCardRef">{product.ref}</div>
                        <div className="productCardPrice">{`${product.priceB2B} €`}</div>
                    </div>
                </>
            )
        }) */
        const seenRefs = new Set();

        const productList = data.map((product, index) => {
        const handleClick = (props) => {
            toggle();
            setSelectedData(props);
        };

        // Vérifie si la référence a déjà été vue
        if (seenRefs.has(product.genericReference)) {
            return null; // Retourne null pour les produits ayant des références en double
        }

        // Ajoute la référence au set des références vues
        seenRefs.add(product.genericReference);

        return (
            <div key={index} className="productCard" onClick={() => handleClick(product)}>
            <img className="imageProduct" src={product.visual} alt={product.model} />
            <div className="productCardType">{product.type}</div>
            <div className="productCardModel">{product.model}</div>
            <div className="productCardRef">{product.ref}</div>
            <div className="productCardPrice">{`${product.priceB2B} €`}</div>
            </div>
        );
        }).filter(Boolean); // Filtre les null pour les enlever du tableau final


        setBrandProducts(productList)

    }
    
    const createColorList = () => {
          
        if(selectedData){
            if(selectedData.isCustomisableColor){
                
                const arrayColor = JSON.parse(selectedData.colors)

                const outputArray = arrayColor.map(obj => {
                    const [code, hexa] = Object.entries(obj)[0];
                    return { code, hexa };
                });


                const colorsList = outputArray.map((color, index) => {
                
                 const handleClickColor = async (props) => {
                     //load new data
                     let query 
                     if(selectedData.isCustomisableColor === 1 && selectedData.isCustomisablePicto === 1){
                        query = {
                            ref: `${selectedData.genericReference}${selectedData.selectedPicto}${props.color.code}`
                        }
                     }else if (selectedData.isCustomisableColor  === 1 && selectedData.isCustomisablePicto === 0){
                        query = {
                            ref: `${selectedData.genericReference}${props.color.code}`
                        }
                     }

                    fetchProductSelect(query)   
                 }

                 let isSelect = false

                 if(selectedData.selectedColor === color.code){
                    isSelect = true
                 }

                 if(color != null){
                     return(
                         <>
                             <div>
                                 <div style={{backgroundColor:color.hexa}} className={ isSelect ? "colorListSelect" : "colorList"} onClick={() => handleClickColor({color, index})}>
                                     
                                 </div>
                             </div>
                         </>
                     )
                 }
                })
                setColorList(colorsList)
            }   
        }
    }

    const createPictoList = () => {
        if(selectedData){
            if(selectedData.isCustomisablePicto){

                const pictosList = JSON.parse(selectedData.pictos).map((picto, index) => {


                 const handleClickPicto = async (props) => {
                    //load new data
                    let query 
                    if(selectedData.isCustomisableColor === 1 && selectedData.isCustomisablePicto === 1){
                       query = {
                           ref: `${selectedData.genericReference}${props.picto.id}${selectedData.selectedColor}`
                       }
                    }else if (selectedData.isCustomisableColor  === 0 && selectedData.isCustomisablePicto === 1){
                       query = {
                           ref: `${selectedData.genericReference}${props.picto.id}`
                       }
                    }

                   fetchProductSelect(query)   
                }
                 let isSelect = false
                 if(selectedData.selectedPicto === picto.id){
                    isSelect = true
                 }
                 

                 if(picto != null){
                     return(
                         <>
                             <div>
                                <div className={ isSelect ? "pictoListSelect" : "pictoList"} onClick={() => handleClickPicto({picto, index})}>
                                    <img className="imgPictoList" src={picto.value}></img>
                                </div>
                             </div>
                         </>
                     )
                 }
                })
                setPictoList(pictosList)
            }   
        }
    }

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const handleInputTextChange = (event) => {
        setInputTextValue(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setQuantityValue(event.target.value);
    };

    const handleAdd = () => {
        const newQuantity = quantityValue + 1
        setQuantityValue(newQuantity)
    }

    const handleRemove = () => {
        const newQuantity = quantityValue - 1
        if(newQuantity >= 1){
            setQuantityValue(newQuantity)
        }
    }

    const handleIncreasePage = () => {
        if(page < maxPage){
            setPage(page + 1)
        }
    }

    const handleDecreasePage = () => {
        if(page > 1){
            setPage(page - 1)
        }
    }

    const handleAddToCart = () => {
        let item = {
            product:selectedData,
            quantity:quantityValue
        }
        let error = false

        if(selectedData.isCustomisableText){
            if(isChecked === true && !inputTextValue){
                toast.warn("Veuillez indiquer votre personalisation ou décocher la case texte personalisé")
                error = true
            }else{
                if(isChecked === true){
                    item = {...item, customText:inputTextValue}
                }
            }
        }
        if(error === false){
            try{
                onSetBasket({item:item})
                toast.success("Article ajouté au panier")
                toggle()
            }catch(error){
                console.log(error)
                toast.error("Une erreur est survenue")
            }
            /* setTimeout(() => {
                toggle()
              }, 1000); */
        }
    }

    return(
        <>
            <div className="etapeActive">
                {brand ? `Produits ${brand.label}`: null}
            </div>
            <Col xs="10">
                <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
                <Collapse isOpen={isOpen} className="filterButton">
                  <Col>
                    <Row className="filterRow">
                        <Label className="filterLabel">Recherche par référence produit</Label>
                        <Select.Async
                            placeholder="Référence"
                            noResultsText="Pas de résultat"
                            className="searchSaler"
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            value={selectedValue}
                            loadOptions={loadRefOptions}
                        />  
                    </Row>
                    <Row>
                        <Label className="filterLabel">Recherche par modèle de produit </Label>
                        <Select.Async
                            placeholder="Modèle"
                            noResultsText="Pas de résultat"
                            className="searchSaler"
                            onInputChange={handleInputRangeChange}
                            onChange={handleRangeChange}
                            value={selectedRangeValue}
                            loadOptions={loadRangeOptions}
                        />  
                    </Row>
                    <Row>
                        <Label className="filterLabel">Recherche par type de produit </Label>
                        <Select.Async
                            placeholder="Type"
                            noResultsText="Pas de résultat"
                            className="searchSaler"
                            onInputChange={handleInputTypeChange}
                            onChange={handleTypeChange}
                            value={selectedTypeValue}
                            loadOptions={loadTypeOptions}
                        />  
                    </Row>
                  </Col>
                </Collapse> 
            </Col>
            <div>
            <Row className="modalSubmit">
                    <Button onClick={handleDecreasePage} className="inputButton">{'<'}</Button>
                    <div>Page {page} sur {maxPage}</div>
                    <Button onClick={handleIncreasePage} className="inputButton">{'>'}</Button>
                </Row>
                <Row>
                    {brandProducts ? brandProducts : "Aucun produit trouvé"}

                </Row>
                <Row className="modalSubmit">
                    <Button onClick={handleDecreasePage} className="inputButton">{'<'}</Button>
                    <div>Page {page} sur {maxPage}</div>
                    <Button onClick={handleIncreasePage} className="inputButton">{'>'}</Button>
                </Row>
            </div>
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
            >
                <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">{selectedData ? selectedData.ref : null}</h1>
                </ModalHeader>
                <ModalBody>
                    {selectedData ?
                    <>
                        <Row>
                            <Col>
                                <img className="imageProductInfo" src={selectedData.visual}></img>
                            </Col>
                            <Col>
                                <div className="productInfoType">
                                    {selectedData.type}
                                </div>
                                <div className="productInfoModel">
                                    {selectedData.model}
                                </div>
                                <div className='rowProduct'>
                                    <Button className="inputButton" onClick={() => handleRemove()} > - </Button>
                                    <Input  className="inputNumber" onChange={() => handleQuantityChange()} value={quantityValue} type="number" step="1"></Input>
                                    <div>Pièce(s)</div>
                                    <Button className="inputButton" onClick={() => handleAdd()} > + </Button>
                                </div>
                                
                                {selectedData.isCustomisableText === 1 ?
                                    <div className="productInfoCustomText">
                                        <Row>
                                            <Label className="productInfoLabelCustom">Texte personnalisé : </Label>
                                            <input
                                                type="checkBox"
                                                value={isChecked}
                                                onChange={() => handleCheckboxChange()}
                                            >

                                            </input>
                                        </Row>
                                        {isChecked ? 
                                            <Input
                                                value={inputTextValue}
                                                onChange={(event) => handleInputTextChange(event)}
                                            >
                                            </Input>
                                        :null}
                                    </div>
                                 :null}
                                {selectedData.isCustomisableColor === 1 ?
                                    <>
                                        <div className="productInfoColors">
                                            Couleurs disponibles 
                                        </div>
                                        <Row>
                                            {colorList ? colorList : null}
                                        </Row>
                                    </>
                                :null}
                                {selectedData.isCustomisablePicto === 1 ?
                                    <>
                                        <div className="productInfoColors">
                                            Images disponibles 
                                        </div>
                                        <Row>
                                            {pictoList ? pictoList : null}
                                        </Row>
                                    </>
                                :null}
                                <div className="productInfoPriceDiv">
                                    <div className="productInfoPrice">
                                        {`Prix unitaire HT : ${selectedData.priceB2B} €`}
                                    </div>
                                    <div className="productInfoPrice">
                                        {`Prix total HT : ${parseFloat(selectedData.priceB2B * quantityValue)} €`}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                    :null}
                </ModalBody>
                <ModalFooter>
                    <Button className={"btn btn-gray btn-padded"} onClick={() => handleAddToCart()}>
                        Ajouter au panier
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
	basket: getBasket(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBasket: (item) => dispatch(setBasket(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);