import React, {useEffect, useState} from "react";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import { toast } from "react-toastify";
import { Button, Row, Input, Label } from "reactstrap";
import { createMedias, uploadMediaLink } from "../../services/crm";

const FileUploadLink = (props) => {
    const {userRoles, commandId, isSaler, userId} = props
    const organisationId = props.user.organisationId
    const [inputLinkValue, setInputLinkValue] = useState()
    const [inputNameValue, setInputNameValue] = useState()

    const handleAddLink = async () => {

        const payload= {
            downloadUrl: inputLinkValue,
            organisationId: organisationId,
            name: inputNameValue ? inputNameValue : null
        }
        
        try{
            const media = await createMedias(payload)
            toast.success("Fichier enregistré")
            setTimeout(() => {
                window.location.reload()
                }, 2000)
        }catch(error){
            toast.error("Une erreur est survenue")
        }
    };

    const handleChangeLinkInput = (props) => {
        setInputLinkValue(props.target.value)
    };

    const handleChangeNameInput = (props) => {
        setInputNameValue(props.target.value)
    };

    return(
        <>
            <div className="containerColCenter" style={{ marginBottom: '65px' }} >
                <Row>
                    <div className="centerTitle" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <h3>Ajout par lien de téléchargement</h3>
                        <div></div>
                    </div>
                </Row>
                <Row>
                    <Input 
                    className="inputMediaDB"
                    placeholder="Nom du fichier"
                    value={inputNameValue}
                    onChange={(event) => handleChangeNameInput(event)}
                    >
                    </Input>
                    
                </Row>
                <Row>
                    <Input 
                    className="inputMediaDB"
                    placeholder="Lien vers le fichier" 
                    value={inputLinkValue}
                    onChange={(event) => handleChangeLinkInput(event)}
                    >
                    </Input>
                    
                </Row>
            </div>
            <Button className="right" onClick={handleAddLink} color="dark">Enregistrer</Button>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(FileUploadLink);