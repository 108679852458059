import React, { Component } from "react";
import { Form, Collapse, Row, Col, Label } from "reactstrap";
import { Field, FieldArray, getFormError, getFormSyncErrors, getFormValues, reduxForm } from "redux-form";
import {
  WhFieldSelect,
  WhFieldTxt,
} from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { required, numeric } from "../../components/Form/whFields/WhValidator";
import { fetchFabrics } from "../../services/pim";
import RenderPrices from "./RenderPrices"
import { connect } from "react-redux";

class FormPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active: false
    };
  }

  handleToggle = () => {
    this.setState({active:!this.state.active})
  }

  async loadTissu(input) {
    return fetchFabrics({ ref: { $like: `${input}%` } }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: item.ref,
      }));
      return { options };
    });
  }

  render() {
    const { handleSubmit, submitting, formError, currentValues } = this.props;
    const active = this.state.active;
    const initialValues = this.props.initialValues

    const fabricTypeOptions = [
      { value: "polyester", name: "Polyester" },
      { value: "naturel", name: "Naturel" },
    ];

    const priceTypeOptions = [
      { value: "coef", name: "coef" },
      { value: "price", name: "price" },
    ];

    return (
      <Form onSubmit={handleSubmit} className="form-horizontal">
        <ModalBody>
          <Field
            label="Référence Tissu : "
            name="fabric.ref"
            component={WhFieldTxt}
            type="text"
            className="form-control"
            validate={required}
          />
          <Field
            label="Nom : "
            name="fabric.name"
            component={WhFieldTxt}
            type="text"
            className="form-control"
            validate={required}
          />
          <Field
            label="Description : "
            name="description"
            component={WhFieldTxt}
            type="text"
            className="form-control"
          />
          <Field
            label="Stock : "
            name="fabric.stock"
            component={WhFieldTxt}
            type="text"
            className="form-control"
            validate={[required, numeric]}
          />
          <Field
            label="Seuil d'alerte du stock : "
            name="fabric.stockAlert"
            component={WhFieldTxt}
            type="text"
            className="form-control"
            validate={[required, numeric]}
          />
          <Field
            label="Type de tissu : "
            name="fabric.type"
            component={WhFieldSelect}
            type="select"
            options={fabricTypeOptions}
            multi={false}
            className="form-control"
            validate={[required]}
          />

          <h2 className="subTitleModal" style={{ marginTop: 25 }}>
            Grille tarifaire :{" "}
          </h2>
          <Row>
            <Col xs="12">
              <div className={formError.myditexPrices ? "accordion errorCollapse" : "accordion"}>
                <div className="accordionDiv" >
                  <div className="dropButton">
                    <i onClick={this.handleToggle} className={"fa fa-edit icon"} />
                  </div>
                  <Label className="accordionLabel" onClick={this.handleToggle}>Tarif standard</Label>
                </div>
                  <Collapse 
                    className="marg"
                    isOpen={active}>
                      
                    <Field
                      label="Type de prix: "
                      name="myditexPrices.priceType"
                      component={WhFieldSelect}
                      type="select"
                      options={priceTypeOptions}
                      multi={false}
                      className="form-control"
                      validate={[required]}
                    />
                    <Field
                      label="0 à 4 unités : "
                      name="myditexPrices.price1"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="5 à 9 unités : "
                      name="myditexPrices.price2"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="10 à 19 unités : "
                      name="myditexPrices.price3"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="20 à 49 unités : "
                      name="myditexPrices.price4"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="50 à 99 unités : "
                      name="myditexPrices.price5"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="100 à 199 unités : "
                      name="myditexPrices.price6"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="200 à 500 unités : "
                      name="myditexPrices.price7"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                    <Field
                      label="plus de 500 unités : "
                      name="myditexPrices.price8"
                      component={WhFieldTxt}
                      type="text"
                      className="form-control"
                      validate={[required, numeric]}
                    />
                  </Collapse>
                  {formError.myditexPrices ? <div className="errorFormMessage">Obligatoire !</div> : null}
              </div>
            </Col>
          </Row>
          <Collapse 
            className="marg" 
            >
          </Collapse>
          <FieldArray name="customPrices" component={RenderPrices} currentValues={currentValues}/>
        </ModalBody>
        <ModalFooter>
          <LaddaButton
            className="btn btn-gray btn-padded btn-ladda"
            loading={submitting}
            data-style={EXPAND_LEFT}
            type={"submit"}
          >
            Enregistrer{" "}
          </LaddaButton>
        </ModalFooter>
      </Form>
    );
  }
}

const formName = "FormPopup"

FormPopup = reduxForm({
  form: formName,
  enableReinitialize: true
})(FormPopup);

export const mapStateToProps = (state) => ({
  formError: getFormSyncErrors(formName)(state),
  currentValues: getFormValues(formName)(state)
});

export default connect(mapStateToProps)(FormPopup);
