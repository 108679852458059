import React, { Component } from "react";
import { Row, Col, Input, InputGroup, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import "ladda/dist/ladda-themeless.min.css";


const renderFieldLogin = ({ name, input }) => {
	return (
		<InputGroup className="mb-3">
			<Input type="text" placeholder="Email" {...input} />
		</InputGroup>
	);
};

class FormForgot extends Component {
	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<form onSubmit={handleSubmit}>
				{error && <Alert color="danger">{error}</Alert>}
				<Field
					name="username"
					component={renderFieldLogin}
					type="text"
				/>
				<LaddaButton
					className="btn btn-gray btn-padded btn-fullWidth	"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type={"submit"}
				>
					<i class='fa fa-paper-plane-o'></i>
				</LaddaButton>
			</form>
		);
	}
}

FormForgot = reduxForm({
	form: "FormForgot",
})(FormForgot);

export default FormForgot;
