import React from "react";
import CKEditor from "ckeditor4-react";
import { change } from "redux-form";
import ApiConfig from "../../../constants/ApiConfig";

class FieldCkEditor extends React.Component {
	onChange = evt => {
		try {
			const data = evt.editor.getData();
			this.props.meta.dispatch(
				change(this.props.meta.form, this.props.input.name, data),
			);
		} catch (error) {}
	};

	render() {
		const { input } = this.props;
		CKEditor.editorUrl = ApiConfig.ckeditorInstance;
		return (
			<CKEditor
				data={input.value}
				config={{
					startupOutlineBlocks: "true",
					autoParagraph: false,
					toolbar: [
						[
							"Heading",
							"Bold",
							"Italic",
							"Link",
							"Format",
							"Styles",
							"Source",
							"Sourcedialog",
							"Showblock",
							"Table",
							"HorizontalRule",
							"JustifyLeft",
							"JustifyCenter",
							"JustifyRight",
							"ShowBlocks",
							"Glyphicons",
						],
					],
				}}
				onChange={this.onChange}
			/>
		);
	}
}

export default FieldCkEditor;
