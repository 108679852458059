import React, { useState, useEffect } from "react";
import Select from "react-select/lib/Select";
import { Row, Col } from "reactstrap";


const productTissuSelec = (props) => {

    const options = props.selectedValue.map((item) => ({
        value: item.id,
        ref: item.ref,
        label: `${item.ref} ${item.name}`,
    }));


    return(
        <>
            <Row>
                <Col>
                    <Select
                        placeholder="Choisissez un tissu"
                        noResultsText="Pas de résultat"
                        className="searchSaler"
                        onChange={props.handleClickTissu}
                        value={props.selectedValue}
                        options={options}
                        isClearable={false}
                    />
                </Col>
            </Row>
        </>
    )
}
export default productTissuSelec;