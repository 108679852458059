import React, { useState } from "react";
import Select from "react-select/lib/Select";
import { Button, Input, Label, ModalFooter, NavbarToggler } from "reactstrap";
import { updateMedias } from "../../services/crm";

const StatusSelect = (props) => {
    const selectedData = props.selectedData
    const [selectedValue, setSelectedValue] = useState();
    const [inputValue, setInputValue] = useState();

    const handleChangeInput = (props) => {
        setInputValue(props.target.value)
    };

    const handleChange = (props) => {
        setSelectedValue(props)
    };

    const handleSubmit = async () => {
        const query = {
            status: selectedValue ? selectedValue.value : selectedData.status,
            information: inputValue ? inputValue : selectedData.information
        }
        try{
            await updateMedias(selectedData.id, query);
            window.location.reload()
        }catch(error){
            console.log(error)
        }
    }
    const options = [
        {value: 10, label: "Le fichier n'est pas vérifié"},
        {value: 100, label: "Fichier est non conforme"},
        {value: 200, label: "Fichier conforme"}
    ]

    return(
        <>
            <div>
                <Select
                    options = {options}
                    value={selectedValue ? {label: selectedValue.label, value: selectedValue.value} : selectedData ? {label: selectedData.currentStatusName, value: selectedData.status }: "ici"}
                    onChange={(value) => handleChange(value)}
                    >
                
                </Select>

                <Label>Informations supplémentaires :</Label>
                <Input
                    value = {inputValue ? inputValue : selectedData.information}
                    onChange={(event) => handleChangeInput(event)}>

                </Input>
                <ModalFooter>
                    <Button
                        color="dark"
                    onClick={() => handleSubmit()}>
                        Enregistrer
                    </Button>
                </ModalFooter>
            </div>
        </>
    )
}

export default StatusSelect;