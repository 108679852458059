import React from "react";
import { Button } from "reactstrap";

const Cgv = (props) => {

    return(
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <iframe src="https://docs.google.com/document/d/1U9vq0eJjt7ws74kR4OducEpYK_qeRhi6/preview" width="640" height="500" allow="autoplay"></iframe>
            </div>
            <Button
                color="dark"
                className="modalSubmit"
                onClick = {() => props.handleCgv()}
            >
                Valider les CGV
            </Button>
        </>
    )
}

export default Cgv;