import React, { Component } from "react";
import { Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Select from "react-select";
import { required, numeric } from "../../components/Form/whFields/WhValidator";


class FormAccessoire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const AsyncComponent = Select.Async;

		const { error, handleSubmit, pristine, reset, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
				<Field
						label="Référence : "
						name="ref"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={required}
					/>
					<Field
						label="Nom : "
						name="label"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={required}
					/>
					<Field
						label="Stock : "
						name="stock"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={[required, numeric]}
					/>
					<Field
						label="Seuil d'alerte du stock : "
						name="stockAlert"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={[required, numeric]}
					/>
					<Field
						label="Seuil de stock parfait : "
						name="perfectStock"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={[required, numeric]}
					/>
                    <Field
						label="Stock maximal : "
						name="stockMaxi"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={[required, numeric]}
					/>
					<Field
						label="Prix unitaire : "
						name="price"
						component={WhFieldTxt}
						type="text"
						className="form-control"
						validate={[required, numeric]}
					/>
					
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormAccessoire = reduxForm({
	form: "FormAccessoire",
})(FormAccessoire);

export default FormAccessoire;
