import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import naturalPicto from "../../assets/picto/naturalPicto.png"
import polyesterPicto from "../../assets/picto/polyesterPicto.png"
import { fetchTissuConfection } from "../../services/pim";

const productTissuTypeSelec = (props) => {

        const [naturals, setNaturals] = useState([])
        const [polyesters, setPolyesters] = useState([])

        useEffect(() => {
            loadTissu()
        }, [])

        const ref = props.item.genericReference

        const loadTissu = async () => {
            const payload = {
                genericReference:ref
            }

            const res = await fetchTissuConfection(payload)

            if(res != undefined){
                let tissuNat = []
                let tissuPoly = []


                res.map((tissu, index) =>{
                    if(tissu.ref[0] === 'C'){
                        tissuNat.push(tissu)
                    }else{
                        tissuPoly.push(tissu)
                    }
                })

                setNaturals(tissuNat)
                setPolyesters(tissuPoly)
            }

        }

        return(
            <>
                <Row>

                    {polyesters[0] ?
                        <button className="iconSelection2" onClick={() => props.handleClickType(polyesters)}>
                            <img src={polyesterPicto} className="tunnelPicto"></img>
                            <label className="labelPicto">Polyester</label>
                        </button>
                    :null}

                    {naturals[0] ? 
                        <button className="iconSelection2" onClick={() => props.handleClickType(naturals)}>
                            <img src={naturalPicto} className="tunnelPicto"></img>
                            <label className="labelPicto">Naturel</label>
                        </button>
                    :null}

                </Row>
            </>
        )

}
export default productTissuTypeSelec;