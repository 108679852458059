import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "./FormPopup";
import * as productService from "../../services/fabric";
import Loading from "../../components/Loading";

class EditProductPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: {},
      loaded: true,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen = (id = null) => {
    this.setState({
      create: true,
      open: true,
    });
    if (id) {
      this.setState({
        create: false,
        loaded: false,
      });

      productService.view(id).then((data) => {
        this.setState({
          data,
          loaded: true,
        });
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { className, active, data, isCreated } = this.props;
    const { loaded } = this.state;

    return (
      <Modal
        isOpen={active}
        className={className}
        fade={false}
        toggle={this.handleClose}
      >
        <ModalHeader toggle={() => this.handleClose()}>
          {isCreated ? " Créer un nouveau tissu" : "Editer un tissu"}
        </ModalHeader>

        {!loaded && <Loading />}
        {loaded && (
          <FormPopup onSubmit={this.props.onSubmit} initialValues={{ ...data, customPrices: data.customPrices }} />
        )}
      </Modal>
    );
  }
}

export default EditProductPopup;
