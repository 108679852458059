import React from "react";
import { Form, Progress, Col, Row } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { WhFieldTxt } from "../../components/Form/whFields";

const EditPopUp = (props) => {
    const { currentValues } = props;

    const initialValues = props.initialValues;
    let pourcentageRounded;
    let color;
    //const yellow = 100 - (currentValues ? (parseInt(currentValues.minStockPercent) + (100 - parseInt(currentValues.perfectStock))) : (parseInt(initialValues.minStockPercent) + (100 - parseInt(initialValues.perfectStock))))

    if(currentValues){
        const pourcentage = (currentValues.stock / currentValues.stockMaxi) * 100  
        pourcentageRounded = Math.round(pourcentage * 10) / 10

        switch(true){
            case pourcentageRounded < currentValues.minStockPercent :
                color = "red"
            break;
            case pourcentageRounded >= currentValues.minStockPercent && pourcentageRounded < currentValues.perfectStock :
                color = "warning"
            break;
            case pourcentageRounded >= currentValues.perfectStock :
                color = "teal"
            break;
            default: console.log("default")
        }
    }else{
        const pourcentage = (initialValues.stock / initialValues.stockMaxi) * 100  
        pourcentageRounded = Math.round(pourcentage * 10) / 10

        switch(true){
            case pourcentageRounded < initialValues.minStockPercent :
                color = "red"
            break;
            case pourcentageRounded >= initialValues.minStockPercent && pourcentageRounded < initialValues.perfectStock :
                color = "warning"
            break;
            case pourcentageRounded >= initialValues.perfectStock :
                color = "teal"
            break;
            default: console.log("default")
        }
    }

    return(
        <>
            <Form onSubmit = {props.handleSubmit} className="form-horizontal">
                <Row>
                        <Col sm={2}>
                            {initialValues.ref}
                        </Col>
                        <Col sm={2}>
                            {initialValues.name}
                        </Col>
                        <Col>
                            <Progress className="radius" color={color} value={pourcentageRounded} />
                        </Col>
                        <Col sm={2}>
                            { currentValues ? `${currentValues.stock}ml (${pourcentageRounded}%)` : `${initialValues.stock}m (${pourcentageRounded}%)`}
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={4}>
                            <h2 className="modalMarg">
                                Stock
                            </h2>
                            <Row className="modalMarg">
                                <Col sm={8}>
                                    <div>
                                        Stock actuel :
                                    </div>
                                </Col>
                                <Col>
                                    <Field
                                        name="stock"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col sm={1}>
                                    <div>
                                        ml
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <div>
                                        Ajout de stock :
                                    </div>
                                </Col>
                                <Col>
                                    <Field
                                        name="addStock"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col sm={1}>
                                    <div>
                                        ml
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <div>
                                        Suppression de stock :
                                    </div>
                                </Col>
                                <Col>
                                    <Field
                                        name="unStock"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col sm={1}>
                                    <div>
                                        ml
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <div>
                                        Stock maxi :
                                    </div>
                                </Col>
                                <Col>
                                    <Field
                                        name="stockMaxi"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col sm={1}>
                                    <div>
                                        ml
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>

                        </Col>
                        <Col>
                            <h2 className="modalMarg">
                                Ajustement de la criticité
                            </h2>
                                <Progress multi className="radius modalMarg">
                                    <Progress bar color="red" value= {currentValues ? currentValues.minStockPercent : initialValues.minStockPercent} />
                                    <Progress bar color="gray-600" value= {currentValues ? 100 - currentValues.minStockPercent : 100 - initialValues.minStockPercent} />
                                </Progress>   
                            <Row className="modalMarg">
                                <Col md="auto">
                                    <div>
                                    {`Stock`} <span className="fontRed">faible</span>{` : Stock <`}
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <Field
                                        name="minStockPercent"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col md="auto">
                                    <div>
                                        % au stock maxi 
                                    </div>
                                </Col>
                                
                            </Row>
                            <Progress multi className="radius modalMarg">
                                    <Progress bar color="gray-600" value= {currentValues ? currentValues.minStockPercent : initialValues.minStockPercent} />
                                    <Progress bar color="warning" value= {100 - (currentValues ? (parseInt(currentValues.minStockPercent) + (100 - parseInt(currentValues.perfectStock))) : (parseInt(initialValues.minStockPercent) + (100 - parseInt(initialValues.perfectStock))))} />
                                    <Progress bar color="gray-600" value= {100 - (currentValues ? currentValues.perfectStock : initialValues.perfectStock)} />
                                </Progress>
                            <Row className="modalMarg">
                                <Col md="auto">
                                    <div>
                                    {`Stock`} <span className="fontOrange">ok</span>{` : Stock >`}
                                    </div>
                                </Col>  
                                <Col sm={2}>
                                    <Field
                                        name="minStockPercent"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col md="auto">
                                    {`<`}
                                </Col>
                                <Col sm={2}>
                                    <Field
                                        name="perfectStock"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col md="auto">
                                    <div>
                                        % au stock maxi 
                                    </div>
                                </Col>
                                
                            </Row>
                            <Progress multi className="radius modalMarg">
                                    <Progress bar color="gray-600" value= { currentValues ? currentValues.perfectStock : initialValues.perfectStock} />
                                    <Progress bar color="teal" value= {100 - (currentValues ? currentValues.perfectStock : initialValues.perfectStock)} />
                                </Progress>   
                            <Row className="modalMarg">
                                <Col md="auto">
                                    <div>
                                    {`Stock`} <span className="fontTeal">parfait</span>{` : Stock >`}
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <Field
                                        name="perfectStock"
                                        component={WhFieldTxt}
                                    />
                                </Col>
                                <Col md="auto">
                                    <div>
                                        % au stock maxi 
                                    </div>
                                </Col>
                                
                            </Row>
                        </Col>
                    </Row>
                    <LaddaButton
                        className="btn btn-gray btn-padded btn-ladda modalSubmit dashMarg"
                        loading={props.submitting}
                        data-style={EXPAND_LEFT}
                        type={"submit"}
                    >
                        Enregistrer{" "}
                    </LaddaButton>
            </Form>
        </>
    )
}
const formName = "FormStock";

const rxf = reduxForm({ form: formName })(EditPopUp);

export const mapStateToProps = (state) => {
    return {
        currentValues: getFormValues(formName)(state),
    }
};

export default connect(mapStateToProps)(rxf);