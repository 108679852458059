import React from "react";
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";
import { change } from "redux-form";
import ApiConfig from "../../../../../constants/ApiConfig";

const config = [
	[
		"Heading",
		"Bold",
		"Format",
		"FontSize",
		"lineheight",
		"Styles",
		"Source",
		"Templates",
		"Sourcedialog",
		"Showblock",
		"HorizontalRule",
		"JustifyLeft",
		"JustifyCenter",
		"JustifyRight",
		"ShowBlocks",
		"Glyphicons",
	],
];
class Title extends React.Component {
	componentDidMount = () => {
		const { data } = this.props;

		if (data) this.setState({ data });
	};

	onChange = evt => {
		const data = evt.editor.getData();
		this.props.meta.dispatch(
			change(this.props.meta.form, this.props.input.name, data),
		);
	};

	//Source, style, blocks
	returnContent = () => {
		const { onEdit, data, input } = this.props;
		CKEditor.editorUrl = ApiConfig.ckeditorInstance;
		if (input && input.value) {
			return (
				<CKEditor
					data={input.value}
					type="inline"
					config={{
						autoParagraph: false,
						toolbar: config,
					}}
					onChange={this.onChange}
				/>
			);
		}
		if (data && data.admin && data.admin.content) {
			return (
				<CKEditor
					data={data.admin.content}
					type="inline"
					config={{
						toolbar: config,
					}}
					onChange={this.onChange}
				/>
			);
		}

		return (
			<div onClick={() => onEdit("title")}>
				<div className="row row-title" />
			</div>
		);
	};

	render = () => {
		return <div className="tpl-title">{this.returnContent()}</div>;
	};
}

Title.defaultProps = {
	onEdit: () => {},
};
Title.propTypes = {
	onEdit: PropTypes.func.isRequired,
};

export default Title;
