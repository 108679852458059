import React, { Component } from "react";
import { Row, Col, Card, CardHeader } from "reactstrap";
import Download from "./download";
import Commands from "./commands";
import {connect} from 'react-redux'
import {getUserRoles} from '../Authentication/redux'

class DashboardCostumer extends Component {
	render() {
		return (
			<div className="animated fadeIn">
				<Card>
					<CardHeader>
						<h1>Tableau de bord</h1>
					</CardHeader>
				</Card>
				{!this.props.userRoles.includes("ROLE_ADMIN") &&
				<Row>
{/* 					<Col lg={4} md={6} sm={12}>
						<Card>
							<CardHeader>Documents</CardHeader>
							<Download/>
						</Card>
					</Col> */}
					<Col lg={12} md={12} sm={12}>
						<Commands/>
					</Col>
				</Row>
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	userRoles: getUserRoles(state),
});

export default connect(
	mapStateToProps,
	null,
	null,
)(DashboardCostumer);
