import { useState } from "react";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import {
  createOrganisation,
  deleteOrganisation,
  fetchOrganisations,
  updateOrganisation,
} from "../../services/crm";


const useOrganisationService = ({ selectedData, toggle, isSaler, userId, salerFilter, selectedSaler, selectedClientValue }) => {
  const [pagination, setPagination] = useState();
  const [data, setData] = useState([]);
  const loadData = async (pageNumber, limit = 50) => {
    const query = {
      $skip: (pageNumber - 1) * limit,
      $limit: limit,
    };

    if (salerFilter) {
      query.salerId = userId;
    }

    if (selectedSaler) {
      query.salerId = selectedSaler;
    }

    if(selectedClientValue){
      query.id = selectedClientValue.value
    }

    try {
      const { data, ...paginationParams } = await fetchOrganisations(query);

      setData(data);
      setPagination(paginationParams);
    } catch (error) {
      toast.error("Erreur dans le chargement des données");
    }
  };

  const onDelete = async (user) => {
    try {
      const confirmed = window.confirm(
        `Êtes vous sûr de vouloir supprimer le client ${user.name} ?`
      );

      if (confirmed) {
        await deleteOrganisation(user.id);                

        loadData(1);
        toast.success("Le client a été supprimé avec succès");
      }
    } catch (error) {
      toast.error("Erreur");
    }
  };

  const onSubmit = async (newData) => {
    const payload = {
      ...newData,
      salerId: newData.contactComId ? newData.contactComId.value : null,
      contactComId: newData.contactComId ? newData.contactComId.value : null,
    };
    
    try {
      if (selectedData) {
        await updateOrganisation(selectedData.id, payload);
 
      }else{
        await createOrganisation(payload);
      }
      loadData(1);
      toggle();
      toast.success("Enregistrement réussi");
    } catch (error) { 
      switch(error.response.status){
        case 400 :
          toast.error("Le nom doit être unique");
          throw new SubmissionError({name:"Ce nom existe déjà"});
          break;
        case 500 : 
          toast.error("Nom déjà défini dans un bucket")
          throw new SubmissionError({name:"Ce nom existe déjà dans l'historique des buckets"});
          break;
        default:
          toast.error("Une erreur est survenue")
      }
      throw new SubmissionError(error);
    }
  };

  return {
    pagination,
    data,
    loadData,
    onDelete,
    onSubmit,
    userId
  };
};

export default useOrganisationService;
