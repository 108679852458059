import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import * as commandService from "../../services/commandItem";

class CommandsDetailsInProgress extends Component {
	state = {
		loaded: false,
		data: [],
	};

	columns = () => {
		return [
			{
				Header: "id",
				accessor: "id",
			},
			{
				Header: "Id",
				accessor: "id",
			},
		];
	};

	load = async state => {
		this.setState({
			loaded: false,
		});

		try {
			const data = await commandService.search(state);

			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
					});
				},
			);
		} finally {
			this.setState({ loaded: true });
		}
	};

	render() {
		const { data, loaded, pages } = this.state;
		return (
			<WHTable
				data={data}
				pages={pages}
				columns={this.columns()}
				filterable={false}
				onFetchData={this.load}
				loading={!loaded}
			/>
		);
	}
}

export default CommandsDetailsInProgress;
