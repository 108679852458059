import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { fetchAccessory, fetchCustomQuery, fetchProducts } from "../../services/pim";

const FormatSelection = (props) => {
    const [data, setData] = useState([]);
    const ref = props.type;
    const [accessory, setAccessory] = useState();
    const [article, setArticle] = useState();

    const loadFormat = async () => {
        const payload = {
            query: `SELECT genericReference, dimensions, accessoryId FROM products  WHERE genericReference LIKE '${ref}%' GROUP BY genericReference, dimensions, accessoryId`
          };
        
        const res = await fetchCustomQuery(payload)
        setData(res)
    }

    useEffect(() => {
        loadFormat()
      },[]);

    const handleClick = async (item) => {
        if(item.accessoryId === null){
            props.handleClickFormat({...item, isAccessory:false})
        }else{
            const id = item.accessoryId
            const query={
                id: id
            }
            const res = await fetchAccessory(query)
            setArticle(item)
            setAccessory(res.data[0]);
        }
    };

    const render = data.map((item) => {
        return(<>
            <button className="iconSizeTunnel" onClick={() => handleClick(item)}>{`${item.dimensions.width} x ${item.dimensions.height}`}</button>
        </>)
    })

    return(
        <>
            {render}
            {accessory ?
                <>
                    <div>Cet article possède un accessoire ({accessory.label} à {accessory.price} € par article)</div>
                    <Button color="green" className="buttonCommand" onClick={() => props.handleClickFormat({...article,isAccessory:true, accessory})}>Ajouter accesoire au panier </Button>
                    <Button className="buttonCommand" onClick={() => props.handleClickFormat({...article, isAccessory:false, accessory})}>Continuer sans accessoire </Button>
                </>
                :null
            }
        </>
    )
}

export default FormatSelection;