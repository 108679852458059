import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { fetchOrganisations } from "../../services/crm";
import { fetchUsers } from "../../services/iam";

const ContactInformation = (user) => {
    const [saler, setSaler] = useState();
    const orgaId = user.user.organisationId;
    const [isUser, setIsUser] = useState(false);

    const fetchData = async () => {
        const query = {id:orgaId};
        const result = await fetchOrganisations(query);
        const data = result.data
        const salerId = data[0].contactComId;
        const queryUser = { id:salerId };
        const res = await fetchUsers(queryUser);
        const saler = res.data[0]
        setSaler(saler)
    }

    useEffect(() => {
        
        if(user.user.roles[0] === "ROLE_USER"){
            setIsUser(true)
        }
        fetchData();
      },[]);

    return(
        <>
            {isUser ?
                <Card className="form-horizontal">
                    <CardHeader>
                        <h1>Mes contacts</h1>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <h2 className="contactTitle">Mon contact administatif</h2>
                            
                                <div className="divContact">
                                    compta@myditex.com 
                                </div>

                            </Col>
                            <Col>
                                <h2 className="contactTitle">Mon contact commercial</h2>
                                <div className="divContact">
                                    { saler ?`${saler.lastname}  ${saler.firstname}` : null}
                                </div>
                                <div className="divContact">
                                    { saler ?  saler.phone : "Pas de contact"}
                                </div>
                                <div className="divContact">
                                    { saler ? saler.email : null}
                                </div>
                            </Col>
                            <Col>
                                <h2 className="contactTitle">Mon contact expédition</h2>
                                <div className="divContact">
                                    logistique@myditex.com
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            :null}
        </>
    )
}

export default ContactInformation;
