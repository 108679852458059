import React, { useEffect, useState } from "react"
import MaintenanceForm from "./maintenanceForm"
import { createMaintenance, fetchMaintenance } from "../../services/crm";
import { toast } from "react-toastify";
import { Card } from "reactstrap"
const Maintenance = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData()
    },[])

    const loadData = async () => {
        setLoading(true);
        const newData = await fetchMaintenance()
        setData(newData)
        setLoading(false);
    }

    const handleSubmit = async (props) => {
        if(props.enabled === undefined){
            
        }else{
            const query = {
                enabled: props.enabled
            }
            try{
                await createMaintenance(query)
                toast.success("Mise à jour éffectuée")
            }catch(error){
                toast.error("Une erreur est survenue")
            }
        }

    }

    return(
        <>
            <h1>Maintenance</h1>
            <Card className="bannerPageCard">
                {!loading ?
                    <MaintenanceForm onSubmit={handleSubmit} initialValues={
                        data
                        ? {
                                enabled:data.enabled
                            }
                        : {
                                enabled:false
                            }
                    }/>
                    :
                        null
                    }
            </Card>
        </>
    ) 
}

export default Maintenance