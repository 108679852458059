const roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_SALER: "ROLE_SALER",
  ROLE_USERB2C: "ROLE_USERB2C",
  ROLE_USERB2B2C: "ROLE_USERB2B2C",
  ROLE_USERB2B: "ROLE_USERB2B"
};

export default roles;
