import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { WhFieldTxt, WhFieldSelect, WhFieldSelect2 ,WhFieldFile} from "../../../components/Form/whFields";
import {
	ModalBody,
	ModalFooter,
	Card,
	CardHeader,
	CardBody,
	Col,
	Form,
	Alert,
	FormGroup,
	InputGroup,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Loading from "../../../components/Loading";
import { connect } from "react-redux";
import * as mediaService from "../../../services/media";

class FormTracking extends React.Component {
	state = {
		options: [],
	};

	render() {
		const { error, handleSubmit, submitting } = this.props;
		const {options} = this.state;
		return (
			<Form onSubmit={handleSubmit} className="form-inline">
					{submitting && <Loading />}

				<Field
					label="Nméro de suivi :"
					name="trackingNumber"
					component={WhFieldTxt}
					type="text"
					labelSize="5"
					inputSize="7"
					labelStyle={{"font-weight":'bold'}}
				/>


				{error && (
					<Alert color="danger">
						<div dangerouslySetInnerHTML={{ __html: error }} />
					</Alert>
				)}

				<LaddaButton
					className="btn btn-gray btn-padded btn-ladda flot-right"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type={"submit"}
				>
					Enregistrer{" "}
				</LaddaButton>
			</Form>
		);
	}
}

FormTracking = reduxForm({
	form: "FormTracking",
})(FormTracking);


export default FormTracking;
