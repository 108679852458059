import React, { useState } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown,
} from "reactstrap";
import * as sessionService from "../../../utils/session/index";
import { connect } from "react-redux";
import { getUser } from "../../Authentication/redux";
import { useNavigate } from "react-router-dom";

const HeaderDropdownAccount = (props) => {

	const [dropdownOpen, setDropDownOpen] = useState(false);
	const navigate = useNavigate();

	const toggle = () => {
		setDropDownOpen(!dropdownOpen);
	}

	const logout = () => {
		sessionService.logout().then((res) => {
			navigate("/login");
		});
	}

	/* componentDidMount() {
		// const decoded = jwtDecode()
	} */


	const { user } = props;

	return (
		<Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle nav>
				<span className="userName">{`${user.firstname} ${user.lastname}`}</span>
				<i className="fa fa-user" />
			</DropdownToggle>

			<DropdownMenu right>
				<DropdownItem header tag="div" className="text-center">
					<strong>Mon compte</strong>
				</DropdownItem>

				<DropdownItem
					onClick={() =>
						navigate("/account/informations")
					}
				>
					<i className="fa fa-user" />
					<span>Mes infos</span>
				</DropdownItem>
				<DropdownItem 
					onClick={() =>
						navigate("/account/password")
					}
				>
					<i className="fa fa-lock" />
					<span>Changer de mot de passe</span>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps)(HeaderDropdownAccount);
