import APICONFIG from "../constants/ApiConfig";
import fetch from "../utils/fetch";

const endPoint = APICONFIG.CRM;

const endPoints = {
  subCommands: `${endPoint}/sub-commands`,
  organisations: `${endPoint}/organisations`,
  textilCmds: `${endPoint}/textils-cmds`,
  contacts: `${endPoint}/contacts`,
  commands: `${endPoint}/commands`,
  medias: `${endPoint}/medias`,
  gabarits: `${endPoint}/gabarits`,
  consumption: `${endPoint}/cmd-consumptions`,
  metrage: `${endPoint}/metrage`,
  downloadSubCmdMedia: `${endPoint}/download-sub-cmd-media`,
  downloadDropBoxMedia: `${endPoint}/download-dropbox-media`,
  downloadDropBoxGabarit: `${endPoint}/download-dropbox-gabarit`,
  maintenance: `${endPoint}/maintenance`,
  banner: `${endPoint}/information-banner`,
  shippingDelay: `${endPoint}/shipping-delay`,
  orderErp: `${endPoint}/order-erp`,
  uploadLargeFile: `${endPoint}/upload-large-file`,
  jobs: `${endPoint}/jobs`,
  makers: `${endPoint}/makers`,
  uploadMediaLink: `${endPoint}/upload-media-link`,
  importDB: `${endPoint}/download-dropbox-folder`,
  importClient: `${endPoint}/import-organisation`,
  synchroClient: `${endPoint}/synchro-penny-client`
};

//Sub Commands
export const fetchSubCommands = (query) =>
  fetch(endPoints.subCommands, "get", {}, query);
export const updateSubCommands = (id, payload) => fetch(`${endPoints.subCommands}/${id}`, "patch", payload);


export const fetchTextilCmds = (query) =>
fetch(endPoints.textilCmds, "get", {}, query);

export const fetchCommands = (query) =>
fetch(endPoints.commands, "get", {}, query);
export const updateCommands = (id, payload) => fetch(`${endPoints.commands}/${id}`, "patch", payload);



//Organisations
export const fetchOrganisations = (query) => fetch(endPoints.organisations, "get", {}, query);

export const createOrganisation = (payload) => fetch(endPoints.organisations, "post", payload);

export const updateOrganisation = (id, payload) => fetch(`${endPoints.organisations}/${id}`, "patch", payload);

export const deleteOrganisation = (id) => fetch(`${endPoints.organisations}/${id}`, "delete");


//Contacts
export const fetchContact = (query) => fetch(endPoints.contacts, "get", {}, query);

export const createContact = (payload) => fetch(endPoints.contacts, "post", payload);

export const updateContact = (id, payload) => fetch(`${endPoints.contacts}/${id}`, "patch", payload);

export const deleteContact = (id) => fetch(`${endPoints.contacts}/${id}`, "delete");

//Medias

//fetchBase64 media subCommand bucket url 
export const fetchBase64 = (id) => fetch(`${endPoints.downloadSubCmdMedia}/${id}`, "get", {},)

//fetchBase64 media subCommand bucket url 
export const fetchBase64Media = (id) => fetch(`${endPoints.downloadDropBoxMedia}/${id}`, "get", {},)

export const fetchMedias = (query) => fetch(endPoints.medias, "get", {}, query)
export const createMedias = (payload) => fetch(endPoints.medias, "post", payload);
export const uploadLargeFile = (payload) => fetch(endPoints.uploadLargeFile, "post", payload);
export const updateMedias = (id, payload) => fetch(`${endPoints.medias}/${id}`, "patch", payload);
export const deleteMedias = (id) => fetch(`${endPoints.medias}/${id}`, "delete");
export const uploadMediaLink = (payload) => fetch(endPoints.uploadMediaLink, "post", payload);

//Gabarits

//fetchBase64 gabarits
export const fetchBase64Gabarit = (id) => fetch(`${endPoints.downloadDropBoxGabarit}/${id}`, "get", {},)

export const fetchGabarits = (query) => fetch(endPoints.gabarits, "get", {}, query)
export const createGabarits = (payload) => fetch(endPoints.gabarits, "post", payload);
export const updateGabarits = (id, payload) => fetch(`${endPoints.gabarits}/${id}`, "patch", payload);
export const deleteGabarits = (id) => fetch(`${endPoints.gabarits}/${id}`, "delete");


//Consumptions
export const fetchConsumption = (query) => fetch(endPoints.consumption, "get", {}, query);

//Metrage
export const fetchMetrage = (query) => fetch(endPoints.metrage, "get", {}, query);


//Maintenance 
export const fetchMaintenance = (query) => fetch(endPoints.maintenance, "get", {}, query);
export const createMaintenance = (payload) => fetch(endPoints.maintenance, "post", payload);

//Banner 
export const fetchBanner = (query) => fetch(endPoints.banner, "get", {}, query);
export const createBanner = (payload) => fetch(endPoints.banner, "post", payload);

//Shipping delay
export const fetchShippingDelay = (query) => fetch(endPoints.shippingDelay, "get", {}, query);
export const createShippingDelay = (payload) => fetch(endPoints.shippingDelay, "post", payload);

//order erp
export const createOrder = (payload) => fetch(endPoints.orderErp, "post", payload);

//Jobs 
export const fetchJobs = (query) => fetch(endPoints.jobs, "get", {}, query);
export const updateJobs = (id, payload) => fetch(`${endPoints.jobs}/${id}`, "patch", payload);

//Confectionneurs
export const fetchMakers = (query) => fetch(endPoints.makers, "get", {}, query);

//Import Dropbox
export const createImport = (payload) => fetch(endPoints.importDB, "post", payload);

//Import clients
export const createClientImport = (payload) => fetch(endPoints.importClient, "post", payload);

//Synchro pennylane
export const createClientSynchro = (payload) => fetch(endPoints.synchroClient, "post", payload);