import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "../../Commands/views/FormItem";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import * as commandService from "../../../services/commandItem";

class EditUserPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price: false,
			open: false,
			data: {},
			loaded: true,
			create: true,
		};
	}

	handleOpen = (price = null) => {
		this.setState({
			price: price,
			create: true,
			open: true,
		});
	}

	handleEdit = (id) => {
		this.setState({ create: false, open: true });

		if (id) {
			this.setState({ loaded: false });

			commandService.view(id).then(data => {
				this.setState({
					price: data.price,
					product: data.product,
					data: data,
					loaded: true,
				});
			});
		}
	}

	handleClose = () => {
		this.setState({ open: false, data: {} });
	}

	formatAddressError = (error) => {
		let updatedError = {...error}
		const violations = [...error.violations];
		const newKey = ["street", "city", "zipCode", "country"]
		const delAddr = "deliveryAddress";
		const billAddr = "billAddress";
		for (let key in error){
			if (key === delAddr || key === billAddr) {
				newKey.map((k, idx) => {
					const newProperty = idx === 0 ? {[key]:{[k]:error[key]}} :
						{[key]:
								{[k]:error[key], ...updatedError[key]}}
					updatedError = {
						...updatedError,
						...newProperty
					}
				})
			}
		}
		violations.map((el) => {
				const prop = el.propertyPath.split('.');
				const newProperty = prop.length > 1 ? ({
					[prop[0]]: {
						...updatedError[prop[0]],
						[prop[1]]: el.message
					}
				}) : {}
				updatedError = {
					...updatedError,
					...newProperty
				}
			});
		return updatedError
	}

	onSubmit = (data) => {
		if (data.isSameAddress && data.billAddress) delete data.billAddress;
		data.price = this.state.price;

		let edit = data.id ? commandService.update : commandService.create;
		return edit(data)
			.then(data => {
				toast.success("Produit ajouté à votre panier !");

				this.handleClose();

				if (this.props.newData)
					this.props.newData(data);
			})
			.catch(error => {
				let updatedError = this.formatAddressError(error, data, "deliveryAddress");
				throw new SubmissionError(updatedError);
			});
	}

	render() {
		const { className } = this.props;
		const { loaded } = this.state;
		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					{this.state.create ? "Commander" : "Modifier la commande"}
				</ModalHeader>
				{!loaded && <Loading />}
				{loaded && (
					<FormPopup
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

export default EditUserPopup;
