import { toast } from "react-toastify";
import { useState } from "react";
import {
    fetchFabrics,
    updateFabrics,
} from "../../services/pim"

const useStockService = ({selectedData, toggle, loadStock}) => {
  
    const onSubmit = async (newData) => {
        let payload
        if(newData.addStock && !newData.unStock){
            const newStock = parseInt(newData.stock) + parseInt(newData.addStock);
            payload = {
                stock: newStock,
                stockAlert: newData.stockAlert,
            }
        } else if(newData.unStock && !newData.addStock){
            const newStock = (parseInt(newData.stock) - parseInt(newData.unStock));
            payload = {
                stock: newStock,
                stockAlert: newData.stockAlert,
            }
        } else if(newData.addStock && newData.unStock){
            const newStock = (parseInt(newData.stock) + parseInt(newData.addStock)) - parseInt(newData.unStock);
            payload = {
                stock: newStock,
                stockAlert: newData.stockAlert,
            }
        }else{
            payload = {
                ...newData
            }
        }

        const stockAlert =Math.round(((parseInt(newData.minStockPercent) / 100 ) * parseInt(newData.stockMaxi))* 10) / 10;
        
        try {
            await updateFabrics(newData.id, {...payload, stockAlert})
            toast.success("Enregistrement réussi");
            toggle()
            loadStock()
        }catch (error) {
            toast.error("Erreur");
        }
    }
    return {
        onSubmit,
    }
}

export default useStockService;