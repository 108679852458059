import React from "react";
import ClientImport from "./EtapesImports/clientImport";
import TissuImport from "./EtapesImports/tissuImport";
import ProductImport from "./EtapesImports/productImport";
import PriceImport from "./EtapesImports/priceImport";
import AccessoryImport from "./EtapesImports/accessoryImport";
import BrandImport from "./EtapesImports/brandImport";
import BrandProductImport from "./EtapesImports/brandProductImport";
import { Button } from "reactstrap";
import { createClientSynchro } from "../../services/crm";
import { createBrandprodSynchro, createProductSynchro } from "../../services/pim";

const dataImportGestion = () => {

    const handleSynchroClients = async () => {
        const payload = {
            start:true
        }
        try{
            const resp = await createClientSynchro(payload)
        }catch(error){
            console.log(error)
        }
    }

    const handleSynchroProducts = async () => {
        const payload = {
            start:true
        }

        try{
            const resp = await createProductSynchro(payload)
        }catch(error){
            console.log(error)
        }
        
    }

    const handleSynchroBrandProducts = async () => {
        const payload = {
            start:true
        }
        try{
            const resp = await createBrandprodSynchro(payload)
        }catch(error){
            console.log(error)
        }
        
    }

    return(
        <>
            <h1>Gestion des imports de données</h1>

            <div className="importInfoDiv">
                Sur cette page vous trouverez l'ensemble des outils nécessaires à l'ajout d'un grande quantité de données sur l'erp.<br/>
                Plusieurs facteurs sont à prendre en compte avant de lancer des imports : 
            </div>
            <ul className="listInfoImport">
                <li className="listInfoImport">
                    Il est important d'utiliser les tableaux présents dans le drive suivant : 
                    <a href="https://drive.google.com/drive/folders/0AHU_q_EBo0WGUk9PVA" target="_blank"> Drive ERP</a>
                </li>
                <li className="listInfoImport">
                    Pour soumettre un tableau il faut : le remplir sur le drive -> 
                    le télécharger sur son ordinateur un fois le fichier rempli -> 
                    le selectionner grâce à la zone de drop de l'étape voulu 
                </li>
                <li className="listInfoImport">
                    Il faut garder à l'esprit qu'importer une donnée deux fois la double dans la base de données, <br/>
                    Pour remedier à cela je vous conseille d'utiliser les tableaux "import tampon" pour faire votre import afin de compléter la base <br/>
                    et ensuite déplacer les données dans le tableau général qui regroupe tout le référentiel 
                </li>
                <li className="listInfoImport">
                    L'ordre d'importation est important CLIENTS -> TISSUS -> ACCESSOIRES -> PRODUITS -> PRIX <br/>
                    Exemple : si je veux importer un prix sur un produit en 3008, j'ai besoin que le client et le produit existent et pour cela j'ai besoin que le tissu existe 
                </li>
                <li className="listInfoImport">
                    L'import se réalise par script cela implique que les données doivent être EXACTEMENT pareil que dans la base de données <br/>
                    Exemple : je veux importer un prix pour DECO-TISSU je dois saisir DECO-TISSU avec attention (décotissu est different de DECO-TISSU)
                </li>
            </ul>
            <div className="importInfoDiv">
                N'hésitez pas à vous appuyer sur le tableau général pour remplir votre tableau tampon et avoir les bonnes pratiques <br/>
                Il faut surtout garder à l'esprit que c'est un script est qu'il agit bêtement par correspondance
            </div>
            <div className="importInfoDiv">
                Une fois un import de produits / d'un client ou d'un produit marque il est important de re synchroniser la base de données <br/>
                avec celle de PennyLane grâce à ces trois boutons :
            </div>
            <div className="importInfoDiv">
               <Button onClick={handleSynchroClients} color={"teal"}>Synchro clients</Button>
               <Button onClick={handleSynchroProducts} color={"teal"}>Synchro produits</Button>
               <Button onClick={handleSynchroBrandProducts} color={"teal"}>Synchro produits marques</Button>
            </div>
          

            {/* /!\ Faire collapse sur chaque étape /!\ */}
            <h2 className="importTitle">Tunnel de commandes</h2>
            <ClientImport/>
            <TissuImport/>
            <AccessoryImport/>
            <ProductImport/>
            <PriceImport/>
            <h2 className="importTitle">Catalogue de marques</h2>
            <BrandImport/>
            <BrandProductImport/>
            {/* <h2>Etape 6 : Import des produits marques</h2> */}
        </>
    )
}

export default dataImportGestion