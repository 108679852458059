import React, { Component } from "react";
import {
	Nav,
	NavItem,
	Card,
	CardHeader,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import classnames from "classnames";
import CommandsInProgress from "./commandsInProgress";
import CommandsDetailsInProgress from "./commandsDetailsInProgress";
import {connect} from 'react-redux'
import {getCommandStatus} from '../../reducers/params'

class Commands extends Component {
	state = {
		loaded: false,
		data: [],
		activeTab: "1",
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	render() {
		const { commandStatus } = this.props;
		return (
			<div>
				{/* <Card>
					<CardHeader>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({
										active: this.state.activeTab === "1",
									})}
									onClick={() => {
										this.toggle("1");
									}}
								>
									Commandes en cours
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({
										active: this.state.activeTab === "2",
									})}
									onClick={() => {
										this.toggle("2");
									}}
								>
									Details en cours
								</NavLink>
							</NavItem>
						</Nav>
					</CardHeader> 
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1" style={{ padding: 0 }}>*/}
							<CommandsInProgress commandStatus={commandStatus}/>
						{/* </TabPane>
						<TabPane tabId="2" style={{ padding: 0 }}>
							<CommandsDetailsInProgress />
						</TabPane>
					</TabContent>
				</Card> */}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	commandStatus: getCommandStatus(state),
});

export default connect(
	mapStateToProps,
	null,
	null,
)(Commands);
