import React, { useEffect, useState } from "react"
import BannerForm from "./bannerForm"
import { createBanner, createImport, fetchBanner } from "../../services/crm";
import { toast } from "react-toastify";
import { Card, Row, Input, Button } from "reactstrap"
import { fetchOrganisations } from "../../services/crm";
import Select from "react-select";
import Loading from "../../components/Loading";

const FastImport = () => {
    const [selectedClientValue, setSelectedClientValue] = useState(null);
    const [inputClientValue, setInputClientValue] = useState('')
    const [inputDbValue, setInputDbValue] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const handleChangeDbInput = (props) => {
        setInputDbValue(props.target.value)
    };

    const handleInputClientChange = (value) => {
        setInputClientValue(value)
    }

    const handleClientChange = (value) => {
        setSelectedClientValue(value)
    }

    const loadClientOptions = (input) => {
        return fetchOrganisations({
          $or: [
            { name: { $like: `%${input}%` } },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          }));
    
          return { options };
        });
    }

    const handleSubmit = async () => {
        if(!selectedClientValue || inputDbValue === undefined){
            toast.error("Vérifier que les champs sont bien remplis")
        }else{
            setIsLoading(true)
            try {
                const payload = {
                    organisationId: selectedClientValue.value,
                    folderPath: inputDbValue
                };
                const createdMedias = await createImport(payload);
                // Remettre isLoading à false une fois la requête terminée
                setIsLoading(false);
                toast.success("Fin de l'import")
            } catch (error) {
                // Gérer les erreurs
                console.error("Une erreur s'est produite lors de la création de l'import :", error);
                // Assurez-vous de remettre isLoading à false même en cas d'erreur
                setIsLoading(false);
            }
            
        }
    }

    return(
        <>
            <h1>Importer un dossier DropBox</h1>
            <div className="importDbInfo">
                Afin d'importer et associer des médias dropbox à un client, veuillez séléctionner un client et entrer un lien de partage de dossier dropbox.
            </div>
            {isLoading === false ? 
                <div className="mainCardImportDB">
                    <Row>
                        <div>
                            <Select.Async
                                placeholder="Nom du client"
                                noResultsText="Pas de résultat"
                                className="searchSaler"
                                onInputChange={handleInputClientChange}
                                onChange={handleClientChange}
                                value={selectedClientValue}
                                loadOptions={loadClientOptions}
                            />
                        </div>
                        <div>
                        <Input 
                        className="inputMediaDB"
                        placeholder='Lien de partage dossier dropbox'
                        value={inputDbValue}
                        onChange={(event) => handleChangeDbInput(event)}
                        >
                        </Input>
                        </div>
                        <Button onClick={handleSubmit} color="dark">Enregistrer</Button>
                    </Row>
                </div>
            
            :
                <div className="containerColCenter">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
        </>
    ) 
}

export default FastImport