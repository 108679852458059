import { fetch } from "../../utils/fetch.old";
import QueryBuilder from "../../components/WHTable/queryBuilder";

const endPoints = "/api/products";

export const search = (data) => {
	let query = QueryBuilder(data);

	query.type = 100;

	return fetch(endPoints, "get", query);
};

export const view = (id) => fetch(`${endPoints}/${id}`, "get");

export const create = (data) => fetch(`${endPoints}`, "post", data);

export const update = (data) => fetch(`${endPoints}/${data.id}`, "put", data);

export const remove = (id) => fetch(`${endPoints}/${id}`, "delete", {});

export const fabric = (data) => {
	let query = QueryBuilder(data);

	return fetch("/api/fabrics", "get", query);
};
