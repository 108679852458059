import React, { useState } from "react";
import { Row, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

const ProductSelection = (props) => {
    let navigate = useNavigate();

    const handleClickBasket = () => {
       navigate('/account/basket')
    }
    const handleClickContinue = () => {
        window.location.reload()
    }
    
    return(
        <>  
            <div className="searchSaler">Vos articles ont bien été ajoutés au panier</div>
            <Row>
                <Button color="green" className="buttonCommand" onClick={() => handleClickContinue()}>Poursuivre mes achats</Button>
                <Button className="buttonCommand" onClick={() => handleClickBasket()}>Aller au panier</Button>
            </Row>
        </>
    )
}
export default ProductSelection;