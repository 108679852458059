import React, { useMemo, useState } from "react";
import { Button } from "reactstrap";
import Table from "../../Table";

// type: "manufacture" | "fabric" | undefined

const ProductList = ({
  onEdit,
  onDelete,
  pagination,
  loadData,
  data,
  type,
}) => {
  const columns = useMemo(() => {
    const fabricColumns =
      type === "fabric"
        ? [
            {
              id: "ref",
              Header: "Référence Tissu",
              Cell: ({
                row: {
                  original: { fabric },
                },
              }) => {
                return fabric.ref;
              },
            },
            {
              id: "name",
              Header: "Nom",
              Cell: ({
                row: {
                  original: { fabric },
                },
              }) => {
                return fabric.name;
              },
            },
            {
              id: "stock",
              Header: "Stock",
              Cell: ({
                row: {
                  original: { fabric },
                },
              }) => {
                return fabric.stock;
              },
            },
            {
              id: "stockMaxi",
              Header: "Stock maximum",
              Cell: ({
                row: {
                  original: { fabric },
                },
              }) => {
                return fabric.stockMaxi;
              },
            },
          ]
        : [];

    const manufactureColumns =
      type === "manufacture"
        ? [
            {
              id: "label",
              accessor: "label",
              Header: "Nom",
            },
            {
              id: "dimension",
              Header: "Dimension",
              Cell: ({ row: { original: currentData } }) => {
                return currentData.manufacture
                  ? currentData.manufacture.dimension
                  : "";
              },
            },
          ]
        : [];

    return [
      {
        id: "reference",
        accessor: "reference",
        Header: "Référence Produit",
      },

      {
        id: "description",
        accessor: "description",
        Header: "Description",
      },
      ...manufactureColumns,
      ...fabricColumns,
      {
        id: "action",
        accessor: "action",
        Header: "Actions",
        Cell: (row) => {
          const currentData = row.row.original;
          return (
            <div className={"text-center"}>
              <Button color={"transparent"} onClick={() => onEdit(currentData)}>
                <i className={"fa fa-edit"} />
              </Button>
              <Button
                color={"transparent"}
                onClick={() => onDelete(currentData)}
              >
                <i className={"far fa-trash-alt"} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        loadData={loadData}
        pagination={pagination}
        paginated
      />
    </div>
  );
};

export default ProductList;
