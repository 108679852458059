import React from 'react';
import { Row, Col, Container } from "reactstrap"

const MaintenanceScreen = () => {
    return(
        <>
            <div className="app flex-row align-items-center loginContainer">
            <Container>
                <Row className="justify-content-center">
                <Col xs="12" className="justify-content-center"></Col>
                    <div className={"loginHeader"}>
                    <img src="img/logo.svg" alt="" style={{ maxWidth: "100%" }} />
                        <h1>Le site est en maintenance </h1>
                    </div>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default MaintenanceScreen