import { set } from 'lodash';
import React, {useEffect, useState} from 'react'
import Dropzone, {useDropzone} from 'react-dropzone'
import { Button, Label, Input, Row } from 'reactstrap';
import { fetchMedias } from '../../services/crm';
import { toast } from 'react-toastify';
import useModal from '../../hooks/useModal';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import Select from "react-select";


const DragDropFiles = (props) => {
    const isTissu = props.isTissu
    const maxSize = 314572800 
    const orgaId = props.user.organisationId;
    const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone({maxSize: maxSize});
    const [data, setData] = useState();
    const [files, setFiles] = useState();
    const { toggle, active } = useModal();
    const [medias, setMedias] = useState();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState();
    const [maxPage, setMaxPage] = useState(0)
    const [selectedMediaValue, setSelectedMediaValue] = useState(null);
    const [inputMediaValue, setInputMediaValue] = useState('')
    const [toggleIndex, setToggleIndex] = useState(0);

    const handleClick = () => {
        props.handleClickFiles([data, medias])
    }

    useEffect(() => {
        if(selectedMediaValue){
            loadMedia(1, selectedMediaValue.label)
        }else{
            loadMedia(page)
        }
        loadFiles()
        checkSize()
    },[acceptedFiles, page, selectedMediaValue])



    useEffect(() => {
        onError()
    },[fileRejections])

    const checkSize = () => {
        if(acceptedFiles.length > 0){
            if(acceptedFiles[0].size > 262144000 ){
                toast.warn("Fichier de plus de 250Mo attention le max est de 300Mo")
            }
        }
    }

    const onError = () => {
        if(fileRejections.length > 0){
            if(fileRejections[0].errors[0].code === "file-too-large")
            toast.error("Le fichier est trop volumineux (Max 300Mo) pour les plus gros fichier merci de passer par la page Mes médias")
        }
    }

    

    const loadMedia = async (pageNumber,  mediaName, limit = 50) => {
        try{
            setPage(pageNumber);
            let query
            if(mediaName){
                query = {$skip: (pageNumber - 1) * limit, $limit: limit, organisationId:orgaId, name:mediaName}
            }else{
                query = {$skip: (pageNumber - 1) * limit, $limit: limit, organisationId:orgaId}
            }
            const { data, ...paginationParams } = await fetchMedias(query)
            setFiles(data)
            setPagination(paginationParams)
            const newMax = Math.ceil(paginationParams.total / paginationParams.limit)
            setMaxPage(newMax)
            
        }catch(error){
            /* toast.error("Erreur dans le chargement des données"); */
        }
    }

    const handleIncreasePage = () => {
        if(page < maxPage){
            setPage(page + 1)
        }
    }

    const handleDecreasePage = () => {
        if(page > 1){
            setPage(page - 1)
        }
    }
    const loadFiles = async  () => {
        if(acceptedFiles.length > 0){
            var reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = function () {
                const base64= reader.result.split(',')[1]
                if(data === undefined){
                    setData([{base64:base64, inputValue:1, path:acceptedFiles[0].path, custom:true}])
                }else{
                    setData([...data, {base64:base64, inputValue:1, path:acceptedFiles[0].path, custom:true}])
                }
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
            
        }
    }

    const handleInputMediaChange = (value) => {
        setInputMediaValue(value)
    }

    const handleMediaChange = (value) => {
        setSelectedMediaValue(value)
    }

    const loadMediaOptions = (input) => {
        const query = {
            name: { $like: `%${input}%` },
            organisationId:orgaId
        }
        try{
            return fetchMedias(query).then((result) => {
              const options = result.data.map((item) => ({
                value: item.id,
                label: `${item.name}`,
              }));
        
              return { options };
            });
        }catch(error){
            console.log(error)
        }
    }

    const handleToggleUpdloadFile = () => {
        setToggleIndex(1)
        toggle()
    }

    const handleToggleMyMedia = () => {
        setToggleIndex(0)
        toggle()
    }

    if(data === undefined && medias === undefined){
        
        return(
            <section>


            {/*  ANCIENNE METHODE ZONE DE DROP */}
            {/* <div>Cliquez sur le bouton si dessous pour déposer votre</div>
            <div className='divShadow' {...getRootProps()}>
                <i class='fas fa-cloud-download-alt fa-xl'></i>
                <input {...getInputProps()} />
            </div> */}

            {/* <div className='divShadow' onClick={handleToggleUpdloadFile}>
                Ajouter un média
            </div> */}
            <div className='divShadow' onClick={handleToggleMyMedia}>
                    Importer un fichier depuis mes médias
            </div>
            {/* <div className='stockIndicator divShadow'>
                <p>Format autorisés : PNG, PDF, PSD</p>
                <p>Poids : 500 MO maximum</p>
            </div> */}
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '700px', width: '100%'}}
                >
                    <ModalHeader toggle={toggle}>
                        {toggleIndex === 0 ? <h1 className="modalTitle">Mes médias</h1> : null}
                        
                    </ModalHeader>
                    <ModalBody>
                        <Row className="filterRow">
                        
                            <Label className="filterLabel">Recherche par nom de média </Label>
                            <Select.Async
                                placeholder="Nom du média"
                                noResultsText="Pas de résultat"
                                className="searchSaler"
                                onInputChange={handleInputMediaChange}
                                onChange={handleMediaChange}
                                value={selectedMediaValue}
                                loadOptions={loadMediaOptions}
                            />  
                        </Row>
                        {files? files.map((file, index) => {
                            const handleClickAdd = () => {
                                setMedias([{...files[index], inputValue:1, path:files[index].name, custom:false, url: files[index].url}]);
                                toast.success("fichier ajouté")
                                toggle()
                            }
                            return(
                                <div className='divShadow2'>
                                    <div key={file.name} className="icon-file-name">{file.name}</div>
                                    <div className='marginAutoLeft'>
                                        <Button className="inputButton" onClick={() => handleClickAdd(index)}>+</Button>
                                    </div>
                                </div>
                            )
                        }):null}
                        <Row className="modalSubmit">
                            <Button onClick={handleDecreasePage} className="inputButton">{'<'}</Button>
                            <div>Page {page} sur {maxPage}</div>
                            <Button onClick={handleIncreasePage} className="inputButton">{'>'}</Button>
                        </Row>
                    </ModalBody>
                </Modal>
        </section>
        )
    }else{
        
        return(
            <section>
                <div> Selectionner la quantité pour chaque fichier uploadé</div>
                {data ? data.map((file, index) => {
                    const handleClickAdd = (index) => {
                        const newData = [...data];
                        newData[index].inputValue = parseFloat(newData[index].inputValue) + 1;
                        setData(newData)
                    }

                    const handleClickRemove = (index) => {
                        const newData = [...data];
                        if(newData[index].inputValue >= 0){
                            newData[index].inputValue = parseFloat(newData[index].inputValue)  - 1;
                            if(newData[index].inputValue < 0){
                                newData[index].inputValue = 0
                            }
                            setData(newData)
                        }
                    }

                    const handleChangeInput = (event) => {
                        const newData = [...data];
                        newData[index].inputValue = event.target.value
                        setData(newData)
                    }

                return(
                    <div className='divShadow2'>
                        <div key={file.path} className="icon">{file.path.length > 30 ? `${file.path.substring(0,30)}...` : file.path}</div>
                        <div className='marginAutoLeft'>
                            <div className='row'>
                                <Button className="inputButton" onClick={() => handleClickRemove(index)}> - </Button>
                                <Input value={file.inputValue} onChange={handleChangeInput} className="inputNumber" type="number" step="0.01"></Input>
                                {isTissu ? <div>ML</div> : <div>Pièce(s)</div>}
                                <Button className="inputButton" onClick={() => handleClickAdd(index)}> + </Button>
                            </div>
                        </div>
                    </div>
                )}): null}

                {medias ? medias.map((file, index) => {
                    const handleClickAdd = (index) => {
                        const newData = [...medias];
                        newData[index].inputValue = parseFloat(newData[index].inputValue) + 1;
                        setMedias(newData)
                    }

                    const handleClickRemove = (index) => {
                        const newData = [...medias];
                        if(newData[index].inputValue >= 0){
                            newData[index].inputValue = parseFloat(newData[index].inputValue)  - 1;
                            if(newData[index].inputValue < 0){
                                newData[index].inputValue = 0
                            }
                            setMedias(newData)
                        }
                    }


                    const handleChangeInput = (event) => {
                        const newData = [...medias];
                        newData[index].inputValue = event.target.value
                        setMedias(newData)
                    }

                return(
                    <div className='divShadow2'>
                        <div key={file.path} className="icon">{file.path.length > 30 ? `${file.path.substring(0,30)}...` : file.path}</div>
                        <div className='marginAutoLeft'>
                            <div className='row'>
                                <Button className="inputButton" onClick={() => handleClickRemove(index)}> - </Button>
                                <Input value={file.inputValue} onChange={handleChangeInput} className="inputNumber" type="number" step="0.01"></Input>
                                {isTissu ? <div>ML</div> : <div>Pièce(s)</div>}
                                <Button className="inputButton" onClick={() => handleClickAdd(index)}> + </Button>
                            </div>
                        </div>
                    </div>
                )}): null}


                {/* ANCIENN METHODE DRAG DROP*/}
                {/* <div className='divShadow' {...getRootProps()}>
                    <i class='fas fa-cloud-download-alt fa-xl'></i>
                    <input {...getInputProps()} />
                </div> */}

                {/* <div className='divShadow' onClick={handleToggleUpdloadFile}>
                    Ajouter un média
                </div> */}
                <div className='divShadow' onClick={handleToggleMyMedia}>
                    Importer un fichier depuis mes médias
                </div>

                <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '700px', width: '100%'}}
                >
                    <ModalHeader toggle={toggle}>
                        <h1 className="modalTitle">Mes médias</h1>
                    </ModalHeader>

                    <ModalBody>
                        <Row className="filterRow">
                            
                            <Label className="filterLabel">Recherche par nom de média </Label>
                            <Select.Async
                                placeholder="Nom du média"
                                noResultsText="Pas de résultat"
                                className="searchSaler"
                                onInputChange={handleInputMediaChange}
                                onChange={handleMediaChange}
                                value={selectedMediaValue}
                                loadOptions={loadMediaOptions}
                            />  
                        </Row>
                        {files? files.map((file, index) => {
                            const handleClickAdd = (index) => {
                                if(medias === undefined){
                                    setMedias([{...files[index], inputValue:1, path:files[index].name, custom:false, url: files[index].url}]);
                                }else{
                                    setMedias([...medias,{...files[index], inputValue:1, path:files[index].name, custom:false, url: files[index].url}]);
                                }
                                toast.success("fichier ajouté")
                                toggle()
                            }
                            return(
                                <div className='divShadow2'>
                                    <div key={file.name} className="icon">{file.name.length > 30 ? `${file.name.substring(0,30)}...` : file.name}</div>
                                    <div className='marginAutoLeft'>
                                        <Button className="inputButton" onClick={() => handleClickAdd(index)}>+</Button>
                                    </div>
                                </div>
                            )
                        }):
                            <div>Aucun média disponible</div>
                        }
                        <Row className="modalSubmit">
                            <Button onClick={handleDecreasePage} className="inputButton">{'<'}</Button>
                            <div>Page {page} sur {maxPage}</div>
                            <Button onClick={handleIncreasePage} className="inputButton">{'>'}</Button>
                        </Row>
                    </ModalBody>
                </Modal>
                <Button color="green" className="buttonCommand" onClick={() => handleClick()}>Suivant</Button>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
export default connect(mapStateToProps)(DragDropFiles);