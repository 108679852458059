import React, { useState, useMemo, useEffect } from "react";
import { fetchCommands } from "../../services/crm";
import Table from "../../components/Table";
import Moment from "react-moment";
import { Button } from "reactstrap";
import { Modal, ModalBody, ModalHeader, Col, Row, Collapse, Label } from "reactstrap"
import useModal from "../../hooks/useModal";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import SubCommandsList from "../SubCommands/SubCommandsList";
import { fetchOrganisations } from "../../services/crm";
import Select from "react-select";
import CommandsForm from "./commands/commandsForm";
import { updateCommands } from "../../services/crm";
import { toast } from "react-toastify"
import { fetchBase64Media } from "../../services/crm";
import moment from 'moment';

const InProgressCommands = ({ userRoles, user }) => {
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);

    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const { toggle, active } = useModal();
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedClientValue, setSelectedClientValue] = useState(null);
    const [inputClientValue, setInputClientValue] = useState('')
    const [index, setIndex] = useState()
    const [contact, setContact] = useState();
    const [selectedStatusValue, setSelectedStatusValue] = useState(null);

    const options = [
        {value: 100, label:"Commande initialisée"},
        {value: 200, label:"Commande validée"},
        {value: 300, label:"Commande confirmée"},
        {value: 310, label:"Commande refusée"},
        {value: 350, label:"Commande en cours d'impression partielle"},
        {value: 400, label:"Commande en cours d'impression"},
        {value: 450, label:"Commande en cours de confection partielle"},
        {value: 500, label:"Commande en cours de confection"},
        {value: 510, label:"Commande en visite partielle"},
        {value: 520, label:"Commande en visite"},
        {value: 530, label:"Commande en défaut partiel"},
        {value: 540, label:"Commande en défaut"},
        {value: 550, label:"Commande prête pour expédition partielle"},
        {value: 600, label:"Commande prête pour expédition"},
        {value: 650, label:"Commande partiellement expédiée"},
        {value: 700, label:"Commande expédiée"},
        {value: 710, label:"Commande partiellement reçue par le client"},
        {value: 720, label:"Commande reçue par le client"},
        {value: 730, label:"Commande à facturer partiellement"},
        {value: 740, label:"Commande à facturer"},
        {value: 750, label:"Commande partiellement facturée"},
        {value: 800, label:"Commande facturée"},
        {value: 810, label:"Commande partiellement en attente de paiement"},
        {value: 820, label:"Commande en attente de paiement"},
        {value: 830, label:"Commande partiellement en retard de paiement"},
        {value: 840, label:"Commande en retard de paiement"},
        {value: 900, label:"Commande clôturée"},
    ]

    useEffect(() => {
        loadData(page)
    },[active, selectedClientValue, selectedStatusValue])
    
    const loadData = async (pageNumber, limit = 50) => {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log("loadData")
        let query 
        if(!pageNumber){
            pageNumber = 1
        }

        setPage(pageNumber);

        if(isAdmin === false){
            query = { $skip: (pageNumber - 1) * limit, $limit: limit, authorId:user.id, currentStatus:{$lt:900}};
            console.log(query)
        }else{
            if(selectedClientValue !== null && selectedStatusValue === null){
                query = { $skip: (pageNumber - 1) * limit, $limit: limit, organisationId: selectedClientValue.value ,currentStatus:{$lt:900}};
            }else if (selectedClientValue === null && selectedStatusValue !== null){
                query = { $skip: (pageNumber - 1) * limit, $limit: limit,currentStatus:selectedStatusValue.value};
            }else if(selectedClientValue === null && selectedStatusValue === null){
                query = { $skip: (pageNumber - 1) * limit, $limit: limit,currentStatus:{$lt:900}};
            }else if(selectedClientValue !== null && selectedStatusValue !== null){
                query = { $skip: (pageNumber - 1) * limit, $limit: limit, organisationId: selectedClientValue.value ,currentStatus:selectedStatusValue.value};
            }
        }
 
        const { data, ...paginationParams } = await fetchCommands(query);
        setData(data)
        setPagination(paginationParams)
    }

    const handleView = (props) => {
        setIndex(0)
        setSelectedData(props)
        toggle();
    }

    const handleCollapse = () => {
        setIsOpen(!isOpen)
    }
    
    const handleInputClientChange = (value) => {
        setInputClientValue(value)
    }

    const handleClientChange = (value) => {
        setSelectedClientValue(value)
    }

    const handleStatusChange = (value) => {
        setSelectedStatusValue(value)
    }

    const loadClientOptions = (input) => {
        return fetchOrganisations({
          $or: [
            { name: { $like: `%${input}%` } },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          }));
    
          return { options };
        });
    }

    const onSubmit = async (props) => {
        const payload = {
            deliverName: props.deliverName ? props.deliverName : null,
            deliverDate: props.deliverDate ? props.deliverDate : null,
            deliverWeight: props.deliverWeight ? props.deliverWeight : null,
            trackingNumber: props.trackingNumber ? props.trackingNumber : null
        }
        try{
            await updateCommands(props.id, payload)
            window.location.reload()
        }catch(error){
            console.log(error)
        }
    }
    const columns = useMemo(() => {
        const result = [
            {
            id: "organisations",
            accessor: "organisations",
            Header: "Client",
                Cell: ({ row }) => {
                    return (
                        <>
                            {row.original.organisation.name ? row.original.organisation.name : null }
                        </>
                    );
            },
            },
            /* {
                id: "project",
                accessor: "project",
                Header: "Projets",
                    Cell: ({ row }) => {
                        const listMedia = row.original.subCommands.map((item, index) => {
                            return(
                                <>
                                    <div>
                                        {item.mediaName}
                                    </div>       
                                </>
                            )
                        })
                        return (
                            <>
                                <div className="test5">
                                    {listMedia}
                                </div>
                            </>
                        );
                },
            }, */
            {
            id: "refClient",
            accessor: "clientRef",
            Header: "Reference client",
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.original.clientRef ? row.original.clientRef : row.original.ref}
                        </div>
                    );
                },
            },
            {
            id: "status",
            accessor: "currentStatusName",
            Header: "Statut",
                Cell: ({ row }) => {
                    return (
                    <span className={`label cmdStatus-${row.original.currentStatus}`}>
                        {row.original.currentStatusName}
                    </span>
                    );
                },
            },
            {
            id: "supports",
            accessor: "",
            Header: "Supports",
                Cell: ({ row }) => {
                    const listSupport = row.original.subCommands.map((item, index) => {
                        const filteredName = item.support.replace(/_/g, '');
                        return(
                            <>
                                <div>
                                    {filteredName}
                                </div>
                            </>
                        )
                    })
                    return (
                        <>
                            <div>
                                {listSupport}
                            </div>
                        </>
                    );
                },
            },
            {
                id: "totalMetrage",
                accessor: "metrageTotal",
                Header: "Metrage total",
                    Cell: ({ row }) => {
                        return (
                            <div>
                                {row.original.metrageTotal !== null ? `${row.original.metrageTotal.toFixed(2)} Ml` : null}
                            </div>
                        );
                },
            },
            {
            id: "dateCreate",
            Header: "Date de création",
            accessor: "createdAt",
                Cell: (row) => <Moment date={row.value} format={"DD/MM/YYYY"} />
            },
            {
            id: "wishDate",
            Header: "Date souhaitée",
            accessor: "wishDate",
                Cell: ({row}) => {
                    const today = moment();
                    const wishDate = moment(row.original.wishDate);
                    const daysDifference = wishDate.diff(today, 'days');
                    let isLimit
                    if(daysDifference >= 4){
                        isLimit = true
                    }else{
                        isLimit = false
                    }
                    return(
                        row.original.wishDate ? 
                        <div>
                            <Moment date={row.original.wishDate} className={isLimit ? "wishOk" : "wishWarning"} format={"DD/MM/YYYY"} />
                        </div>
                        :null
                    );
                }
            },
            {   
            id: "price",
            accessor: "totalPrice",
            Header: "Total commande",
                Cell: (row) => <div>€ {row.value.toFixed(2)}</div>
            },
            {
            id: "trackingNumber",
            accessor: "trackingNumber",
            Header: "Numéro de suivi",
            },
            {
            id: "deliverWeight",
            accessor: "deliverWeight",
            Header: "Poid du colis",
            },
            {
            id: "action",
            accessor: "action",
            Header: "Actions",
                Cell: ({row}) => {
                    const contactInfo = async () => {
                        const query = {id : row.original.subCommands[0].organisationId}
                        const contact = await fetchOrganisations(query)
                        setContact(contact.data[0])
                    }

                    const handleEdit = (props) => {
                        contactInfo()
                        setIndex(1)
                        setSelectedData(props)
                        toggle();
                    }

                    const handleViewPdf = async (props) => {
                        if(props.media !== null){
                            const res = await fetchBase64Media(props.media.id)
                            const ext = props.media.format
                            var a = document.createElement("a");
                            a.href = `data:image/${ext};base64,` + res;
                            a.download = `${props.media.name}`;
                            a.click();
                        }else{
                            toast.error("Il n'y a pas de fichier d'information sur cette commande")
                        }
                    }

                    const handleEstimate = async (props) => {
                        if(props.estimateNumber === null){
                            const payload = {
                                createEstimate : true
                            }
                            try{
                                const resp =  await updateCommands(props.id, payload)
                                if(resp){
                                    loadData(page)
                                }
                            }catch(error){
                                toast.error("Une erreur est survenue")
                            }
                            
                        }else{
                            navigator.clipboard.writeText(props.estimateNumber)
                            toast.success(`Numéro devis ${props.estimateNumber} copié dans le presse papier`)
                        }
                    }

              
                    return(
                        <div className={"text-center"}>
                            <Button color={"transparent"} onClick={() => handleView(row.original)}>
                                <i className={"fa fa-eye"} />
                            </Button>
                            {row.original.media ? 
                                <Button color={"transparent"} onClick={() => handleViewPdf(row.original)}>
                                    <i className={"fas fa-file-pdf"} />
                                </Button>
                            :null}
                            {isAdmin ? 
                                <>
                                    <Button color={"transparent"} onClick={() => handleEdit(row.original)}>
                                        <i className={"fas fa-shipping-fast"} />
                                    </Button>
                                    <Button  color={row.original.estimateNumber ? "teal" : "red"} onClick={() => handleEstimate(row.original)}>
                                        <i class='fa fa-calculator'></i>
                                    </Button>
                                </>
                            :null}
                        </div>
                    )
                }
            },
        ];
        if (isAdmin){
            /* result.push({
                id: "organisation",
                accessor: "organisations",
                Header: "Organisation",
                    Cell: (row) => {
    
                        return(
                            <div>{row.row.original.organisation.name}</div>
                        )
                    }
            }) */
            return result
        }else{
            return result
        }
    }, []);

       
    return(
        <>
            <h1 className="subTitleModal">Commandes en cours</h1>
            {!isAdmin ? null : 
                <Col xs="10">
                <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
                <Collapse isOpen={isOpen} className="filterButton">
                    <Row className="filterRow">
                    <Label className="filterLabel">Recherche par nom de client </Label>
                    <Select.Async
                        placeholder="Nom du client"
                        noResultsText="Pas de résultat"
                        className="searchSaler"
                        onInputChange={handleInputClientChange}
                        onChange={handleClientChange}
                        value={selectedClientValue}
                        loadOptions={loadClientOptions}
                    />
                    </Row>
                    <Row className="filterRow">
                        <Label className="filterLabel">Recherche par statut </Label>
                        <Select
                            placeholder="Statut"
                            className="searchSaler"
                            onChange={handleStatusChange}
                            value={selectedStatusValue}
                            noResultsText="Pas de résultat"
                            options={options}
                        />
                    </Row>
                </Collapse> 
                </Col>
            }
            <Table
            className="dashMarg"
            data={data}
            columns={columns}
            loadData={loadData}
            paginated
            pagination={pagination}
            />
             <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1600px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">Sous commandes de la commande : {selectedData.clientRef ? selectedData.clientRef : selectedData.ref}</h1>
                </ModalHeader>
                <ModalBody>
                    {index === 0 ? <SubCommandsList commandId={selectedData.id}/> : null}
                    {index === 1 ? 
                    <>
                        <div className="widthInfo">
                            <div>{`Adresse de livraison : ${selectedData.subCommands[0].deliveryAddress}`}</div>
                            <div>{contact ? contact.email ? `Email client : ${contact.email}` : "Aucun email" : null}</div>
                            <div>{contact ? contact.phone ? `Téléphone client : ${contact.phone}` : "Aucun numéro de téléphone" : null}</div>
                        </div>
                        <CommandsForm 
                            initialValues={selectedData}
                            onSubmit={onSubmit}/> 
                    </>
                    : null}
                </ModalBody>
            </Modal>
        </>
    )   
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(InProgressCommands) ;
