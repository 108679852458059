import React, { useMemo } from "react";
import { Button } from "reactstrap";
import Table from "../../Table";
// type: "manufacture" | "fabric" | undefined

const CustomersList = ({
  onEdit,
  onDelete,
  pagination,
  loadData,
  data,
  isSaler,
  userId
}) => {
  const columns = useMemo(() => {
    const result = [
      {
        id: "name",
        accessor: "name",
        Header: "Nom",
      },
      {
        id: "socialReason",
        accessor: "socialReason",
        Header: "Raison Sociale",
      },
      {
        id: "email",
        accessor: "email",
        Header: "Email",
      },
      {
        id: "phone",
        accessor: "phone",
        Header: "Téléphone",
      },
    ];

    if (!isSaler) {
      result.push({
        id: "action",
        accessor: "action",
        Header: "Actions",
        disabled: true,
        Cell: ({ row }) => {
          const currentData = row.original;

          return (
            <div className={"text-center"}>
              <Button color={"transparent"} onClick={() => onEdit(currentData)}>
                <i className={"fa fa-edit"} />
              </Button>
              <Button
                color={"transparent"}
                onClick={() => onDelete(currentData)}
              >
                <i className={"far fa-trash-alt"} />
              </Button>
            </div>
          );
        },
      });
    }else{
      result.push({
        id: "isClient",
        accessor: "client",
        Header: "Mes clients",
        disabled: true,
        Cell: ({ row }) => {
          const salerId = row.original.salerId;
          if(salerId == userId){
            return (
              <div className={"text-center"}> 
                <i class="fa fa-check" />
              </div>
            );
          }
          return null
        },

      });
    }

    return result;
  }, []);


  return (
    <div>
      <Table
        data={data}
        columns={columns}
        loadData={loadData}
        pagination={pagination}
        paginated
      />
    </div>
  );
};

export default CustomersList;
