import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	NavItem,
	NavLink,
	Nav,
	TabPane,
	TabContent,
	ListGroup,
	ListGroupItem,
} from "reactstrap";

import { connect } from "react-redux";
import Loading from "../../components/Loading";
import * as commandService from "../../services/command";
import * as commandItemService from "../../services/commandItem";
import FabricShopPopup from "./../PriceFabrics/shop/shopPopup";
import ManufactureShopPopup from "./../PriceManufactures/shop/shopPopup";
import MetrageShowPopup from "./MetragePopup";
import { Line } from "rc-progress";
import classnames from "classnames";
import WHTable from "../../components/WHTable";
import Moment from "react-moment";
import * as commandStatusService from "../../services/commandStatus";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import CONFIG from "../../constants/parameters";
import EuroFormat from "../../components/EuroFormat/EuroFormat";
import { getCommandStatus } from "../../reducers/params";
import Select from "../../components/Select/Select";
import { CommandColor } from "../../services/commandStatus/commandStatusEnum";
import * as mediaService from "../../services/media";
import FormTracking from "./views/FormTracking";

class DetailsPopup extends Component {
	state = {
		open: false,
		data: {},
		activeTab: "1",
		loaded: true,
	};

	static getDerivedStateFromProps(props, state) {
		if (!state.data) return null;
		const cmd = props.data.find((d) => d.id === state.data.id);
		if (!cmd) return null;
		if (state.data !== cmd) {
			return {
				data: cmd,
			};
		}
		return null;
	}

	load = (id) => {
		this.setState({ create: false, loaded: false });

		commandService.view(id).then((data) => {
			this.setState({ data: data, loaded: true });
		});
	};

	handleOpen = (id = null) => {
		this.setState({ create: true, open: true });

		if (id) {
			this.load(id);
		}
	};

	handleClose = () => {
		this.setState({ open: false, data: {} });
	};

	validSubCommand = () => {
		commandService
			.update(this.state.data)
			.then((cmd) => {
				//getting the newCommand;
				this.props.validSubCommand(this.state.data.id, cmd);
				this.setState({ open: false, data: {} });
			})
			.catch((exception) => {
				this.setState({ loaded: true });
				toast.error("Impossible d'uploader la commande");
			});
	};

	confirmCommand = () => {
		let data = {
			command: this.state.data,
			status: CONFIG.COMMAND_STATUS_PREPARATION,
		};

		this.setState({ loaded: false });
		return commandStatusService
			.create(data)
			.then(() => {
				this.setState({ loaded: true });
				this.props.confirmCommand(this.state);
				toast.success("Enregistrement réussi");
				this.handleClose();
			})
			.catch((error) => {
				throw SubmissionError(error);
			});
	};

	validTracking = (values) => {


		const command = {
			...this.state.data,
			trackingNumber : values.trackingNumber
		};

		this.setState({ loaded: false });

		return commandService
			.update(command)
			.then(() => {
				this.setState({ loaded: true });

				toast.success("Enregistrement réussi");
				this.props.parentLoad(this.state);
				this.handleClose();
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	}

	validCommand = () => {
		let data = {
			command: this.state.data,
			status: CONFIG.COMMAND_STATUS_VALIDATE,
		};

		this.setState({ loaded: false });

		return commandStatusService
			.create(data)
			.then(() => {
				this.setState({ loaded: true });

				this.props.validCommand(this.state);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	edit = (data) => {
		if (data.product.fabric) {
			this.refs.fabricShopPopup.handleEdit(data.id);
		} else if (data.product.manufacture) {
			this.refs.manufactureShopPopup.handleEdit(data.id);
		}
	};

	remove = (id) => {
		if (
			window.confirm(
				"Etes vous sûre de vouloir supprimer cette élément ?"
			)
		) {
			commandItemService.remove(id).then(() => {
				toast.success("Le produit a été supprimé !!");

				this.load(this.state.data.id);
			});
		}
	};

	download = (id) => {
		mediaService.download(id);
	};

	openMetrage = (command) => {
		this.refs.metrageShopPopup.handleOpen(command);
	};

	columns = () => {
		let columns = [];
		const { currentStatus, organisation } = this.state.data;

		const { isAdmin } = this.props;


		columns.push(
			{
				Header: "Support textile",
				accessor: "support",
			},
			{
				Header: "Article",
				accessor: "label",
			},
			{
				Header: "Quantité",
				accessor: "quantity",
			},
			{
				Header: "Référence interne ",
				accessor: "internRef",
			},
			{
				Header: "Nom client",
				accessor: "organisation.name",
				Cell: (row) => <div>{organisation.name}</div>,
			},
			{
				Header: "Nom client final",
				accessor: "finalCustomer",
			},
			{
				Header: "Prix unitaire",
				accessor: "unitPrice",
				Cell: (row) => <EuroFormat value={row.value} />,
			},
			{
				Header: "Prix total",
				accessor: "totalPrice",
				Cell: (row) => <EuroFormat value={row.value} />,
			},
			{
				Header: "Nom (liv)",
				accessor: "lastName",
			},
			{
				Header: "Prénom (liv)",
				accessor: "firstName",
			},
			{
				Header: "Rue (liv)",
				accessor: "deliveryAddress.street",
			},
			{
				Header: "Complément (liv)",
				accessor: "deliveryAddress.complement",
			},
			{
				Header: "Code postal (liv)",
				accessor: "deliveryAddress.zipCode",
			},
			{
				Header: "Ville (liv)",
				accessor: "deliveryAddress.city",
			},
			{
				Header: "Pays (liv)",
				accessor: "deliveryAddress.country",
			},
			{
				Header: "Téléphone",
				accessor: "deliveryAddress.phone",
			},
			{
				Header: "Statut",
				accessor: "product.manufacture",
				Cell: (row) => (
					<Select
						onlySelect
						afterUpdate={(value) =>
							this.props.updateSubCommand(
								this.state.data.id,
								row.index,
								value
							)
						}
						value={
							this.state.data.commandItem
								? this.state.data.commandItem[row.index].status
								: null
						}
						isDisabled={
							!isAdmin ||
							!currentStatus ||
							currentStatus < CONFIG.COMMAND_STATUS_PREPARATION
						}
						options={this.getStatus(row.value)}
					/>
				),
			},

			{
				Header: "Fichier d'impression",
				accessor: "media.title",
				Cell: (row) => (
					<Button
						color="transparent"
						onClick={() => this.download(row.original.id)}
					>
						<i className={"fa fa-download"} /> {row.value}
					</Button>
				),
			},

			{
				Header: "Metrage",
				accessor: "product.fabricDimension",
				minWidth: 200,
				Cell: (row) => {
					if (row.original.product.manufacture) {
						return (
							<div>
								<Button
									onClick={() =>
										this.openMetrage(row.original)
									}
									className={"btn btn-gray btn-padded"}
								>
									Mettrage(s)
								</Button>
							</div>
						);
					} else {
						return (
							<div>
								{row.value
									? row.value * row.original.quantity
									: ""}
							</div>
						);
					}
				},
			}
		);
		if (currentStatus === CONFIG.COMMAND_STATUS_CREATE) {
			columns.push({
				Header: "Actions",
				accessor: "id",
				filterable: false,
				Cell: (row) => (
					<div>
						<Button
							color={"transparent"}
							onClick={() => this.edit(row.original)}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
			});
		}
		return columns;
	};

	getStatus = (value) => {
		if (value)
			return [
				{ value: 10, label: "En attente" },
				{ value: 20, label: "En cours de d’impression" },
				{ value: 30, label: "En cours de confection" },
				{ value: 40, label: "Prêt pour expédition" },
				{ value: 50, label: "Expédié" },
				{ value: 60, label: "Facturé" },
			];
		else
			return [
				{ value: 10, label: "En attente" },
				{ value: 20, label: "En cours de d’impression" },
				{ value: 40, label: "Prêt pour expédition" },
				{ value: 50, label: "Expédié" },
				{ value: 60, label: "Facturé" },
			];
	};

	onSubmit = (data) => {
		this.setState({ loaded: false });
		return commandStatusService
			.create(data)
			.then(() => {
				toast.success("Enregistrement réussi");

				this.setState({ loaded: true });

				this.handleClose();

				this.props.validCommand(this.state);
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	newData = (data) => {
		let lst = this.state.data;

		lst.totalPrice = data.command.totalPrice;

		let index = lst.commandItem.findIndex((el) => el.id == data.id);

		if (index > -1) {
			lst.commandItem[index] = data;
		}

		this.setState({ data: lst });
	};

	render() {
		const { className, commandStatus, isAdmin } = this.props;
		const { loaded, data, open, activeTab } = this.state;
		const {
			statusName,
			currentStatus,
			commandItem,
			status,
			totalPrice,
			trackingNumber
		} = data;
		return (
			<div>
				<ManufactureShopPopup
					newData={this.newData}
					ref={"manufactureShopPopup"}
				/>
				<FabricShopPopup
					newData={this.newData}
					ref={"fabricShopPopup"}
				/>
				<MetrageShowPopup ref={"metrageShopPopup"} />
				<Modal
					size={"lg"}
					isOpen={open}
					className={className}
					fade={false}
				>
					<ModalHeader toggle={() => this.handleClose()}>
						Informations sur votre commande
					</ModalHeader>

					{!loaded && <Loading />}
					<ModalBody>
							{loaded && isAdmin &&
							<div className={"float-right"}>
									<FormTracking
										onSubmit={this.validTracking}
										initialValues={this.state.data}
									/>
								</div>
								}
							{!isAdmin &&
								<div className={"float-right"}>Numéro de suvi :{trackingNumber}</div>
							}

						<div>
							Avancement de votre commande : {statusName}
							<Line
								percent={
									(commandStatus.findIndex(
										(stat) => stat.value === currentStatus
									) *
										100) /
									(commandStatus.length - 1)
								}
								strokeColor={CommandColor(currentStatus)}
							/>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === "1",
										})}
										onClick={() => {
											this.toggle("1");
										}}
									>
										Détails
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === "2",
										})}
										onClick={() => {
											this.toggle("2");
										}}
									>
										Historique
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<WHTable
										data={commandItem}
										columns={this.columns()}
										defaultPageSize={10}
										filterable={true}
									/>
								</TabPane>
								<TabPane tabId="2">
									<ListGroup>
										<ListGroupItem color={"info"}>
											Suivi de votre commande
										</ListGroupItem>
										{status &&
											status.map((data, index) => {
												return (
													<ListGroupItem key={index}>
														{data.statusName}{" "}
														<span
															className={
																"pull-right"
															}
														>
															{" "}
															Le{" "}
															<Moment
																format="DD/MM/YYYY"
																date={data.date}
															/>
														</span>
													</ListGroupItem>
												);
											})}
									</ListGroup>
								</TabPane>
							</TabContent>
						</div>
					</ModalBody>

					<ModalFooter>
						<div className={"mr-auto"}>
							<h3>
								<b>
									Total de votre commande :{" "}
									<EuroFormat value={totalPrice} />
								</b>
							</h3>
						</div>
						{currentStatus === CONFIG.COMMAND_STATUS_CREATE && (
							<Button
								onClick={this.validCommand}
								className={"btn btn-green btn-padded"}
								>
								Valider votre commande
							</Button>
						)}
						{isAdmin &&
							currentStatus ===
								CONFIG.COMMAND_STATUS_VALIDATE && (
								<Button
									onClick={this.confirmCommand}
									className={"btn btn-green btn-padded"}
								>
									{" "}
									Confirmer la commande
								</Button>
							)}
						{isAdmin &&
							currentStatus >=
								CONFIG.COMMAND_STATUS_PREPARATION && (
								<Button
									onClick={() => {
										this.validSubCommand(
											this.state.data.id
										);
									}}
									className={"btn btn-green btn-padded"}
								>
									{" "}
									Valider modification
								</Button>
							)}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	commandStatus: getCommandStatus(state),
});

export default connect(mapStateToProps, null, null, { withRef: true })(
	DetailsPopup
);
