import React, { useState } from "react";
import EditPopup from "./EditPopup";
import { Card, CardHeader, CardFooter, Row, Label, Input } from "reactstrap";
import useProductService from "../../hooks/services/useProductService";
import ProductList from "../../components/Products/ProductList";
import useModal from "../../hooks/useModal";
import Select from "react-select";
import { fetchProducts } from "../../services/pim";

const FabricProducts = () => {
  const [selectedData, setSelectedData] = useState();
  const { toggle, active } = useModal();
  const [isCreated, setIsCreated] = useState(false);
  const [inputProductValue, setInputProductValue] = useState('');
  const type = 100;

  const { loadData, onSubmit, onDelete, data, pagination, customPrices } = useProductService({
    selectedData,
    toggle,
    type,
    inputProductValue
  });

  const onEdit = (data) => {
    setSelectedData({
      ...data,
      tissu: { label: data.fabric.ref, value: data.fabric.id },
    });

    toggle();
  };

  const onClose = () => {
    setIsCreated(false);
    setSelectedData();
    toggle();
  };

  const onCreate = () => {
    setIsCreated(true);
    setSelectedData();
    toggle();
  };

  const onSuccess = () => {
    loadData(1);
    toggle();
  };

  const handleProductChange = (event) => {
    setInputProductValue(event.target.value)
  }
  
  return (
    <div className="animated fadeIn">
      <EditPopup
        data={{ ...selectedData, customPrices}}
        active={active}
        onClose={onClose}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        isCreated={isCreated}
      />

      <Card>
        <CardHeader>
          <h1>Tissus</h1>
          <a
            href={`/help/#/configuration?id=gestion-des-produits`}
            target={"_blank"}
            className={"card-header-tooltip"}
          >
            ?
          </a>
        </CardHeader>
        <Row className="filterRow">
          <Label className="filterLabel">Recherche par référence</Label>
          <Input
              placeholder="référence"
              className="searchSaler"
              onChange={handleProductChange}
              value={inputProductValue}
          />
        </Row>
        <ProductList
          type="fabric"
          loadData={loadData}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={pagination}
        />

        <CardFooter>
          <div className="card-actions">
            <button className={"btn btn-gray btn-padded"} onClick={onCreate}>
              Ajouter
            </button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default FabricProducts;
