import React, { useState } from "react";
import { Input, Card, Row, Button } from "reactstrap"

const clientNote = (props) => {
    const [isNote, setisNote] = useState(false)
    const [inputValue, setInputValue] = useState("")


    const handleCreateNote = () => {
        setisNote(true)
    }

    const handleInputChange = (props) => {
        setInputValue(props.target.value)
    }

    return(
        <>
            {!isNote ? 
                <Card>
                    <div>
                        Avez vous une information supplémentaire à nous transmettre ? 
                    </div>
                    <Row>
                        <Button color="green" onClick={() => handleCreateNote()}>
                            Oui 
                        </Button>
                        <Button onClick={() => props.handleClickClientNote("none")}>
                            Non
                        </Button>
                    </Row>
                </Card>
            : 
                <Card className="clientNote">
                    <div>Que voulez vous nous transmettre ? </div>
                    <textarea value={inputValue} onChange={(e) => handleInputChange(e)} className="textAreaCommand">Saisir ici</textarea>
                    <Button color="dark" onClick={() => props.handleClickClientNote(inputValue)}>Enregistrer</Button>
                </Card>
            }
        </>
    )
}

export default clientNote 