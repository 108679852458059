import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import UserForm from "./UserForm";
import * as userService from "../../services/user";
import Loading from "../../components/Loading";
import Locals from "../../components/Locals";

class CreateEditPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {},
      loaded: true,
    };
  }

  handleOpen = (id = null) => {
    this.setState({
      open: true,
    });
 
    if (id) {
      this.setState({
        loaded: false,
      });

      userService.view(id).then((data) => {
        this.setState({
          data,
          loaded: true,
        });
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { className, roles, active, data, isCreated } = this.props;
    const { loaded } = this.state;

    return (
      <Modal
        isOpen={active}
        className={className}
        fade={false}
        toggle={this.handleClose}
      >
        <ModalHeader toggle={this.handleClose}>
          {isCreated ? "Créer un nouvel utilisateur" : "Editer un utilisateur"}
          <Locals />
        </ModalHeader>

        {!loaded && <Loading />}

        {loaded && (
          <div>
            <UserForm
              onSubmit={this.props.onSubmit}
              initialValues={
                data
                  ? {
                      firstname: data.firstname,
                      lastname: data.lastname,
                      phone: data.phone,
                      email: data.email,
                      roles: data.roles,
                      organisation: {
                        label: data.organisationName,
                        value: data.organisationId,
                      },
                    }
                  : undefined
              }
              roles={roles}
            />
          </div>
        )}
      </Modal>
    );
  }
}

export default CreateEditPopup;
