import React, { useState, useMemo, useEffect }from "react";
import Table from "../../components/Table";
import { deleteMedias, fetchBase64Media, fetchMedias } from "../../services/crm";
import { Button, ModalBody, ModalFooter, ModalHeader, Modal, Input, Row, Label} from "reactstrap";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import useModal from "../../hooks/useModal";
import StatusSelect from "./statusSelect";
import { toast } from "react-toastify";
import FilesAddDbLink from "./filesAddDbLink";
import Select from "react-select";

const FilesList = (props) => {
    const {userRoles, commandId, isSaler, user} = props
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const [pagination, setPagination] = useState();
    const [data, setData] = useState([]);
    const { toggle, active } = useModal();
    const [selectedData, setSelectedData] = useState();
    const organisationId = user.organisationId;
    const [indexModal, setIndexModal] = useState(0)
    const [selectedMediaValue, setSelectedMediaValue] = useState(null);
    const [inputMediaValue, setInputMediaValue] = useState('')

    useEffect(() => {
        loadData(1)
      }, [selectedMediaValue]);

    const loadData = async (pageNumber, limit = 10) => {
        let query
        if(selectedMediaValue){
            query = {name:selectedMediaValue.label}
        }

        if(isAdmin){
            query = {
                ...query,
                $skip: (pageNumber - 1) * limit,
                $limit: limit,
            };

            const { data, ...paginationParams } = await fetchMedias(query);
            setData(data)
            setPagination(paginationParams)
        }else{
            query = {
                ...query,
                $skip: (pageNumber - 1) * limit,
                $limit: limit,
                organisationId : organisationId
            };
            const { data, ...paginationParams } = await fetchMedias(query);
            setData(data)
            setPagination(paginationParams)
        }
    };

    const handleInputMediaChange = (value) => {
        setInputMediaValue(value)
    }

    const handleMediaChange = (value) => {
        setSelectedMediaValue(value)
    }

    const loadMediaOptions = (input) => {
        let query
        if(isAdmin){
            query = {
                name: { $like: `%${input}%` },
            }
        }else{
            query = {
                name: { $like: `%${input}%` },
                organisationId: organisationId
            }
        }

        try{
            return fetchMedias(query).then((result) => {
              const options = result.data.map((item) => ({
                value: item.id,
                label: `${item.name}`,
              }));
        
              return { options };
            });
        }catch(error){
            console.log(error)
        }
    }

    const handleEdit = (props) => {
        setIndexModal(0)
        setSelectedData(props)
        toggle()
    };
    
    const handleDelete = async (props) => {
        try{
            const confirmed = window.confirm(
                `Êtes vous sûr de vouloir supprimer le media ?`
              );
        
              if (confirmed) {           
                await deleteMedias(props.id)
                window.location.reload()
                toast.success("Le media a été supprimé avec succès");

              }
        }catch(error){
            console.log(error)
        }
    };

    const columns = useMemo(() => [
        {
            id: "name",
            accessor: "name",
            Header: "Nom",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.name}
                  </span>
                );
            }
        },
        {
            id: "weight",
            accessor: "weight",
            Header: "Poid",
        },
        {
            id: "status",
            accessor: "statusName",
            Header: "Statut",
            Cell: ({ row }) => {
                return (
                  <span className={`label status-${row.original.status}`}>
                      {row.original.currentStatusName}
                  </span>
                );
            }
        },
        {
            id: "information",
            accessor: "information",
            Header: "Information", 
            Cell: ({ row }) => {
                let isMedia
                if(row.original.url){
                    isMedia = true
                }else{
                    isMedia = false
                }

                const handleAddMedia = (props) => {
                    if(isAdmin){
                        setIndexModal(1)
                        setSelectedData(props)
                        toggle()
                    }
                }
                return (
                    <>
                        <span>
                            {isMedia ?  
                            <Button>
                                ✅
                            </Button>:
                            <Button onClick={() => handleAddMedia(row.original)}>
                                ❌
                            </Button>
                            }
                        </span>
                        <span>
                            {row.original.information}
                        </span>
                    </>
                );
            }
        },
        {
            id: "organisation",
            accessor: "organisation.name",
            Header: "Organisation",
        },
        {
            id: "action",
            Header: "Action",
            accessor: "action",
            Cell: (row) => {
                const handleDlMedia = async () => {
                    if(row.row.original.weight < 150000000){
                        try{
                            const file = await fetchBase64Media(row.row.original.id)
                            var a = document.createElement("a");
                            a.href = "data:image/png;base64," + file;
                            a.download = row.row.original.name;
                            a.click();
                        }catch(error){
                            toast.error("Une erreur est survenue")
                        }
                    }else{
                        toast.error("Le fichier est trop lourd pour être téléchargé")
                    }
                }

                return(
                    <div className={"text-center"}>
                        <Button
                        onClick={() => handleDlMedia()}
                        color={"transparent"}
                        >
                        <i className={"fas fa-cloud-download-alt fa-xl"} />
                        </Button>

                        {isAdmin ?
                            <>
                                <Button
                                    color={"transparent"}
                                    onClick={() => handleEdit(row.row.original)}
                                >
                                    <i className={"fa fa-edit"} />
                                </Button>
                            </>
                        : null}
                        <Button
                            onClick={() => handleDelete(row.row.original)}
                            color={"transparent"}
                        >
                            <i className={"far fa-trash-alt"} />
                        </Button>
                        
                    </div>
                )
            }
      },

    ])
    return(
        <>
            <Row className="filterRow">
                            
                <Label className="filterLabel">Recherche par nom de média </Label>
                <Select.Async
                    placeholder="Nom du média"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputMediaChange}
                    onChange={handleMediaChange}
                    value={selectedMediaValue}
                    loadOptions={loadMediaOptions}
                />  
            </Row>
            <Table
            className="dashMarg"
            data={data} 
            columns={columns}
            loadData={loadData}
            paginated
            pagination={pagination}
            />
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}>
                <ModalHeader>
                    
                    {indexModal === 0 ? "Editer le statut du média" : null}
                    {indexModal === 1 ? "Upload du média" : null}
                </ModalHeader>
                <ModalBody>
                    {indexModal === 0 ? <StatusSelect selectedData={selectedData}/> : null}
                    {indexModal === 1 ? 

                    <>
                    <h2 className="stepMedia">Etape 1 : Télécharger le fichier </h2>
                        <div> Lien pour télécharger le fichier : </div>
                        <a href={selectedData.downloadUrl}>{selectedData.downloadUrl }</a>
                    <h2 className="stepMedia">Etape 2 : Ajouter le média sur le dropbox de myditex dans le dossier souhaité</h2>

                    <h2 className="stepMedia">Etape 3 : Lier le fichier dans l'erp </h2>
                        <FilesAddDbLink mediaId={selectedData.id}/> 
                    </>
                    : null}
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(FilesList);