import React, { useEffect, useState } from "react";
import { fetchFabrics } from "../../services/pim";
import { Row, Progress, Col, Button, ModalHeader, ModalBody } from "reactstrap";
import { Modal } from "reactstrap"
import useModal from "../../hooks/useModal";
import EditPopUp from "./editPopUp";
import useStockService from "../../hooks/services/useStockService";
import IndicateursStock from "./indicateursStock";

const StockFaible = () => {
    const [data, setData] = useState([]);
    const { toggle, active } = useModal();
    const [selectedData, setSelectedData] = useState([]);
    const [toggleAllRef, setToggleAllRef] = useState(5);
    const [toggleAllAlert, setToggleAllAlert] = useState(5);
    const [isOpen, setIsOpen] = useState(false);
    const [allRellOpen, setAllRefOpen] = useState(false);
    const [pagination, setPagination] = useState()
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(0)

    useEffect( () => {
		loadStock(page);
	}, [page]);
    
    const loadStock = async (pageNumber) => {
        setPage(pageNumber);
        const limit = 50
        const query = {
            $skip: (pageNumber - 1) * limit, 
            $limit: limit,
          }
		const {data, ...paginationParams} = await fetchFabrics(query);
  
        const pagination = paginationParams
        const newData = data.sort((a, b) => ((a.stock / a.stockMaxi) * 100 ) - ((b.stock / b.stockMaxi) * 100 ) )
        setData(newData)
        setPagination(paginationParams)
        const newMax = Math.ceil(paginationParams.total / paginationParams.limit)
        setMaxPage(newMax)
    };

    const handleIncreasePage = () => {
        if(page < maxPage){
            setPage(page + 1)
        }
    }

    const handleDecreasePage = () => {
        if(page > 1){
            setPage(page - 1)
        }
    }

    const handleToggle = (props) => {
        const minStockPercent = props[1];
        setSelectedData({...props[0],minStockPercent});
        toggle();
    };

    const handleToggleAllRef = () => {
        if(toggleAllRef == 5){
            setAllRefOpen(true)
            setToggleAllRef(data.length)
        }else{
            setAllRefOpen(false)
            setToggleAllRef(5);
        }
    };
    
    const handleToggleAllAlert = () => {
        if(toggleAllAlert== 5){
            setIsOpen(true)
            setToggleAllAlert(data.length)
        }else{
            setIsOpen(false)
            setToggleAllAlert(5);
        }
    };


    const { onSubmit } =
    useStockService({
      selectedData,
      toggle,
      loadStock
    });

    const stockFaibles = data.slice(0,toggleAllAlert).map((stockFaible) => {
        if(stockFaible.alert == true){
            const pourcentage = ((stockFaible.stock / stockFaible.stockMaxi) * 100);
            const pourcentageRounded = Math.round(pourcentage * 10) / 10;
            const minStockPercent = Math.round(((stockFaible.stockAlert / stockFaible.stockMaxi) * 100)* 10) / 10;
            let color = "red";

            return(
                <>
                    <Row className="marg">
                        <Col>
                            {stockFaible.ref}
                        </Col>
                        <Col>
                            {stockFaible.name}
                        </Col>
                        <Col>
                            <Progress className="radius" color={color} value={pourcentageRounded} />
                        </Col>
                        <Col>
                            {`${stockFaible.stock}ml (${pourcentageRounded}%)`}
                        </Col>
                        <Col>
                            <Button className="alerteButton">Envoyer une alerte</Button>
                        </Col>
                        <Col>
                            <Button className="buttonAddRound" value={[stockFaible, minStockPercent]} onClick={() => handleToggle([stockFaible, minStockPercent])}>+</Button>
                        </Col>
                    </Row>
                </>
            )
        }
    })
    const dataWithoutAlert = data.filter(item => item.alert === 0);

    const stockProd = dataWithoutAlert.slice(0,toggleAllRef).map((stockFaible) => {
            const pourcentage = (stockFaible.stock / stockFaible.stockMaxi) * 100  
            const pourcentageRounded = Math.round(pourcentage * 10) / 10
            const minStockPercent = Math.round(((stockFaible.stockAlert / stockFaible.stockMaxi) * 100)* 10) / 10;

            let color;

            switch(true){
                case pourcentageRounded < minStockPercent :
                    color = "red"
                break;
                case pourcentageRounded >= minStockPercent && pourcentageRounded < stockFaible.perfectStock :
                    color = "warning"
                break;
                case pourcentageRounded >= stockFaible.perfectStock :
                    color = "teal"
                break;
            }
            return(
                <>
                    <Row className="marg">
                        <Col>
                            {stockFaible.ref}
                        </Col>
                        <Col>
                            {stockFaible.name}
                        </Col>
                        <Col>
                            <Progress className="radius" color={color} value={pourcentageRounded} />
                        </Col>
                        <Col>
                            {`${stockFaible.stock}ml (${pourcentageRounded}%)`}
                        </Col>
                        <Col>
                            <Button className="alerteButton">Envoyer une alerte</Button>
                        </Col>
                        <Col>
                            <Button className="buttonAddRound" value={[stockFaible, minStockPercent]} onClick={() => handleToggle([stockFaible, minStockPercent])}>+</Button>
                        </Col>
                    </Row>
                </>
            )
        
    })


    return(
        <>
            <IndicateursStock data={data}/>
            <h2>Stocks faibles</h2>
            <div className="dashMarg">
                {stockFaibles ? stockFaibles : "Aucun stock en alerte"}
            </div>
            {!isOpen ? 
            <span className="showMore" onClick={handleToggleAllAlert}>
                Voir tous les stocks en alerte
            </span>
            :
            <span className="showMore" onClick={handleToggleAllAlert}>
                Voir moins
            </span>}
            <h2 className="dashMarg">
                Références textiles à produire
            </h2>
            <div className="dashMarg">
                {stockProd}
            </div>
            {!allRellOpen ? 
            <span className="showMore" onClick={handleToggleAllRef}>
                Voir tous les stocks
            </span>
            :
            <>
            
            <Row className="modalSubmit">
                <Button onClick={handleDecreasePage} className="inputButton">{'<'}</Button>
                <div>Page {page} sur {maxPage}</div>
                <Button onClick={handleIncreasePage} className="inputButton">{'>'}</Button>
            </Row>
            <span className="showMore" onClick={handleToggleAllRef}>
                Voir moins
            </span>

            </>
            }
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">Stock {selectedData.name}</h1>
                </ModalHeader>
                <ModalBody>
                    <EditPopUp
                        onSubmit={onSubmit}
                        initialValues={selectedData}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}

export default StockFaible;