export const setBasket = item => {
    return{
    type: 'ADD_ITEM_BASKET',
    payload: item,
}};

export const delBasket = id => {
    return{
    type: 'DEL_ITEM_BASKET',
    payload: id,
}};

export const eraseBasket = id => {
    return{
    type: 'DEL_BASKET',
    payload: id,
}};

export const newBasket = item => {
    return{
        type: 'NEW_BASKET',
        payload: item
    }
}

export const updateBasket = (item, index)=> {
    return{
        type: 'UPDATE_BASKET',
        payload: {item, index}
    }
}