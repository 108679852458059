import React, { Component } from "react";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	change,
	getFormValues,
} from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WhFieldTxt, WhFieldCkEditor, WhFieldFile, WhFieldSelect } from "../..";
import { Form, ModalBody, ModalFooter, Button, Alert } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class FormRequest extends Component {
	state = {
		activeFilter: false,
		service: null,
	};

	componentDidMount = () => {
		const copyText = document.getElementById("copyText");
		const value = copyText.value;

		try {
			const service = JSON.parse(value);
			if (service.service) {
				this.setState({ activeFilter: true, service });
			}
		} catch (error) {}
	};

	pastFilter = () => {
		const { service } = this.state;
		const { change } = this.props;

		const filters = JSON.stringify(service.filters, null, 4);

		change("service", service.service);
		change("filters", filters);
	};

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			initialValues,
		} = this.props;

		const { activeFilter } = this.state;

		return (
			<Form className="form-vertical">
				<ModalBody>
					{(activeFilter || initialValues.filters) && (
						<div>
							<Field
								label={false}
								name="service"
								component={WhFieldTxt}
								type="text"
								placeholder="Service"
							/>
							<Field
								label={false}
								name="filters"
								component={WhFieldTxt}
								type="textarea"
								placeholder="Filtres"
							/>

							{activeFilter && (
								<Button
									color="primary"
									size="lg"
									block
									onClick={() => this.pastFilter()}
								>
									Coller le service
								</Button>
							)}
						</div>
					)}

					{!activeFilter && !initialValues.filters && (
						<Alert color="info">
							Vous devez d'abord copier un service
						</Alert>
					)}
					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				{(activeFilter || initialValues.filters) && (
					<ModalFooter>
						<LaddaButton
							className="btn btn-gray btn-padded btn-ladda"
							data-style={EXPAND_LEFT}
							onClick={handleSubmit}
							type="button"
						>
							Enregistrer{" "}
						</LaddaButton>
					</ModalFooter>
				)}
			</Form>
		);
	}
}

const formName = "formRequest";

const rxf = reduxForm({ form: formName })(FormRequest);

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default connect(
	null,
	mapDispatchToProps,
)(rxf);
