const recursiveFilter = (items, user) =>
	items.filter(item => {
		const found = item.roles.find(roles => {
			if(roles == user.roles){
				if (item.children) {
					item.children = recursiveFilter(item.children, user);
				}
				return item
			}
		})
		return found
	});

export default recursiveFilter;
