import React, { useState, useEffect } from "react";
import ClientNote from "./clientNote";
import BasketAdd from "./basketAdd";
import DragDropFiles from "../MediasTunnel";
import { getBasket, setBasket } from "../../reducers/basket";
import { connect } from "react-redux";
import confectionPicto from "../../assets/picto/confectionPicto.png"
import { createMedias } from "../../services/crm"; 
import { getUser } from "../Authentication/redux";
import { toast } from "react-toastify";
import { fetchProducts } from "../../services/pim";
import ProdSpeChoice from "./prodSpeChoice";

const ProdSpeIndex = ({basket, onSetBasket, user}) => {
    const [index, setIndex] = useState(1);
    const [prodChoice, setProdChoice] = useState();
    const [typeChoice, setTypeChoice] = useState();
    const [tissuChoice, setTissuChoice] = useState();
    const [files, setFiles] = useState();
    const [maxIndex, setMaxIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const [finalArticle, setFinalArticle] = useState()

    useEffect(() => {
        if(index > maxIndex){
            setMaxIndex(index);
        }
      },[index]);

    const handleClickProd = async (product) => {
        setProdChoice(product)
        //On récupère le produit avec ses infos prix et autres
        const query = {
            id: product.value
        }
        const produit = await fetchProducts(query)
        setFinalArticle(produit.data)
        setIndex(2)
    }

    const handleClickFiles = (files) => {
        if(files[0] === undefined){
            setFiles(files[1])
        }else{
            if(files[1] === undefined){
                setFiles(files[0])
            }else{
                const newArray = [...files[0], ...files[1]]
                setFiles(newArray)
            }
        }
        setIndex(4)
    }
    
    const handleClickClientNote = async (props) => {

        let filesToPush = []
        setLoading(true)
        
        await Promise.all(files.map(async (file, index) => {
            if(file != undefined){
                if(file.base64){
                    const payload = {
                        name: file.path,
                        file: file.base64,
                        organisationId:user.organisationId
                    }
    
                    const createdMedia = await createMedias(payload)
                    const newMedia = {...createdMedia, inputValue: file.inputValue, custom: false, path:file.path}
                    filesToPush.push(newMedia)

                }else{
                    filesToPush.push(file)
                }
            }
        }))

        const fileArray = [filesToPush]

        setLoading(false)
        toast.success("Fichier(s) ajouté(s) à vos médias")
        if(props === "none"){
            onSetBasket({item:{article:finalArticle, fichier:{...fileArray}}})
            setIndex(5)
        }else{
            onSetBasket({item:{article:finalArticle, note:{props}, fichier:{...fileArray}}})
            setIndex(5)
        }
    }

    const handleNavigate = (etape, index) => {
        if(etape === 0){
            window.location.reload()
        }else{
            if(maxIndex > etape){
                switch(etape){
                    case 1:
                        setTypeChoice(null)
                        setTissuChoice(null)
                        break;
                    case 2:
                        setTissuChoice(null)
                        break;
                    default:
                        console.log("default")
                }
                setIndex(etape)
                setMaxIndex(etape)
            }
        }
    };
            return(
                <>  
                    <div className="borderEtape">
                        <div className="row">
                            <div className="bulletInActive"></div>
                            <div onClick={() => handleNavigate(0, index)} className="etapeInactive"> Etape 1 : Choisissez un produit - Produit spécifique</div>
                        </div>
                        <div className="row">
                            <img src={confectionPicto} className="inactivePicto"></img>
                            <label className="labelPicto">Spécifique</label>
                        </div>
                        <div className="row">
                            <div className={index === 1 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(1, index)} className={index === 1 ? "etapeActive" : "etapeInactive"}>Etape 2 : Choix du produit  {typeChoice ? `- ${typeChoice}` : null}</div>
                        </div>
                            { index === 1 && <ProdSpeChoice handleClickProd={handleClickProd} orgaId={user.organisationId}/> }
                            <div>{prodChoice ? prodChoice.ref : null}</div>
                        <div className="row">
                            <div className={index === 2 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(2, index)} className={index === 2 ? "etapeActive" : "etapeInactive"}>Etape 3 : Upload du fichier</div>
                        </div>
                            { index === 2 && <DragDropFiles isTissu={false} handleClickFiles={handleClickFiles}/> }

                            <div className="row">
                            <div className={index === 4 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(4, index)} className={index === 4 ? "etapeActive" : "etapeInactive"}>Etape 4 : Note client</div>
                        </div>
                            { index === 4 ? <ClientNote handleClickClientNote={handleClickClientNote}/> : null}

                        <div className="row">
                            <div className={index === 4 ? "bulletActive" : "bulletInActive"}></div>
                            <div onClick={() => handleNavigate(5, index)} className={index === 5 ? "etapeActive" : "etapeInactive"}>Etape 5 : Panier</div>
                        </div>
                        {loading ? 
                                    <div className="containerColCenter">
                                        <div className="tableMarg">Cela peut prendre plus ou moins de temps selon la taille et le nombre de fichiers </div>
                                        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div> :null}
                            { index === 5 ?  <BasketAdd/> : null}
                    </div>
                </>
            )
}

const mapStateToProps = (state) => ({
	basket: getBasket(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBasket: (item) => dispatch(setBasket(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdSpeIndex);