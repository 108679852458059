import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Card, CardHeader, CardFooter, Button } from "reactstrap";
import CONFIG from "./../../constants/parameters";
import ImportPopup from "./ImportPopup";
import * as commandService from "../../services/command";
import DetailsPopup from "./DetailsPopup";
import EuroFormat from "../../components/EuroFormat/EuroFormat";
import { getUserRoles, getUser } from "../Authentication/redux";

const statusList = [
	{
		"value": 100,
		"label": "Commande initialisée"
	},
	{
			"value": 200,
			"label": "Commande validée"
	},
	{
			"value": 300,
			"label": "Confirmée par MyDiTex"
	},
	{
			"value": 350,
			"label": "En imp trans partielle"
	},
	{
			"value": 400,
			"label": "En imp transfert"
	},
	{
			"value": 450,
			"label": "En confection partielle"
	},
	{
			"value": 500,
			"label": "En confection"
	},
	{
			"value": 550,
			"label": "OK expédition partielle"
	},
	{
			"value": 600,
			"label": "A expéditier"
	},
	{
			"value": 650,
			"label": "partiellement expédiée"
	},
	{
			"value": 700,
			"label": "expédiée"
	}
]

class Commands extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
		canDuplicate: true,
		query:{}
	};

	validCommand = () => {
		this.updateCommand(this.state.query);
	};

	confirmCommand = () => {
		this.updateCommand(this.state.query);
	}

	updateCommand = (state, instance) => {
		this.setState({ loaded: false });
		return commandService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					loaded: true,
				});
			});
			this.checkDuplicate();
		});
	};

	duplicate = id => {
		if (
			window.confirm("Etes vous sûre de vouloir dupliquer cet commande ?")
		) {
			this.setState({ loaded: false });
			return commandService.duplicate(id).then(data => {
				toast.success("La commande a été dupliquée.");
				this.newData(data);
				this.setState({ loaded: true, canDuplicate: false });
			});
		}
	};

	columns = () => {
		return [
			{
				Header: "Reférences",
				accessor: "id",
				filterable: false,
				minWidth: 100,

				Cell: row => (
					<strong>{row.value}</strong>
				),

			},
			{
				Header: "Statut",
				accessor: "statusName",
				minWidth: 180,
				filterable: false,
				/* Filter: ({ filter, onChange }) => {

					const statusOptions = [ { value: 'all', label: 'Tous les statuts' }, ...statusList]

					return <FieldSelect
						options={statusOptions}
						valueName="label"
						input={{
							value: filter ? filter.value : null,
							onChange: (event) => {
								const newValue = event.target.value
								onChange(newValue)
							}
						}}
					/>
				} */
				Cell: row => (
					<span className={`label cmdStatus-${row.original.currentStatus}`}>
						{row.value}
						
					</span>
				),
			},
			{

				Header: "Nom du client",
				accessor: "organisation.currentName",
				filterable: false,
			},
			{
				Header: "Date de création",
				accessor: "created",
				filterable: false,
				Cell: row => <Moment date={row.value} format={"DD/MM/YYYY"} />,
				/* Filter: ({ filter, onChange }) => {

					const value = filter !== undefined ? filter.value : ''

					const onDateChange = (newDate) => {

						const newValue = newDate !== null ? moment(newDate).format('YYYY-MM-DD') : ''

						onChange(newValue)
					}

					return (
						<DatePicker
							value={value}
							onChange={onDateChange}
						/>
					)
				} */
			},
			{
				Header: "Total commande",
				accessor: "totalPrice",
				filterable: false,
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value.toFixed(2)} />
					</div>
				),
			},
			{
				Header: "Actions",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"transparent"}
							onClick={() => {

								return this._detailsPopup.handleOpen(row.value)
							}
							}
						>
							<i className={"fa fa-eye"} />
						</Button>

						{this.state.canDuplicate && (
							<Button
								color={"transparent"}
								onClick={() => this.duplicate(row.value)}
							>
								<i className={"fa fa-copy"} />
							</Button>
						)}

						{row.original.currentStatus ===
							CONFIG.COMMAND_STATUS_CREATE && (
							<Button
								color={"transparent"}
								onClick={() => this.remove(row.value)}
							>
								<i className={"far fa-trash-alt"} />{" "}
							</Button>
						)}
					</div>
				),
			}
		];
	};

	remove = toDelete => {
		if (
			window.confirm(
				"Etes vous sûre de vouloir supprimer cette commande ?",
			)
		) {
			this.setState({ loaded: false });

			commandService
				.remove(toDelete)
				.then(data => {
					toast.success("La commande a été supprimée!!");

					this.load(this.state);
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error(
						"Impossible de supprimer le produit, des dépences existent",
					);
				});
		}
	};

	checkDuplicate = () => {
		this.setState({ canDuplicate: true });

		this.state.data.map(values => {
			if (values.currentStatus === CONFIG.COMMAND_STATUS_CREATE) {
				this.setState({ canDuplicate: false });
			}
		});
	};

	validSubCommand = (command_id, cmd) => {
		const { data } = this.state;
		const commandIdx = data.findIndex((d) => d.id === command_id);
		const updatedData = [...data];
		updatedData[commandIdx] = cmd
		this.setState({data:updatedData});
	}

	updateSubCommand = (command_id, subcommand_index, updatedStatus) => {
		const { data } = this.state;
		const command = data.find((d) => d.id === command_id);
		const commandIdx = data.findIndex((d) => d.id === command_id);

		// all the spread are use in order to not mutate data;
		const commandToUpdate = {...command, commandItem:command.commandItem.map((item) => {return {...item}})}
		commandToUpdate.commandItem[subcommand_index].status = parseInt(updatedStatus);
		const updatedData = [...data];
		updatedData[commandIdx] = commandToUpdate;
		this.setState({data:updatedData});
	}

	load = (state, instance) => {
		this.setState({ loaded: false });

		const newState = {
			...state,
			filtered: state.filtered
		}

		return commandService.search(newState).then(data => {
			this.setState({ data: [] }, () => {
				const {pageSize, order, page} = state;
				const query = {itemsPerPage:pageSize, order, page};
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
					query
				});
			});
			this.checkDuplicate();
		});
	};

	newData = data => {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}
		this.setState({ data: lst });
		this.checkDuplicate();
	};

	render() {
		const { data, pages } = this.state;
		const { user } = this.props;
		const isAdmin = this.props.userRoles.findIndex((role) => role === "ROLE_ADMIN") !== -1

		return (
			<div className="animated fadeIn">
				<ImportPopup load={this.load} ref={"importPopup"} />
				<DetailsPopup
					validCommand={this.validCommand}
					confirmCommand={this.confirmCommand}
					isAdmin={isAdmin}
					data={this.state.data}
					updateSubCommand={this.updateSubCommand}
					validSubCommand={this.validSubCommand}
					submit={this.onSubmit}
					parentLoad={this.load}
					ref={connectedComponent =>
						(this._detailsPopup = connectedComponent
							? connectedComponent.getWrappedInstance()
							: null)
					}
				/>

				<Card>
					<CardHeader>
						<h1>Mes comma</h1>
						<a
								href={`/help/#/configuration?id=gestion-des-membres`}
								target={"_blank"}
								className={"card-header-tooltip"}

							>
								?
							</a>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>

					<CardFooter>
						<div className="card-actions">
							{user.organisation && (
								<button
									onClick={() =>
										this.refs.importPopup.handleOpen()
									}
									className={"btn btn-gray btn-padded"}
								>
									{" "}
									Importer
								</button>
							)}
							
						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Commands);
