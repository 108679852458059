import React, { Component } from "react";
import { toast } from "react-toastify";
import FormMyInformations from "./FormMyInformations";
import { Row, Col } from "reactstrap";
import { SubmissionError } from "redux-form";
import {
	fetchMyAccountInformations,
	updateMyAccountInformations,
} from "../../services/iam";
import { bindActionCreators } from "redux";
import { setUser } from "../Authentication/redux";
import { connect } from "react-redux";
import ContactInformation from "./ContactInformations";

class MyInformations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			user: {},
		};

		this.submit = this.submit.bind(this);
	}

	async componentDidMount() {
		const data = await fetchMyAccountInformations();
		this.setState({ user: data, loaded: true });
	}

	async submit(values) {
		try {
			await updateMyAccountInformations(values);

			this.props.setUser({
				...this.props.user,
				...values,
			});
			toast.success("Enregistrement réussi");
		} catch (error) {
			throw new SubmissionError(error);
		}
	}
	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" sm="6">
						{this.state.loaded && (
							<FormMyInformations
								onSubmit={this.submit}
								initialValues={this.state.user}
							/>
						)}
					</Col>
				</Row>
				<ContactInformation user={this.props.user}/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

export const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ setUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyInformations);
