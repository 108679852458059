import React from "react";
import { Row, Col } from "reactstrap";
import Stock from "./stock";

const StockGestion = () => {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col>
						<h1>Gestion des stocks tissus</h1>
                        <Stock/>
					</Col>
				</Row>
			</div>
		);
	}

export default StockGestion;
