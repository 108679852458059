import React from "react"
import { Row, Label } from "reactstrap"

const ShippingInfo = (props) => {

    let deliveryAddress
    console.log(props)
    if(props.isSubCo === false){
        deliveryAddress = props.selectedData.subCommands[0].deliveryAddress
    }else{
        deliveryAddress = props.selectedData.deliveryAddress
    }
    return(
        <>
            <div className="widthInfo">
                <div>{`Adresse de livraison : ${deliveryAddress}`}</div>
                <div>{props.contact ? props.contact.email ? `Email client : ${props.contact.email}` : "Aucun email" : null}</div>
                <div>{props.contact ? props.contact.phone ? `Téléphone client : ${props.contact.phone}` : "Aucun numéro de téléphone" : null}</div>
            </div>
            <Row>
                <Label className="shippingLabel">
                    Entreprise de livraison :
                </Label>
                <div>
                    {props.selectedData.deliverName ? props.selectedData.deliverName : "Aucune information"}
                </div>
            </Row>
            <Row>
                <Label className="shippingLabel">
                    Numéro de suivi : 
                </Label>
                <div>
                    {props.selectedData.trackingNumber ? props.selectedData.trackingNumber : "Aucune information"}
                </div>
            </Row>
            <Row>
                <Label className="shippingLabel">
                    Poid du colis : 
                </Label>
                <div>
                    {props.selectedData.deliverWeight ? props.selectedData.deliverWeight : "Aucune information"}
                </div>
            </Row>
            <Row>
                <Label className="shippingLabel">
                    Date d'expedition : 
                </Label>
                <div>
                    {props.selectedData.deliverDate ? props.selectedData.deliverDate : "Aucune information"}
                </div>
            </Row>
        </>
    )
}

export default ShippingInfo;