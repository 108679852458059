import React, { Fragment } from "react";
import {
	Col,
	FormGroup,
	Label,
	Input,
	FormFeedback,
	FormText,
} from "reactstrap";

class Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = { value: "" };
	}

	handleChange = (event) => {
		const { afterUpdate } = this.props;
		this.setState({ value: event.target.value }, () => {
			afterUpdate(this.state.value);
		});
	};

	render() {
		const { options, value, isDisabled, onlySelect } = this.props;
		const currentOpt =
			value > 10
				? options.find((opt) => opt.value === value)
				: { label: "En attente" };
		if (onlySelect) {
			return (
				<select
					style={{ maxWidth: "100%" }}
					value={value}
					onChange={this.handleChange}
				>
					{options.map((opt, index) => (
						<option key={`${opt.label}_${index}`} value={opt.value}>
							{opt.label}
						</option>
					))}
				</select>
			);
		} else {
			return (
				<FormGroup row>
					<Col lg={{ size: 12, offset: 2 }}>
						{!isDisabled ? (
							<select
								style={{ maxWidth: "100%" }}
								value={value}
								onChange={this.handleChange}
							>
								{options.map((opt, index) => (
									<option
										key={`${opt.label}_${index}`}
										value={opt.value}
									>
										{opt.label}
									</option>
								))}
							</select>
						) : (
							<strong>{currentOpt.label}</strong>
						)}
					</Col>
				</FormGroup>
			);
		}
	}
}

export default Select;
