import { result } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import {
  createProduct,
  deleteProduct,
  fetchProducts,
  updateProduct,
  deleteManufacture,
  updateManufacture,
  createManufacture,
  createConfection,
  deleteConfection,
  fetchConfection,
  updateConfection,
  createPrices,
  updatePrices,
  deletePrices,
  updateFabrics,
  createFabrics,
  deleteFabrics,
  fetchPrices,
  updateCustomPrices
} from "../../services/pim";

const useProductService = ({ selectedData, toggle, type, inputProductValue, selectedProductValue}) => {
  const [pagination, setPagination] = useState();
  const [data, setData] = useState([]);
  const [customPrices, setCustomPrices] = useState([]);

  const type2 = type;

  const loadData = async (pageNumber, limit = 50, type) => {
    
    let query
    if(inputProductValue){
      if(!pageNumber){
        pageNumber = 1
      }
      query = {
        $skip: (pageNumber - 1) * limit,
        $limit: limit,
        type: type2,
        $or: [
          { reference: { $like: `%${inputProductValue}%` }},
        ],
      };
    }else{
      if(!pageNumber){
        pageNumber = 1
      }
      query = {
        $skip: (pageNumber - 1) * limit,
        $limit: limit,
        type: type2,
      };
    }
    const { data, ...paginationParams } = await fetchProducts(query);
    setData(data);
    
    setPagination(paginationParams);
  };

  useEffect(() =>{
    loadData()
  }, [selectedProductValue, inputProductValue])

  useEffect(() => {
    if(selectedData){
      const query = {
        productId: selectedData.id,
        organisationId: {
          $ne: true
        }
      }
      loadCustomerPrices(query)
    } else {
      setCustomPrices()
    }

  }, [selectedData])

  const loadCustomerPrices = async (query) => {
    const results = await fetchPrices(query)
    setCustomPrices(results.data)
  }

  const onDelete = async (product) => {
    try {
      const confirmed = window.confirm(
        `Êtes vous sûr de vouloir supprimer le produit ${product.reference} ?`
      );
      if (confirmed) {
        if (type2 == 100) {
          await deleteProduct(product.id);
          await deleteFabrics(product.fabric.id);
        } else if (type2 ==200){
          const confection = await fetchConfection({
            manufactureId: product.manufactureId,
          });
          const confectionId = confection.data[0].id;

          await deleteProduct(product.id);
          await deleteConfection(confectionId);
          await deleteManufacture(product.manufactureId);
        } else if (type2 == 400){
          await deleteProduct(product.id);
        }

        await deletePrices(product.myditexPrices.id);
        loadData(1);
        toast.success("Le produit a été supprimé avec succès");
      }
    } catch (error) {
      toast.error("Erreur");
    }
  };

  const onSubmit = async (newData) => {
    const payload = {
      productId:newData.id,
      customPrices:newData.customPrices
    }

    try{
      await updateCustomPrices(payload)
    }catch(error){
      console.log(error)
    }

    let payloadFabric, payloadProducts, dimensions, payloadPrices;

    if (newData.dimensions === null || newData.dimensions === undefined) {
    } else {
      dimensions = {
        width: newData.dimensions.width,
        height: newData.dimensions.height,
      };
    }

    if (type2 == 100) {
      const { fabric } = newData;
      //payload product tissu
      payloadProducts = {
        description: newData.description ? newData.description : "",
        genericReference: fabric.ref,
        type: type2,
        emploisTissu: 1,
      };
      
      payloadFabric = {
        ref: fabric.ref,
        name: fabric.name,
        stock: fabric.stock,
        stockAlert: fabric.stockAlert,
        type: fabric.type,
      };

    } else if(type2 == 200){
      //payload product confection
      payloadProducts = {
        description: newData.description ? newData.description : "",
        genericReference: newData.genericReference,
        type: type2,
        accessoryId: newData.accessory ? newData.accessory.value ? newData.accessory.value : null : null,
        dimensions: dimensions,
        emploisTissu:
          typeof newData.emploisTissu === "string"
            ? newData.emploisTissu.replace(/,/g, ".")
            : newData.emploisTissu,
        label: newData.manufacture.name,
        refTissu: newData.tissu,
        pennyCode: newData.pennyCode ? newData.pennyCode : null
      };
    } else if (type === 400){
      payloadProducts = {
        description: newData.description ? newData.description : "",
        genericReference: newData.genericReference,
        type: type2,
        emploisTissu:
          typeof newData.emploisTissu === "string"
            ? newData.emploisTissu.replace(/,/g, ".")
            : newData.emploisTissu,
        label: newData.label,
        fabricId: newData.fabricId.value,
        organisationId: newData.organisationId.value
      };
    }
    
    payloadPrices = {
      priceType: newData.myditexPrices.priceType
        ? newData.myditexPrices.priceType
        : "price",
      price1: newData.myditexPrices.price1
        ? typeof newData.myditexPrices.price1 === "string"?
        newData.myditexPrices.price1.replace(/,/g, ".")
        : newData.myditexPrices.price1
        : null,
      price2: newData.myditexPrices.price2
        ? typeof newData.myditexPrices.price2 === "string"?
        newData.myditexPrices.price2.replace(/,/g, ".")
        : newData.myditexPrices.price2
        : null,
      price3: newData.myditexPrices.price3
        ? typeof newData.myditexPrices.price3 === "string"?
        newData.myditexPrices.price3.replace(/,/g, ".")
        : newData.myditexPrices.price3
        : null,
      price4: newData.myditexPrices.price4
        ? typeof newData.myditexPrices.price4 === "string"?
        newData.myditexPrices.price4.replace(/,/g, ".")
        : newData.myditexPrices.price4
        : null,
      price5: newData.myditexPrices.price5
        ? typeof newData.myditexPrices.price5 === "string"?
        newData.myditexPrices.price5.replace(/,/g, ".")
        : newData.myditexPrices.price5
        : null,
      price6: newData.myditexPrices.price6
        ? typeof newData.myditexPrices.price6 === "string"?
        newData.myditexPrices.price6.replace(/,/g, ".")
        : newData.myditexPrices.price6
        : null,
      price7: newData.myditexPrices.price7
        ? typeof newData.myditexPrices.price7 === "string"?
        newData.myditexPrices.price7.replace(/,/g, ".")
        : newData.myditexPrices.price7
        : null,
      price8: newData.myditexPrices.price8
        ? typeof newData.myditexPrices.price8 === "string"?
        newData.myditexPrices.price8.replace(/,/g, ".")
        : newData.myditexPrices.price8
        : null,
    };

    try {
      if (selectedData) {
        if(selectedData.myditexPrices === null){
          await createPrices({
            ...payloadPrices,
            productId: selectedData.id,
            organisationId: null,
          });
        }else{
          await updatePrices(selectedData.myditexPrices.id, payloadPrices);
        }

        if (type2 == 100) {
          // product fabric
          await updateFabrics(selectedData.fabric.id, payloadFabric);
          const updt = await updateProduct(selectedData.id, payloadProducts);
        } else if (type2 == 200){
          const confection = await fetchConfection({
            manufactureId: newData.manufacture.id,
          });
          const confectionId = confection.data[0].id;

          await updateManufacture(selectedData.manufactureId, {
            dimension: newData.dimensions
              ? `${dimensions.width}x${dimensions.height}`
              : "",
            urlPatron: newData.manufacture.urlPatron,
            name: newData.manufacture.name,
          });
          await updateProduct(selectedData.id, payloadProducts);
          await updateConfection(confectionId, {
            fabricId: newData.tissu.value,
            manufactureId: newData.manufacture.id,
            quantity: 1,
          });
        } else if (type == 400){
          await updateProduct(selectedData.id, payloadProducts)
        }
      } else {
        let newManufacture, newProduct;

        if (type2 == 100) {
          const newFabric = await createFabrics(payloadFabric);

          newProduct = await createProduct({
            ...payloadProducts,
            fabricId: newFabric.id,
            genericReference: newFabric.ref,
          });
        } else if (type2 == 200) {
          newManufacture = await createManufacture({
            dimension: newData.dimensions
              ? `${dimensions.width}x${dimensions.height}`
              : "",
            urlPatron: newData.manufacture.urlPatron,
            name: newData.manufacture.name,
          });

          newProduct = await createProduct({
            ...payloadProducts,
            manufactureId: newManufacture.id,
          });

          await createConfection({
            fabricId: newData.tissu.value,
            manufactureId: newManufacture.id,
            quantity: 1,
          });
        } else if (type2 == 400){
          newProduct = await createProduct(payloadProducts)
        }

        await createPrices({
          ...payloadPrices,
          productId: newProduct.id,
          organisationId: null,
        });
      }

      loadData(1);
      toggle();
      toast.success("Enregistrement réussi");
      window.location.reload()
    } catch (error) {
      console.log({ error });
      toast.error("Une erreur est survenue")
      throw new SubmissionError(error);
    }
  };

  return {
    pagination,
    data,
    loadData,
    customPrices,
    onDelete,
    onSubmit,
  };
};

export default useProductService;
