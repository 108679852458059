import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneExcel = ({ onFileAccepted }) => {

    const onDrop = useCallback((acceptedFiles) => {
        // Appeler la fonction de callback avec les fichiers acceptés
        onFileAccepted(acceptedFiles);
      }, [onFileAccepted]);
    
      const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls']
          }
        });
    
      return (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Déposez les fichiers ici...</p> :
              <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
          }
        </div>
      );
}

export default DropzoneExcel

