import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import * as commandService from "../../services/command";
import { Line } from "rc-progress";
import { CommandColor } from "../../services/commandStatus/commandStatusEnum"
class CommandsInProgress extends Component {
	state = {
		loaded: false,
		data: [],
	};

	subCommandsComponent = (parentRow) => {
		const subData = [...parentRow.commandItem];
		const subColumns = [
			{
				Header:"Produit",
				accessor:"label",
				minWidth: 180,
				filterable: false,
			},
			{
				Header:"Quantité",
				accessor:"quantity",
				minWidth: 180,
				filterable: false,
			},
			{
				Header:"Statut",
				accessor:"statusName",
				minWidth: 180,
				filterable: false,
				Cell: row => (
					<span className={`label status-${row.original.currentStatus}`}>
						{row.value}
					</span>
				),
			}
		]
		return <WHTable
			data={subData}
			columns={subColumns}
			defaultPageSize={parentRow.commandItem.length}
			showPagination={false}
		/>
	}

	columns = () => {
		const { commandStatus } = this.props;
		return [
			{
				Header: "id",
				accessor: "id",
				maxWidth: 100,
				filterable: false,

			},
			{
				Header: "Statut",
				accessor:"statusName",
				maxWidth: 200,
				filterable: false,
				Cell: row => (
					<span className={`label status-${row.original.currentStatus}`}>
						{row.value}
					</span>
				),
			},
			{
				Header: "Avancement",
				accessor: "progress",
				minWidth: 180,
				filterable: false,
				Cell: (row) =>  {
					const cmdIndex = commandStatus.findIndex((stat) => stat.value === row.original.currentStatus) * 100
					const color = CommandColor(row.original.currentStatus)
					return <Line
					percent={
						(cmdIndex / (commandStatus.length - 1))
					}
					strokeColor={color}
				/>}
			}
		];
	};

	load = async state => {
		this.setState({
			loaded: false,
		});

		try {
			const data = await commandService.search(state);

			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
					});
				},
			);
		} finally {
			this.setState({ loaded: true });
		}
	};

	render() {
		const { data, loaded, pages } = this.state;
		return (
			<WHTable
				data={data}
				pages={pages}
				columns={this.columns()}
				filterable={false}
				onFetchData={this.load}
				loading={!loaded}
				subComponent={(row) => this.subCommandsComponent(row.original)}
			/>
		);
	}
}

export default CommandsInProgress;
