import { fetch } from "../../utils/fetch.old";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/users";

export const search = (data) => {
	let query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const options = () => {
	let lst = [];
	let filters = { filtered: [{ id: "pagination", value: false }] };
	search(filters).then((data) => {
		data["hydra:member"].map((el) =>
			lst.push({ name: el.email, value: el["@id"] })
		);
	});

	return lst;
};

export const view = (id) => fetch(`${configApi.url}${endPoints}/${id}`, "get");

export const create = (data) =>
	fetch(`${configApi.url}${endPoints}`, "post", data);

export const update = (data) =>
	fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);

export const remove = (id) =>
	fetch(`${configApi.url}${endPoints}/${id}`, "delete");

export const sendNewPassword = (id) =>
	fetch(`${configApi.url}/api/admin/users/send-password/${id}`, "get");

export const myInformations = (id, data) =>
	fetch(`${configApi.url}${endPoints}/${id}/my-informations`, "post", data);

export const myPassword = (id, data) =>
	fetch(`${configApi.url}${endPoints}/${id}/my-password`, "post", data);
