import React, { Component } from "react";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change, Form } from "redux-form";
import FieldCheckbox from "../../components/Form/whFields/FieldCheckbox";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { WhFieldTxt } from "../../components/Form/whFields";

class ShippingDelayForm extends Component {
    
    render() {
        const { error, handleSubmit, submitting, roles, initialValues, currentValues } = this.props;

        return(
            <Form onSubmit={handleSubmit} className="form-horizontal">
                <Field
                    label="Delais de livraison :"
                    name="delayText"
                    component={WhFieldTxt}
                    type="text"
                />
                <LaddaButton
                    className="btn btn-gray btn-padded btn-ladda submitParam"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    Enregistrer
                </LaddaButton>
            </Form>
        )
    }
}

const formName = "ShippingDelayForm"


ShippingDelayForm = reduxForm({
  form: formName
})(ShippingDelayForm);

const mapStateToProps = state => {
  return {
	currentValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
}};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (field, value) => dispatch(change(formName, field, value))
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(ShippingDelayForm);