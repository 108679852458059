import React, { useEffect, useState } from "react"
import BannerForm from "./bannerForm"
import { createBanner, fetchBanner } from "../../services/crm";
import { toast } from "react-toastify";
import { Card } from "reactstrap"

const Banner = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData()
    },[])

    const loadData = async () => {
        setLoading(true);
        const newData = await fetchBanner()
        setData(newData)
        setLoading(false);
    }

    const handleSubmit = async (props) => {
        if(props.enabled === undefined){
            
        }else{
            const query = {
                enabled: props.enabled,
                message:props.message
            }
            try{
                await createBanner(query)
                toast.success("Mise à jour éffectuée")
            }catch(error){
                toast.error("Une erreur est survenue")
            }
        }
    }

    return(
        <>
            <h1>Bannière d'information</h1>
            <Card className="bannerPageCard">
                {!loading ?
                    <BannerForm onSubmit={handleSubmit} initialValues={
                        data
                    }/>
                :
                    null
                }
            </Card>
        </>
    ) 
}

export default Banner