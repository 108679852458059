import APICONFIG from "../constants/ApiConfig";
import fetch from "../utils/fetch";

const endPoint = APICONFIG.IAM;

const endPoints = {
  authentication: `${endPoint}/authentication`,
  myAccount: `${endPoint}/my-account`,
  updatePassword: `${endPoint}/update-password`,
  users: `${endPoint}/users`,
  take: `${endPoint}/take`,
  lostPassword: `${endPoint}/lost-password`,
  sendResetPasswordLink: `${endPoint}/send-reset-password-link`,
  resetPassword: `${endPoint}/reset-password`,
  createPassword: `${endPoint}/create-password`,
};

export const login = (data) => fetch(endPoints.authentication, "post", data);
//Account
export const fetchMyAccountInformations = () =>
  fetch(endPoints.myAccount, "get");
export const updateMyAccountInformations = (data) =>
  fetch(endPoints.myAccount, "post", data);

//Password
export const lostPassword = (payload) =>
  fetch(endPoints.lostPassword, "post", payload);
export const sendResetPasswordLink = (id) =>
  fetch(`${endPoints.sendResetPasswordLink}/${id}`, "get");
export const resetPassword = (payload) =>
  fetch(endPoints.resetPassword, "post", payload);
export const createPassword = (payload) =>
  fetch(endPoints.createPassword, "post", payload);
export const updatePassword = (password, newPassword) =>
  fetch(endPoints.updatePassword, "post", { password, newPassword });

//Users
export const fetchUsers = (query) => fetch(endPoints.users, "get", {}, query);
export const getUserById = (id) => fetch(`${endPoints.users}/${id}`, "get");
export const createUser = (payload) => fetch(endPoints.users, "post", payload);
export const updateUser = (id, payload) =>
  fetch(`${endPoints.users}/${id}`, "patch", payload);
export const deleteUser = (id) => fetch(`${endPoints.users}/${id}`, "delete");

export const fetchToken = (id) => fetch(`${endPoints.take}/${id}`, "get");
