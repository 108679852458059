

export const CommandColor = (num) => {return {
	100: "#CFD8DC",
	200: "#607D8B",
	300: "#0288D1",
	350: "#FFEB3B",
	400: "#FFEB3B",
	450: "#FBC02D",
	500: "#FBC02D",
	550: "#009688",
	600: "#009688",
	650: "#8BC34A",
	700: "#8BC34A"
}[num]
}


