import React, { useState } from 'react'
import { Field } from 'redux-form'
import { fetchOrganisations } from '../../services/crm';
import { Button, Row, Col, Label, Collapse } from "reactstrap";
import {
  WhFieldSelect,
  WhFieldTxt,
  WhFieldSelectAsync,
} from "../../components/Form/whFields";
import { toast } from "react-toastify";
import { numeric, required, validateOrganisation } from "../../components/Form/whFields/WhValidator";

const RenderPrices = ({ currentValues, fields, ...props}) => {

  const [openedIndexes, setOpenedIndexes] = useState([])
  
  const loadRefOptions = (input) => {

    return fetchOrganisations({
      $or: [
        { name: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.name}`,
      }));

      return { options };
    });
  }
  
  const handleClickButton = () => {
    fields.push({})
  }


  return(
    <>
    <div className="formDiv">
      {fields.map((price, index) => {
        let active = openedIndexes.includes(index)
        const handleToggle = () => {
          const newOpenedIndexes = [...openedIndexes]

          const existingElementIndex = openedIndexes.indexOf(index)
          if (existingElementIndex !== -1) {
            newOpenedIndexes.splice(existingElementIndex, 1)
          } else {
            newOpenedIndexes.push(index)
          }

          setOpenedIndexes(newOpenedIndexes)
        }

        const handleDelete = () => {
    
          try {
            const confirmed = window.confirm(
              `Êtes vous sûr de vouloir supprimer les prix pour ${currentValues.customPrices[index].label} ?`
            );
            if (confirmed) {
              fields.remove(index)
            }
          } catch (error) {
            toast.error("Erreur");
          }
        }

        const priceTypeOptions = [
          { value: "coef", name: "coef" },
          { value: "price", name: "price" },
        ];

        return(
          <>
            <Row>
              <Col xs="12">
                <div className="accordion">
                  <div className="accordionDiv" >
                    <div className="dropButton">
                      <i onClick={handleToggle} className={"fa fa-edit icon"} />
                      <i onClick={handleDelete} className={"fa fa-trash icon"} />
                    </div>
                    <Label className="accordionLabel" onClick={handleToggle}>Tarif {currentValues.customPrices && currentValues.customPrices[index] ? currentValues.customPrices[index].label ? currentValues.customPrices[index].label  : null : null}</Label>
                  </div>
                    <Collapse 
                      className="marg"
                      isOpen={active}>
                      <Field
                        label="Client : "
                        name={`${price}.organisationId`}
                        component={WhFieldSelectAsync}
                        multi={false}
                        type="select"
                        loadOptions={loadRefOptions}
                        validate={[required, validateOrganisation]}
                      />
                      <Field
                        label="Type de prix: "
                        name={`${price}.priceType`}
                        component={WhFieldSelect}
                        type="select"
                        options={priceTypeOptions}
                        multi={false}
                        className="form-control"
                        validate={[required]}
                      />
                      <Field
                        label="0 à 4 unités : "
                        name={`${price}.price1`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="5 à 9 unités : "
                        name={`${price}.price2`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="10 à 19 unités : "
                        name={`${price}.price3`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="20 à 49 unités : "
                        name={`${price}.price4`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="50 à 99 unités : "
                        name={`${price}.price5`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="100 à 199 unités : "
                        name={`${price}.price6`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="200 à 500 unités : "
                        name={`${price}.price7`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                      <Field
                        label="plus de 500 unités : "
                        name={`${price}.price8`}
                        component={WhFieldTxt}
                        type="text"
                        className="form-control"
                        validate={[required, numeric]}
                      />
                  </Collapse>
                </div>
              </Col>
            </Row>
          </>
        )
      })
      }
      <Button type="button" color="blue" className="addPriceButton" onClick={handleClickButton}>
        Ajouter un prix
      </Button>
    </div>
    </>

)}

export default RenderPrices;