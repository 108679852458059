import React, { useEffect, useState } from "react";
import { Button, Input, Label } from "reactstrap"
import { eraseBasket, getBasket, setBasket, delBasket, newBasket, updateBasket } from "../../../reducers/basket";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import useModal from '../../../hooks/useModal';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DeliveryForm from "./deliveryForm";
import { getUser } from "../../Authentication/redux";
import { createOrder, fetchShippingDelay, fetchSubCommands } from "../../../services/crm";
import { toast } from "react-toastify";
import confectionPicto from "../../../assets/picto/confectionPicto.png";
import tissuPicto from "../../../assets/picto/tissuPicto.png";
import Cgv from "./cgv";
import CommandConfirmation from "./commandConfirmation";
import Loading from "../../../components/Loading";
import { fetchPrices } from "../../../services/pim";
import ExterneRefForm from "./externeRefForm";
import CommandInfoFile from "./commandInfoFile";
import WantedDate from "./wantedDate";

const Basket = ({basket, onSetBasket, onUpdateBasket, onDelBasket, onEraseBasket, user, props}) => {

    const [totalItems, setTotalItems] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [totalBasket, setTotalBasket] = useState(totalItems + shipping);
    const [basketItems, setBasketItems] = useState(basket);
    const { toggle, active } = useModal();
    const [data, setData] = useState();
    const [delay, setDelay] = useState();
    const [validationIndex, setValidationIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const [orgaPrices, setOrgaPrices] = useState()
    const [clientRef, setClientRef] = useState();
    const [mediaInfoId, setMediaInfoId] = useState();
    const [wishDate, setWishDate] = useState(null);

    let list, totalItem

    let Navigate = useNavigate();

    useEffect(() => {
        loadOrgaPrices()
        loadData()
        setTotalPrices()
    }, [basket])

    const fetchDelay = async () => {
        const newDelay = await fetchShippingDelay()
        setDelay(newDelay.delayText)
    }

    const loadOrgaPrices = async () => {
        const orgaId = user.organisationId
    
        const query = {
            organisationId:orgaId
        }
    
        const pricesOrga = await fetchPrices(query)

        setOrgaPrices(pricesOrga.data)
    }

    const setTotalPrices = () => {
        let total = 0

        basketItems.map((item, indexList) => {

            if(item.item.product){
                total = parseFloat(total) + parseFloat(item.item.product.priceB2B * item.item.quantity)
            }else{
                const fileInfo = item.item.fichier[0]
                let qty = 0
                    fileInfo.map((file, index) =>{
                        qty = parseFloat(qty) + parseFloat(file.inputValue)
                    })
                item.item.fichier[0].map((file, index) => {
    
                    if(item.item.accessory){
                        const totalAccessory = parseFloat(item.item.accessory.nbrItem * item.item.article[0].accessory.price)
                        total = parseFloat(total) + parseFloat(totalAccessory)
                    }
    
                    
                    let itemPrice
                    if(item.item.article === 'none'){
                        switch (true) {
                            case qty >= 0 && qty <= 4.9:
                                itemPrice = item.item.tissu.prix.price1 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 5.0 && qty <= 9.9:
                                itemPrice = item.item.tissu.prix.price2 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 10.0 && qty <= 19.9:
                                itemPrice = item.item.tissu.prix.price3 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 20.0 && qty <= 49.9:
                                itemPrice = item.item.tissu.prix.price4 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 50.0 && qty <= 99.9:
                                itemPrice = item.item.tissu.prix.price5 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 100.0 && qty <= 199.9:
                                itemPrice = item.item.tissu.prix.price6 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 200.0 && qty < 500.9:
                                itemPrice = item.item.tissu.prix.price7 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 500.0:
        
                                itemPrice = item.item.tissu.prix.price8 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                              default:
                                  console.log("default");
                                }
                    }else{
                        switch (true) {
                            case qty >= 0 && qty <= 4.9:
                                itemPrice = item.item.article[0].prices.price1 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 5.0 && qty <= 9.9:
                                itemPrice = item.item.article[0].prices.price2 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 10.0 && qty <= 19.9:
                                itemPrice = item.item.article[0].prices.price3 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 20.0 && qty <= 49.9:
                                itemPrice = item.item.article[0].prices.price4 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 50.0 && qty <= 99.9:
                                itemPrice = item.item.article[0].prices.price5 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 100.0 && qty <= 199.9:
                                itemPrice = item.item.article[0].prices.price6 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 200.0 && qty < 500.9:
                                itemPrice = item.item.article[0].prices.price7 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                            case qty >= 500.0:
        
                                itemPrice = item.item.tissu.prix.price8 * file.inputValue
                                total = parseFloat(total) + parseFloat(itemPrice)
                              break;
                              default:
                                  console.log("default");
                                }
                    }
                })
            }
        })
        setTotalItems(total)
        setTotalBasket(total + shipping)
    }

    const loadData = async () => {
        const query = {
            organisationId: user.organisationId, 
        }

        const newData = await fetchSubCommands(query)

        const index = newData.data.length - 1
        setData(newData.data[index])
        fetchDelay()
    }
    
    const handleClientRef = (values) => {
        if(values.clientRef){
            setClientRef(values.clientRef)
        }
        setValidationIndex(1)
    }

    const handleWishDate = (values) => {
        if(values.date){
            const dateObject = new Date(values.date);
            setWishDate(dateObject)
        }
        setValidationIndex(4)
    }
    const handleSubmit = async (props) => {
        setLoading(true)
        setValidationIndex(5)
        const date = Date.now()
        const extRef = `ERP-${date}-${user.lastname}`
        const ref =  `${extRef}-command`
        let itemToOrder = []
        let newOrder

        basket.map(async (item, index) => {
            if(item.item.product){
                const id = item.item.product.id
                const isCatalogue = true
                const ref = item.item.product.ref
                const mediaPath = item.item.product.media
                const qty = item.item.quantity
                const customText = item.item.customText
                const customColor = item.item.color
                const customPicto = JSON.stringify(item.item.picto)
                const fabricsId = item.item.product.fabricsId
                itemToOrder.push({
                    isCatalogue,
                    id,
                    ref,
                    qty,
                    mediaPath,
                    customText,
                    customColor,
                    customPicto,
                    fabricsId
                })
            }else{
                const fileInfo = item.item.fichier[0]
                let totalQty = 0
                    fileInfo.map((file, index) =>{
                        totalQty = parseFloat(totalQty) + parseFloat(file.inputValue)
                    })
                if(item.item.article === 'none'){
                    const id = item.item.tissu.value
                    const ref = item.item.tissu.ref
                    item.item.fichier[0].map(async (file, index) => {
                        if(file.custom === false){
                            const mediaId = file.id
                            const qty = file.inputValue
                            const urlDropBox = file.url
                            const note = item.item.note ? item.item.note.props : null
                            itemToOrder.push({
                                id,
                                ref,
                                qty,
                                mediaId,
                                urlDropBox,
                                note,
                                totalQty
                            })
                        }
                    })
                }else{
                    const id = item.item.article[0].id
                    const ref = item.item.article[0].reference
                    if(item.item.accessory){
                        if(item.item.accessory.nbrItem !== 0 && item.item.accessory.nbrItem !== "0"){
                            //code pour create item accessoire dans la commande 
                            //voir pour le fichier ??? indispensable ? 
                            const qty = item.item.accessory.nbrItem
                            const note = null
                            const id = item.item.accessory.productAccessory.id
                            const ref = item.item.accessory.productAccessory.reference
                            itemToOrder.push({
                                id,
                                ref,
                                qty,
                                note,
                                totalQty
                            })
    
                        }
                    }
                    item.item.fichier[0].map(async (file, index) => {
                        if(file.custom === false){
                            const mediaId = file.id
                            const qty = file.inputValue
                            const urlDropBox = file.url
                            const note = item.item.note ? item.item.note.props : null
                            itemToOrder.push({
                                id,
                                ref,
                                qty,
                                mediaId,
                                urlDropBox,
                                note,
                                totalQty
                            })
                        }
                    })
                }
            }

            newOrder = {
                author:user.id,
                erp:true,
                internalRef: extRef,
                clientRef: clientRef ? clientRef : null,
                date: date,
                bill: props.billAddress,
                delivery: props.deliveryAddress,
                items:itemToOrder,
                infoMedia:mediaInfoId ? mediaInfoId : null,
                wishDate: wishDate ? wishDate : null
            }
        })

        createOrder(newOrder).then(() => onEraseBasket())
        setLoading(false)
    }

    const handleCgv = (props) => {
        toast.success("Conditions générales de ventes acceptées")
        setValidationIndex(3)
    }

    const handleSubmitBasket = () => {
        if(basket.length > 0){
            toggle()
        }else{
            toast.error("Votre panier est vide")
        }
    }

    const handleSubmitInfoFile = (props) => {
        setMediaInfoId(props)
        setValidationIndex(2)
    }

    const handleDeleteBasket = () => {
        try{
            const confirmed = window.confirm(
                `Êtes vous sûr de vouloir supprimer le panier ?`
              );
        
              if (confirmed) {       
                onEraseBasket()
                window.location.reload()
                toast.success("La commande est annulée");

              }
        }catch(error){
            console.log(error)
        }
    };

    const handleDelete = (id) => {
        onDelBasket(id)
        window.location.reload()
    }

    const handleAccessory = (props, indexList) => {
        if(props.target.value >=0){
            basket[indexList].item.accessory.nbrItem = props.target.value
        }
        const newBasket = basket[indexList]
        onUpdateBasket(newBasket, indexList)
    }

    if(!basketItems){
        totalItem = 0

    }else{
        list = basketItems.map((item, indexList) => {
            if(!item.item.product){
                let isTissu = false
                let totalInput = 0;
                let isAccessoire, prix
    
                if(item.item.article === 'none'){
                    //set prices sur tissu au ML 
                    isTissu = true
                    prix = item.item.tissu.prix.price1
                    const fileInfo = item.item.fichier[0]
    
                    let qty = 0
                    fileInfo.map((file, index) =>{
                        qty = parseFloat(qty) + parseFloat(file.inputValue)
                    })
    
                    switch (true) {
                        case qty >= 0 && qty <= 4.9:
                            prix = item.item.tissu.prix.price1
                          break;
                        case qty >= 5 && qty <= 9.9:
                            prix = item.item.tissu.prix.price2
                          break;
                        case qty >= 10 && qty <= 19.9:
                            prix = item.item.tissu.prix.price3
                          break;
                        case qty >= 20 && qty <= 49.9:
                            prix = item.item.tissu.prix.price4
                          break;
                        case qty >= 50 && qty <= 99.9:
                            prix = item.item.tissu.prix.price5
                          break;
                        case qty >= 100 && qty <= 199.9:
                            prix = item.item.tissu.prix.price6
                          break;
                        case qty >= 200 && qty < 500.9:
                            prix = item.item.tissu.prix.price7
                          break;
                        case qty >= 501:
                            prix = item.item.tissu.prix.price8
                          break;
    
                        default:
                          console.log("default");
                      }
                }else{
                    //set prices sur confection
                    if(item.item.accessory){
                        isAccessoire = true
                    }
                    
                    prix = item.item.article[0].prices.price1
                    const fileInfo = item.item.fichier[0]
                    
                    let qty = 0
                    fileInfo.map((file, index) =>{
                        qty = parseFloat(qty) + parseFloat(file.inputValue)
                    })
                    
                    switch (true) {
                        case qty >= 0 && qty <= 4.9:
                            prix = item.item.article[0].prices.price1
                          break;
                        case qty >= 5 && qty <= 9.9:
                            prix = item.item.article[0].prices.price2
                          break;
                        case qty >= 10 && qty <= 19.9:
                            prix = item.item.article[0].prices.price3
                          break;
                        case qty >= 20 && qty <= 49.9:
                            prix = item.item.article[0].prices.price4
                          break;
                        case qty >= 50 && qty <= 99.9:
                            prix = item.item.article[0].prices.price5
                          break;
                        case qty >= 100 && qty <= 199.9:
                            prix = item.item.article[0].prices.price6
                          break;
                        case qty >= 200 && qty < 500.9:
                            prix = item.item.article[0].prices.price7
                          break;
                        case qty >= 501:
                            prix = item.item.article[0].prices.price8
                          break;
    
                        default:
                          console.log("default");
                      }
                }
                
                const filesList = item.item.fichier[0].map((file, index) => {
                    totalInput = parseFloat(totalInput) + parseFloat(file.inputValue)
                    
                    let isTooLong = false
                    if(file.path.length > 30){
                        isTooLong = true 
                    }
    
                const handleChangeInput = (event, indexList) => {
                    if(event.target.value >=1){
                        file.inputValue = event.target.value
                    }
                    const newBasket = basket[indexList]
                    onUpdateBasket(newBasket, indexList)
                }
                    return(
                        <div>
                            <div className='containerRowBasket'>
                            <div className="fileNameBasket" key={file.path}>{isTooLong ? `${file.path.substring(0,25)} ...` : file.path}</div>
                            <div className="containerInputBasket">
                                {isTissu ? 
                                    <Input value={file.inputValue} className="inputBasket" onChange={(event) => handleChangeInput(event, indexList)} step="0.10" type="number"></Input>
                                :
                                    <Input value={file.inputValue} className="inputBasket" onChange={(event) => handleChangeInput(event, indexList)} step="1" type="number"></Input>
                                }
                                {isTissu ? <div>Ml</div> : <div>pièce(s)</div>}
                            </div>
                            </div>
                        </div>
                    )
                })
    
                return(
                    <>
                        <div className="basketItemBox">
                            <div className="basketRow">
    
                                <div>
                                    {isTissu ? 
                                        <img src={tissuPicto} className="basketPicto"></img>
                                    :
                                        <img src={confectionPicto} className="basketPicto"></img>
                                    }
                                </div>
    
                                <div className="containerColBasket">
                                    <div className="basketFontItem">
                                        {isTissu ? "Tissu au ML" : item.item.article[0].description}
                                    </div>
                                    <div className="basketFontRef">
                                        {isTissu ? item.item.tissu.label : item.item.article[0].reference}
                                    </div>
                                </div>
    
                                <div className="fileIconBasket">
                                    <i class='fa fa-file-pdf-o'></i>
                                </div>
    
                                <div className="fileBasketContainer">
                                    {filesList}
                                </div>
    
                                <div className="basketFontRef">
                                    {parseFloat(prix * totalInput).toFixed(2)} €
                                </div>
                                <div className="basketIconTrash">
                                    <i onClick={() => handleDelete(indexList)} class='fa fa-trash'></i>
                                </div>
                            </div>
                            <div>
                                 {isAccessoire ? 
                                        <div className="basketAccessory">
                                            <Label>Accessoire : {item.item.article[0].accessory.label}</Label>
                                            <Input value={item.item.accessory.nbrItem} onChange={(event) => handleAccessory(event, indexList)} type="number" className="inputBasketAccessory">
                                            </Input>
                                            <Label>pièce(s)</Label>
                                            <Label className="basketFontRefAccessory">{parseFloat(item.item.accessory.nbrItem * item.item.article[0].accessory.price).toFixed(2)} €</Label>
                                        </div> 
                                    : null}
                            </div>
                        </div>
                    </>
                )
            }else{
                const handleQuantityChange = (event) => {
                    item.item.quantity = event.target.value
                    const newBasket = basket[indexList]
                    onUpdateBasket(newBasket, indexList)
                };
            
                const handleAdd = () => {
                    const newQuantity = item.item.quantity + 1
                    item.item.quantity = newQuantity
                    const newBasket = basket[indexList]
                    onUpdateBasket(newBasket, indexList)
                }
            
                const handleRemove = () => {
                    const newQuantity = item.item.quantity - 1
                    if(newQuantity >= 1){
                        item.item.quantity = newQuantity
                    }
                    const newBasket = basket[indexList]
                    onUpdateBasket(newBasket, indexList)
                }
            
                return(
                    <>
                        <div className="basketItemBox">
                            <div className="basketRow">
    
                                <div>
                                    <img className="visualBasketProduct" src={item.item.product.visual}></img>
                                </div>
                                <div className="containerColBasket">
                                    <div className="basketFontItem">
                                        {item.item.product.type}
                                    </div>
                                    <div className="basketFontItemModel">
                                        {item.item.product.model}
                                    </div>
                                    <div className="basketFontProductRef">
                                        {item.item.product.ref}
                                    </div>
                                    {item.item.customText ? 
                                        <div className="basketRowCustom"> 
                                                <div>Texte personalisé :  </div>
                                                <div className="basketCustomText">{item.item.customText}</div>
                                        </div>
                                    :null}
                                    {item.item.color ? 
                                            <div className="basketRowCustom"> 
                                                <div>Couleur choisie : </div>
                                                <div style={{backgroundColor:item.item.color}} className="colorBasket"> </div>
                                            </div>
                                            
                                        : null}
                                    {item.item.picto ? 
                                        <div className="basketRow"> 
                                            <div>Image choisie : </div>
                                            <img className="pictoBasket" src={item.item.picto.value}></img>
                                        </div>
                                        
                                    : null}
                                </div>
                                <div className="containerColBasket">
                                   <div>
                                        <div className='rowProduct'>
                                            <Button className="inputButton" onClick={() => handleRemove()} > - </Button>
                                            <Input  className="inputNumber" onChange={() => handleQuantityChange()} value={item.item.quantity} type="number" step="1"></Input>
                                            <div>Pièce(s)</div>
                                            <Button className="inputButton" onClick={() => handleAdd()} > + </Button>
                                        </div>
                                   </div>
                                </div>
                                <div className="containerColBasket">
                                   <div className="basketFontRef">
                                        {`${parseFloat(item.item.quantity * item.item.product.priceB2B)} €`}
                                   </div>
                                </div>
                                <div className="basketIconTrash">
                                    <i onClick={() => handleDelete(indexList)} class='fa fa-trash'></i>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        })
    }
    return(
        <>
            <h1>Panier</h1>
            <h2 className="basketListTitle">Mon panier contient {totalItem === 0 ? totalItem : basketItems.length } lignes articles :</h2>
            <div className="containerRowGeneralBasket">
                <div className="containerCol">
                    {list}
                    <div className="basketItemBox basketFontRef">
                        SOUS-TOTAL : {totalItems.toFixed(2)} €
                    </div>
                    <div className="basketItemBox">
                        {delay ? `${delay}` : "Aucune information de livraison"}
                    </div>
                </div>
                <div className="basketResume">
                    <div className="basketBox">
                        <div className="basketFontTotal">Total</div>
                        <div className="basketFont"><i class="fa fa-shopping-basket basketIcon"></i>{totalItems.toFixed(2)} €</div>
                        {/* <div className="basketFont" ><i class="fas fa-shipping-fast basketIcon"></i>{shipping} € </div> */}
                        <div className="basketDash"></div>
                        <div className="basketFont"> {totalBasket.toFixed(2)} €</div>
                        <Button className="basketButton" onClick={() => Navigate('/product-selection')} color="orange">Compléter ma commande</Button>                                    
                        <Button className="basketButton" onClick={() => handleDeleteBasket()} color="red">Annuler ma commande</Button>                  
                        <Button className="basketButton" onClick={() => handleSubmitBasket()} color="teal">Valider ma commande</Button>
                    </div>
                </div>

            </div>
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                    {validationIndex === 0 ? <h1 className="modalTitle">Possèdez vous une reference client pour cette commande ?</h1> : null}
                    {validationIndex === 1 ? <h1 className="modalTitle">Voulez vous nous transmettre un fichier d'information ? </h1>:null}
                    {validationIndex === 2 ? <h1 className="modalTitle">Validation des conditions générales de ventes </h1> : null}
                    {validationIndex === 4 ?<h1 className="modalTitle">Information de livraison et facturation </h1>: null}
                    {validationIndex === 3 ?<h1 className="modalTitle">Date de livraison souhaitée </h1>: null}
                    {validationIndex === 5 ? loading ? <h1 className="modalTitle">Création de votre commande</h1> : <h1 className="modalTitle">Merci pour votre commande</h1>: null}
                </ModalHeader>
                <ModalBody>
                    {validationIndex === 0 ? 
                        <ExterneRefForm
                        onSubmit = {(values) => handleClientRef(values)}
                        />
                    :
                    null}
                    {validationIndex ===  1 ?
                        <CommandInfoFile handleSubmitInfoFile = {handleSubmitInfoFile}/>
                    :
                    null}

                    {validationIndex === 2 ? 
                        <Cgv
                            handleCgv={() => handleCgv()}
                        />
                    :
                    null}

                    {validationIndex === 4 ? 

                        loading ? <Loading/> :
                        <DeliveryForm 
                        initialValues = {data}
                        onSubmit = {(values) => handleSubmit(values)}
                        />
                        
                    :
                    null}
                    {validationIndex === 3 ? 
                        <WantedDate
                        onSubmit = {(values) => handleWishDate(values)}
                        />
                    :
                    null}

                    
                    {validationIndex ===  5 ? 
                        loading ? 
                            <div className="containerColCenter">
                                <div className="tableMarg">Cela peut prendre plus ou moins de temps selon la taille et le nombre de fichiers </div>
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div> 

                        : 

                            <CommandConfirmation/>
                    :
                    null}

                    
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
	basket: getBasket(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBasket: (item) => dispatch(setBasket(item)),
    onNewBasket: (item) => dispatch(newBasket(item)),
    onDelBasket: (id) => dispatch(delBasket(id)),
    onEraseBasket: () => dispatch(eraseBasket()),
    onUpdateBasket: (item, index) => dispatch(updateBasket(item, index))
});

export default connect(mapStateToProps, mapDispatchToProps)(Basket);