import { SET_PARAMS, PARAMS_COMMANDE_STATUS, PARAMS_ROLES } from "./constants";

const defaultValues = {
	[PARAMS_ROLES]: [],
	[PARAMS_COMMANDE_STATUS]: [],
};

const paramsReducer = (state = defaultValues, { payload, type }) => {
	const newState = { ...state };
	switch (type) {
		case SET_PARAMS:
			return { ...payload };
		default:
			return newState;
	}
};

export default paramsReducer;
