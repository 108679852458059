import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as manufactureService from "../../services/manufacture";

import Loading from "../../components/Loading";
import WHTable from "../../components/WHTable";

class MetragePopup extends Component {
	state = {
		open: false,
		data: [],
		loaded: true,
	};

	handleOpen = (command) => {
		this.setState({ open: true, loaded: false });

		const data = [];

		manufactureService.iri(command.product.manufacture).then((res) => {
			res.confection.map((el) => {
				data.push({
					name: el.fabric.name,
					qty: el.fabric.dimension * el.quantity * command.quantity,
				});
			});

			this.setState({ data, loaded: true });
		});
	};

	handleClose = () => {
		this.setState({ open: false, data: [] });
	};

	columns = () => {
		let columns = [];

		columns.push(
			{
				Header: "Tissu",
				accessor: "name",
				filterable: false,
			},
			{
				Header: "Mètre lineaire",
				accessor: "qty",
				filterable: false,
			}
		);
		return columns;
	};

	render() {
		const { className } = this.props;
		const { loaded, data, open } = this.state;
		return (
			<div>
				<Modal
					size={"xs"}
					isOpen={open}
					className={className}
					fade={false}
				>
					<ModalHeader toggle={() => this.handleClose()}>
						Mètre linaire de tissu
					</ModalHeader>

					{!loaded && <Loading />}

					<WHTable
						data={data}
						columns={this.columns()}
						defaultPageSize={10}
						filterable={true}
					/>
				</Modal>
			</div>
		);
	}
}

export default MetragePopup;
