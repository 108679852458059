import classNames from "classnames";
import React, { useMemo } from "react";
import "./styles.css";

const Pagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
  onLimitChange,
}) => {
  const numberOfPages = useMemo(() => {
    return Math.ceil(
      itemsCountPerPage >= totalItemsCount
        ? 1
        : (totalItemsCount +
            (itemsCountPerPage - (totalItemsCount % itemsCountPerPage))) /
            itemsCountPerPage
    );
  }, [totalItemsCount, itemsCountPerPage]);

  const renderPaginationButton = (isNext = true, active) => {
    const onClick = () => {
      if (active) {
        const newPage = isNext ? activePage + 1 : activePage - 1;
        onChange(newPage);
      }
    };

    const arrowClass = classNames("pagination-button", { disabled: !active });

    const arrowBtn = (
      <>
        <button className={arrowClass} onClick={onClick}>
          <div
            className={classNames("fa", {
              "fa-chevron-left": !isNext,
              "fa-chevron-right": isNext,
            })}
          />
        </button>
      </>
    );

    return arrowBtn;
  };
  
  const onPageChange = (props) => {
    const newActivePage = props;
    if(newActivePage <= numberOfPages && newActivePage != 0){
      onChange(newActivePage);
    }
  }

  const showNextButton = activePage < numberOfPages;
  const showPreviousButton = activePage > 1;

  const availablePageSizes = [5, 10, 20, 25, 50];

  return (
    <div className="pagination-container">
      <div className="pagination-toolbar">
        {renderPaginationButton(false, showPreviousButton)}
        {renderPaginationButton(true, showNextButton)}
        <div style={{ marginRight: 15, marginLeft: 20 }}>
          Page <input value={activePage} onChange={(e) => onPageChange(e.currentTarget.value)} type="number" /> sur {numberOfPages}
        </div>
        <div style={{ marginRight: 15 }}>
          <select
            aria-label="rows per page"
            onChange={(e) => onLimitChange(e.currentTarget.value)}
          >
            {availablePageSizes.map((pageSize, key) => {
              return (
                <option
                  value={pageSize}
                  selected={pageSize === itemsCountPerPage}
                  key={key}
                >
                  {pageSize} lignes
                </option>
              );
            })}
          </select>
        </div>
        <div style={{ marginRight: 15 }}>Total: {totalItemsCount}</div>
      </div>
    </div>
  );
};

export default Pagination;
