export default (data, url, method, token) => {
	let toCopy;

	toCopy = "url : " + url + "\n";
	toCopy += "method : " + method + "\n";
	toCopy += "token : " + token + "\n";
	toCopy += "\n\n Payload : \n ======================= \n\n";
	toCopy += JSON.stringify(data);

	const copyText = document.getElementById("copyText");
	copyText.value = toCopy;
	copyText.select();
	document.execCommand("copy");
};
