import React, {useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone'
import { toast } from "react-toastify";
import { ModalFooter, Button, Row, Progress } from "reactstrap";
import { createMedias, fetchOrganisations, uploadLargeFile } from "../../services/crm";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";

const FilesAdd = (props) => {

    const {userRoles, commandId, isSaler, userId} = props
    const organisationId = props.user.organisationId
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const maxSize = 5368709120
    const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone({maxSize: maxSize});
    const [file, setFile] = useState([]);
    const [base64, setBase64] = useState();
    const [loading, setLoading] = useState(false);
    const [chunkArray, setChunkArray] = useState([]);
    const [progressBar, setProgressBar] = useState(0);
    const [base64Array, setBase64Array] = useState([]);
    const [totalSize, setTotalSize] = useState()
    const [saveOpen, setSaveOpen] = useState(false)

    const handleAddFile = props.handleAddFile ? props.handleAddFile : null;
    const isRecupId = props.recupId ? props.recupId : false;

    let offsetProgress
    
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
      };
      
    useEffect(() => {
        setFile(acceptedFiles);
        if(acceptedFiles[0]){
            uploadFile(acceptedFiles[0])
        }
    }, [acceptedFiles])

    useEffect(() => {

    }, [progressBar, loading])

    
    const uploadFile = (file) => {
        function readFileInChunks(file, onChunkLoad, onFileLoad) {
            const chunkSize = 3 * 1024 * 1024; // 3 MB chunks
            const reader = new FileReader();
            let offset = 0;
            reader.onload = function (event) {
                if (event.target.error) {
                    console.error('Error reading the file:', event.target.error);
                return;
                }
            
                onChunkLoad(event.target.result);

                offset += event.loaded;
                setTotalSize(file.size)
                if (offset < file.size) {
                    offsetProgress = Math.floor((offset / file.size) * 100)
                    readNextChunk();
                } else {
                    onFileLoad(reader.result);
                }
            }
          
            function readNextChunk() {
                const blob = file.slice(offset, offset + chunkSize);
                reader.readAsArrayBuffer(blob);
            }
            
            readNextChunk();
        }
        
        if(file.size > 150000000){
            readFileInChunks(file, function (chunk) {
                chunkArray.push(chunk)
            }, function (result) {
                setSaveOpen(true)
            }); 

          }else{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                const b64 = reader.result.split(',')[1]
                setBase64(b64)
                setSaveOpen(true)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
          }

    }

    const convertArrayToBase64 = (array) => {
        for (let i = 0; i < array.length; i++) {
            var binary = '';
            var bytes = new Uint8Array(array[i]);
            var len = bytes.byteLength;
            for (var e = 0; e < len; e++) {
                binary += String.fromCharCode( bytes[ e ] );
            }
            const base = window.btoa( binary );
            base64Array.push(base) 
        }
    }
    const handleSubmit = async () => {
        setLoading(true)
        const orgaName = await fetchOrganisations({id:organisationId})
        if(orgaName.data.length <= 0){
            toast.error("Aucun dossier DropBox pour cet utilisateur, car celui-ci n'est rattaché à aucune organisation.")
            
            setTimeout(() => {
                window.location.reload()
            }, 5000);
            return
        }
        if(chunkArray.length > 55){
            let offset = 0
            let sessionId = null
            setProgressBar(0)
            for (let i = 0; i <= chunkArray.length; i++) {
                var binary = '';
                var bytes = new Uint8Array(chunkArray[i]);
                var len = bytes.byteLength;
                for (var e = 0; e < len; e++) {
                    binary += String.fromCharCode( bytes[ e ] );
                }
                const base = window.btoa( binary );

                if(i === chunkArray.length){
                    const payloadEnd = {
                        name: file[0].name,
                        organisationId:organisationId,
                        chunk:null,
                        sessionId: sessionId,
                        offset:offset,
                        step:"finish",
                    }
                    const resp = await uploadLargeFile(payloadEnd)
                    if(resp.step === "succes"){
                        toast.success("Fichier enregistré")
                        !isRecupId ? 
                        setTimeout(() => {
                            window.location.reload()
                          }, 2000)
                        : 
                        handleAddFile(resp)

                    }else{
                        toast.error("Une erreur est survenue")
                        setTimeout(() => {
                            window.location.reload()
                          }, 2000);
                    }
                }else{
                    const payload = {
                        name: file[0].name,
                        organisationId:organisationId,
                        chunk:base,
                        sessionId: resp ? resp.sessionId : null,
                        offset:offset,
                        step: i === 0 ? "start" : "step",
                    }
                    const resp = await uploadLargeFile(payload)
                    offset = resp.offset
    
                    sessionId = resp.sessionId
                }
                const newProgress = Math.floor((offset / totalSize * 100))
                setProgressBar(newProgress)
            }
        }else{
            convertArrayToBase64(chunkArray)
            try{
                const newBase = base64Array.join("")
                let payload 
                if(base64){
                    payload = {
                        name: file[0].name,
                        file: base64,
                        organisationId:organisationId
                    }
                }else{
                    payload = {
                        name: file[0].name,
                        file: newBase,
                        organisationId:organisationId
                    }
                }
                const createdMedia = await createMedias(payload)
                toast.success("Fichier enregistré")

                !isRecupId ?
                setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                : 
                handleAddFile(createdMedia)
            }catch(error){
                console.log(error)
                toast.error("Une erreur est survenue")
                setTimeout(() => {
                    window.location.reload()
                  }, 2000);
                
            }
        }
        setLoading(false)
    }
    return(
        <>
        {
            loading ? 
                <>
                    <div className="containerColCenter">
                        <div className="tableMarg">Cela peut prendre plus ou moins de temps selon la taille et le nombre de fichiers </div>
                    </div> 
                    {!base64 ? 
                        <>
                            <div className="containerColCenter fontEuro">
                                {`${progressBar} %`}
                            </div>
                            <Progress
                                className="progressMedia"
                                animated
                                color="success"
                                value={progressBar}
                            />
                        </>
                    :null}

                    <div className="containerColCenter">
                        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                    
                </>
            :
                <>
                    <div className="containerColCenter">
                        <Row>
                            <div className="centerTitle">
                                {file.length === 0  ?
                                    <h3>Upload d'un media</h3>
                                    :
                                    <div>{file[0].path}</div>
                                }
                            </div>
                        </Row>
                        <Row>
                            <div className="centerTitle">
                                <div className='divShadow' {...getRootProps()}>
                                    <i class='fas fa-cloud-download-alt fa-xl'></i>
                                    <input {...getInputProps()} />
                                </div>
                            </div> 
                        </Row>
                    </div>
                    <ModalFooter>
                    {saveOpen ?
                        
                    <Button color='dark' onClick={handleSubmit}>
                        Enregistrer
                    </Button>:null}
                    </ModalFooter>
                </>
        }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(FilesAdd);