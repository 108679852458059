import React, {useState, useEffect} from "react";
import { fetchCommands } from "../../services/crm";

const ClientIndicator = ({userRoles, user}) => {
    const [data, setData] = useState([]);

    useEffect( () => {
		loadData();
	}, []);
    const loadData = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const query = {
            authorId:user.id,
            currentStatus:{$lt:800}
        }

        const result = await fetchCommands(query)
        setData(result.data)
    }
    return(
        <>
            <div className="row dashMarg">
                <div className="divIndicator">
                    <div>Nombre de commandes en cours</div>
                    <div className="fontEuro">{data != null ? `   ${data.length} ` : " Loading" }   </div>
                </div>
            </div>
        </>
    )
}

export default ClientIndicator;