import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import { fetchProducts } from "../../services/pim";

const tissuSelection = (props) => {
   /*  const [options, setOptions] = useState();

    useEffect(() => {
        loadTissu()
      }, []);

    const loadTissu = async () => {
        const query = {
            type: 100,
            $limit:50
        }
        await fetchProducts(query).then((result) => {
            const dataFilter = result.data.filter(item => item.fabric.type === props.selectedValue)
            const options = dataFilter.map((item) => ({
              prix:item.prices,
              value: item.id,
              ref: item.reference,
              label: `${item.reference} ${item.label}`,
            }));
            setOptions(options)
          });
    }

    return(
        <>
            <Row>
                <Col>
                    <Select
                        placeholder="Choisissez un tissu"
                        noResultsText="Pas de résultat"
                        className="searchSaler"
                        onChange={props.handleClickTissu}
                        value={props.selectedValue}
                        options={options}
                    />
                </Col>
            </Row>
        </>
    ) */
    
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setInputValue] = useState('')
    // Define your loadSellerOptions function for fetching options asynchronously
    const loadTissuOptions = async (input, callback) => {
        return fetchProducts({
            /* roles: { $like: `%ROLE_SALER%` }, */
            type: 100,
            $or: [
              { reference: { $like: `%${input}%` } },
            ],
          }).then((result) => {
            const dataFilter = result.data.filter(item => item.fabric.type === props.selectedValue)
            const options = dataFilter.map((item) => ({
                prix: item.prices,
                value: item.id,
                ref: item.reference,
                label: `${item.reference} ${item.label}` ,
            }));
      
            return { options };
          });
    };

    const handleInputChange = (inputValue) => {
        setInputValue(inputValue)
    };

    return(
        <Row>
            <Col> 
                <Select.Async
                    placeholder="Séléctionner un tissu"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputChange}
                    onChange={props.handleClickTissu}
                    value={selectedValue}
                    loadOptions={loadTissuOptions}
                />
            </Col>
        </Row>
    )
}
export default tissuSelection;