import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { fetchMetrage } from "../../services/crm"; // ???????



const MetrageIndicator = (props) => {
    const res = props.data
    const [data, setData] = useState([{}]);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState();
    const [second, setSecond] = useState();
    const [third, setThird] = useState();
    
    const assignValues = () => {
        if(Object.keys(data[0]).length != 0 && total == 0){
            let totalMid = 0
            data.map((item, index) => {
                if(index === 0){
                    data.map((item, index) => {
                        const res = parseFloat(item.total.toFixed(2))
                        totalMid = totalMid + res 
                    })
                }
                let pourcent
                setTotal(totalMid.toFixed(2))
                const res = parseFloat(item.total.toFixed(2))
                pourcent = parseFloat((res / totalMid) * 100)
                switch(index){
                    case index = 0:
                        setFirst(pourcent)
                        break;
                    case index = 1:
                        setSecond(pourcent)
                        break;
                    case index = 2: 
                        setThird(pourcent)
                        break;
                    default: console.log("default")
                }
            })
        }
    }   
 
    useEffect(()=> {
        setData(res)
        if(res[0]){
            assignValues()
        }
      }, [props]);
    
    return(
        <>  
            <Card>
                <Row style={{marginLeft:15, marginBottom:10}}>
                    <h2>Métrage en cours</h2>
                </Row>
                <Row className="metrageDiv">
                    <Col style={{ marginTop:20, marginLeft:20}}>
                        <CircularProgressbarWithChildren
                        text={`${total} ml`}
                        value={third}
                        styles={buildStyles({
                        pathColor: "#B5DEFF",
                        trailColor: "#B5DEFF",
                        textColor:"#d1d4d7",
                        strokeLinecap: "butt"
                        })}
                        >   
                            <CircularProgressbarWithChildren
                            value={second + first}
                            styles={buildStyles({
                                trailColor: "transparent",
                                pathColor:"#C1FFD7",
                                strokeLinecap: "butt"
                            })}
                            >
                                <CircularProgressbar
                                value={first}
                                styles={buildStyles({
                                    trailColor: "transparent",
                                    pathColor:"#CAB8FF",
                                    strokeLinecap: "butt"
                                })}
                                />
                            </CircularProgressbarWithChildren>
                        </CircularProgressbarWithChildren>
                    </Col>
                    <Col style={{marginTop:24, marginLeft:50}}>
                        <Row>
                            <div className="legende">{""}</div>
                            <div className="fontMetrage2"> {data[0] ? data[0].productRef ? data[0].productRef : null : null}</div>
                        </Row>
                        <Row>
                            <div className="fontMetrage2"> {data[0] ? data[0].total ? `${data[0].total.toFixed(2)} ml` : null : null}</div>
                        </Row>
                        <Row>
                            <div className="legende2">{""}</div>
                            <div className="fontMetrage2"> {data[1] ? data[1].productRef : null}</div>
                        </Row>
                        <Row>
                            <div className="fontMetrage2">{data[1] ? `${data[1].total.toFixed(2)} ml` : null}</div>
                        </Row>
                        <Row>
                            <div className="legende3">{""}</div>
                            <div className="fontMetrage2"> {data[2] ? data[2].productRef : null}</div>
                        </Row>
                        <Row>
                            <div className="fontMetrage2">{data[2]? `${data[2].total.toFixed(2)} ml` : null}</div>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default MetrageIndicator;