import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from 'reactstrap';

const CommandConfirmation = () => {
    let Navigate = useNavigate();

    const handleViewCmd = () => {
        Navigate('../account/cmd'/* , {orgaId:2} */)
    }

    const handleGoStore = () => {
        Navigate('../product-selection')
    }


    return(
        <div>
            <div className="confirmationModal">
                Votre commande à bien été prise en compte, vous allez recevoir un mail récapitulatif
            </div>
            <Button color="teal" onClick={handleGoStore} className="modalSubmit">
                Poursuivre mes achats
            </Button>
            <Button onClick={handleViewCmd} className="modalSubmit">
                Voir mes commandes en cours
            </Button>

        </div>
    )
}

export default CommandConfirmation;