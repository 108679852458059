import { toast } from "react-toastify"
export const required = value => {
	if (!value && value !== 0) {
		return "Obligatoire";
	}

	return undefined;
};

export const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Adresse email non valide"
		: undefined;

export const numeric = value =>
	value && !/^([0-9]*[.,])?[0-9]*$/i.test(value)
		? "Ce champ ne peut contenir que des chiffres"
		: undefined;
		

export const phone = value =>
	value &&
	!/^([0-9]{2}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2})$/i.test(value)
		? "Ce n’est pas pas un numéro valide"
		: false;

export const validateOrganisation = (value, allValues) => {
	if(value.label){
		let error = []
		allValues.customPrices.map((item, index) => {
			let id 

			if(typeof item.organisationId == "number"){
				id = item.organisationId
			}else{
				if(item.organisationId != undefined){
					id = item.organisationId.value
				}
			}

			if(id === value.value){
				error.push("error")
			}
		})

		return error.length > 1 ? "Le tarif pour cette organisation a été défini plusieurs fois" : undefined
	}
	return undefined
}