import React from "react";
import LaddaButton from "react-ladda/dist/LaddaButton";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useModal from "../../../hooks/useModal";

const CguField = ({ input: { value, onChange, name } }) => {
  const { active, toggle } = useModal();

  return (
    <div>
      <div className="cgu-btn" onClick={toggle}>
        Lire les conditions générales d'utilisation
      </div>

      <Modal
        isOpen={active}
        toggle={toggle}
        style={{ maxWidth: "1200px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>
          <h1 className="modalTitle">Conditions générales d'utilisation</h1>
        </ModalHeader>
        <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <iframe src="https://drive.google.com/file/d/1lHtxOFSDX2b6wvu6yR_I1LhTY0sqOijM/preview" width="640" height="500" allow="autoplay"></iframe>
        </div>

          <div style={{ display: 'flex', justifyContent: 'center', margin: '15px 0', width: '100%' }}>
            <input name={name} type="checkbox" onChange={onChange} checked={value} id="checkbox-cgu" />
            <label for="checkbox-cgu" style={{ marginLeft: 10, cursor: 'pointer' }}>
              J'accepte les conditions générales d'utilisation
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <LaddaButton
          className="btn btn-gray btn-padded btn-fullWidth	"
          onClick={toggle}>
            Fermer
          </LaddaButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CguField;
