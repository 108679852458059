import React from "react";
import { Col, FormGroup, Label, FormFeedback, FormText } from "reactstrap";
import PropTypes from "prop-types";
import "react-select/dist/react-select.css";
import Select from 'react-select-nested';

export const FieldNestedSelect = ({
	list,
	help,
	input,
	label,
    placeholder,
	meta: { touched, error },
}) => (
	<FormGroup row>
		<Col lg={12}>
			{label && <Label>{label}</Label>}
			{require && <span className="text-danger font-weight-bold">*</span>}
		</Col>

		<Col lg={12}>
			<Select
				list={list}
                placeholder={placeholder}
				{...input}
                onSelectChange={(item)=> input.onChange(item)}
				{...(touched && error ? { invalid: true } : {})}
				onBlur={() => {
					input.onBlur([...input.value]);
				}}
			/>

			{touched && error && (
				<FormFeedback className="help-block">{error}</FormFeedback>
			)}

			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

FieldNestedSelect.defaultProps = {
	className: null,
	input: {},
	reverted: false,
	label: null,
	type: null,
	help: null,
	name: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldNestedSelect.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.string,
	type: PropTypes.string,
	help: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	reverted: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldNestedSelect;
