import React, { useState, useMemo, useEffect } from "react";
import { fetchCommands, fetchSubCommands } from "../../services/crm";
import Table from "../../components/Table";
import Moment from "react-moment";
import { Button, CardHeader } from "reactstrap";
import { Modal, ModalBody, ModalHeader, Row, Col, Collapse, Label } from "reactstrap"
import useModal from "../../hooks/useModal";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import SubCommandsList from "../SubCommands/SubCommandsList";
import { fetchOrganisations } from "../../services/crm";
import Select from "react-select";
import ShippingInfo from "./commands/shippingInfo";

const CommandsHistory = ({ userRoles, user }) => {
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const { toggle, active } = useModal();
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState();
    const [selectedOrgaValue, setSelectedOrgaValue] = useState(null);
    const [inputOrgaValue, setInputOrgaValue] = useState('');
    const [selectedRefValue, setSelectedRefValue] = useState(null);
    const [inputRefValue, setInputRefValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState();
    const [contact, setContact] = useState();

    useEffect(() => {
        loadData(1)
    }, [selectedOrgaValue]);

    const loadData = async (pageNumber, limit = 50) => {
        const user = JSON.parse(localStorage.getItem('user'));
        let query 
        setPage(pageNumber);

        if(isAdmin === false){
            query = { $skip: (pageNumber - 1) * limit, $limit: limit, authorId:user.id, currentStatus:900};
        }else{
            query = { $skip: (pageNumber - 1) * limit, $limit: limit, currentStatus:900};
        }

        if(selectedOrgaValue){
            query.organisationId = selectedOrgaValue.value
        }

        if(selectedRefValue){
            query.ref = selectedRefValue.label
        }

        const { data, ...paginationParams } = await fetchCommands(query);
        setData(data)
        setPagination(paginationParams)
    }

    const handleView = (props) => {
        setSelectedData(props)
        setIndex(0)
        toggle();
    }

    const handleViewShipping = (props) => {
        setSelectedData(props)
        contactInfo(props)
        setIndex(1)
        toggle();
    }




    const loadOrgaOptions = (input) => {
        return fetchOrganisations({
          $or: [
            { name: { $like: `%${input}%` } },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          }));
    
          return { options };
        });
    }

    const loadRefOptions = (input) => {
        return fetchSubCommands({
          $or: [
            { ref: { $like: `%${input}%`}, currentStatus:900 },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.ref}`,
          }));
    
          return { options };
        });
    }
      
    const handleInputOrgaChange = (value) => {
        setInputOrgaValue(value)
    }

    const handleOrgaChange = (value) => {
        setSelectedOrgaValue(value)
    }

    const handleInputRefChange = (value) => {
        setInputRefValue(value)
    }
    
    const handleRefChange = (value) => {
        setSelectedRefValue(value)
    }

    const handleCollapse = () => {
    setIsOpen(!isOpen)
    }

    const contactInfo = async (props) => {
        const query = {id : props.subCommands[0].organisationId}
        const contact = await fetchOrganisations(query)
        setContact(contact.data[0])
    }

    const columns = useMemo(() => {
        const result = [
            {
            id: "ref",
            accessor: "ref",
            Header: "Référence",
            },
            {
                id: "project",
                accessor: "project",
                Header: "Projets",
                    Cell: ({ row }) => {
                        const listMedia = row.original.subCommands.map((item, index) => {
                            return(
                                <>
                                    <div>
                                        {item.mediaName}
                                    </div>       
                                </>
                            )
                        })
                        return (
                            <>
                                <div className="test5">
                                    {listMedia}
                                </div>
                            </>
                        );
                },
            },
            {
            id: "status",
            accessor: "currentStatusName",
            Header: "Statut",
                Cell: ({ row }) => {
                    return (
                    <span className={`label cmdStatus-${row.original.currentStatus}`}>

                        {row.original.currentStatusName}
                    </span>
                    );
                },
            },
            {
            id: "date",
            Header: "Date de création",
            accessor: "createdAt",
                Cell: (row) => <Moment date={row.value} format={"DD/MM/YYYY"} />,
            },
            {   
            id: "price",
            accessor: "totalPrice",
            Header: "Total commande",
                Cell: (row) => <div>€ {row.value}</div>
            },
            {
            id: "trackingNumber",
            accessor: "",
            Header: "Numéro de suivi",
            },
            {
            id: "action",
            accessor: "action",
            Header: "Actions",
                Cell: ({row}) => {
                    return(
                        <div className={"text-center"}>
                            <Button color={"transparent"} onClick={() => handleView(row.original)}>
                                <i className={"fa fa-eye"} />
                            </Button>
                            <Button color={"transparent"} onClick={() => handleViewShipping(row.original)}>
                                <i className={"fas fa-shipping-fast"} />
                            </Button>
                        </div>
                    )
                }
            },
        ];
        if (isAdmin){
            result.push({
                id: "organisation",
                accessor: "organisations",
                Header: "Organisation",
                    Cell: (row) => {
    
                        return(
                            <div>{row.row.original.organisation.name}</div>
                        )
                    }
            })
            return result
        }else{
            return result
        }
    }, []);

    return(
        <>
            <CardHeader>
                <h1>Historique des commandes</h1>
            </CardHeader>
            {isAdmin ? 
                <Col xs="10">
                    <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
                    <Collapse isOpen={isOpen} className="filterButton">
                        <Row className="filterRow">
                        <Label className="filterLabel">Recherche par organisation </Label>
                        <Select.Async
                            placeholder="Nom de l'entreprise"
                            noResultsText="Pas de résultat"
                            className="searchSaler"
                            onInputChange={handleInputOrgaChange}
                            onChange={handleOrgaChange}
                            value={selectedOrgaValue}
                            loadOptions={loadOrgaOptions}
                        />  
                        </Row>
                    </Collapse>
                </Col>
            :null}
            <Table
            className="dashMarg"
            data={data}
            columns={columns}
            loadData={loadData}
            paginated
            pagination={pagination}
            />
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}
                >
                <ModalHeader toggle={toggle}>
                {index === 0 ?
                    <h1 className="modalTitle">Sous commandes de la commande : {selectedData.ref}</h1>
                :null}
                {index === 1 ? 
                    <h1 className="modalTitle">Information de livraison</h1>
                :null}
                </ModalHeader>
                <ModalBody>
                    {index === 0 ?
                        <SubCommandsList commandId={selectedData.id} isClosed={true}/>
                    
                    :null}
                    {index === 1 ?
                        <ShippingInfo selectedData={selectedData} contact={contact} isSubCo={false}/>
                    :null}
                </ModalBody>
            </Modal>
        </>
    )   
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(CommandsHistory) ;
