import React, { useState, useMemo, useEffect }from "react";
import Table from "../../components/Table";
import {  fetchJobs, fetchBase64Media, updateJobs, fetchMakers } from "../../services/crm";
import { Button, ModalBody, ModalHeader, Modal, Row, Col, Collapse, Label, CardHeader, Input} from "reactstrap";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import JobForm from "./jobForm";
import Select from "react-select";


const JobList = (props) => {
    const {userRoles, commandId, isSaler, user} = props
    const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
    const [pagination, setPagination] = useState();
    const [data, setData] = useState([]);
    const { toggle, active } = useModal();
    const [selectedData, setSelectedData] = useState();
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [infosJob, setInfosJob] = useState();
    const [inputValue, setInputValue] = useState('');
    const [selectedTicketValue, setSelectedTicketValue] = useState(null);
    const [inputTicketValue, setInputTicketValue] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMediaValue, setSelectedMediaValue] = useState(null);
    const [inputMediaValue, setInputMediaValue] = useState(null);
    const [inputTicketToSendValue, setInputTicketToSendValue] = useState(null)
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedConfValue, setSelectedConfValue] = useState(null);
    const [inputConfValue, setInputConfValue] = useState(null)
    const [valueMedia, setValueMedia] = useState(null)
    const [selectedStatutValue, setSelectedStatutValue] = useState(null)

    useEffect(() => {
        loadData(1)
        console.log(selectedStatutValue)
      }, [selectedTicketValue, valueMedia, selectedStatutValue]);

    const loadData = async (pageNumber, limit = 50, ) => {
        if(isAdmin){
            if(valueMedia && selectedTicketValue === null && selectedStatutValue === null){            
                const payload = {
                    $skip: (pageNumber - 1) * limit,
                    $limit: limit,
                    $sort: { createdAt: -1 },
                    mediaInfo: { $like: `%${valueMedia}%` },
                };
                const { data, ...paginationParams } = await fetchJobs(payload);
                setData(data)
                setPagination(paginationParams)
            }else if (selectedTicketValue === null && !valueMedia && selectedStatutValue === null){    
                const query = {
                    $skip: (pageNumber - 1) * limit,
                    $limit: limit,
                    $sort: { createdAt: -1 },
                };
    
                const { data, ...paginationParams } = await fetchJobs(query);
                setData(data)
                setPagination(paginationParams)
            }else if(selectedTicketValue !== null && !valueMedia && selectedStatutValue === null){      
                const query = {
                    $skip: (pageNumber - 1) * limit,
                    $limit: limit,
                    $sort: { createdAt: -1 },
                    jobTicket: { $like: `%${selectedTicketValue.label}%` }
                };
    
                const { data, ...paginationParams } = await fetchJobs(query);
                setData(data)
                setPagination(paginationParams)
            }else if(selectedStatutValue !== null && !valueMedia && selectedTicketValue === null){
                const query = {
                    $skip: (pageNumber - 1) * limit,
                    $limit: limit,
                    $sort: { createdAt: -1 },
                    status: selectedStatutValue.value,

                }
                const { data, ...paginationParams } = await fetchJobs(query);
                setData(data)
                setPagination(paginationParams)
            }
        }
    };

    const loadJobTicket = (input) => {
        return fetchJobs({
          $or: [
            { jobTicket: { $like: `%${input}%` } },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.jobTicket}`,
          }));
    
          return { options };
        });
    }

    const loadConf = async (input) => {
        return fetchMakers({
          $or: [
            { name: { $like: `%${input}%` } },
          ],
        }).then((result) => {
          const options = result.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          }));
    
          return { options };
        });
    }

    const options = [
        {value: 10, label: "En impression"},
        {value: 20, label: "En transfert"},
        {value: 30, label: "En visite"},
        {value: 40, label: "Depart confectionneur"},
        {value: 50, label: "Retour confectionneur"},
        {value: 60, label: "Fin"}
    ]
    const handleInputTicketChange = (value) => {
        setInputTicketValue(value)
      }
    
    const handleTicketChange = (value) => {
        setSelectedTicketValue(value)
    }

    const handleInputConfChange = (value) => {
        setInputConfValue(value)
      }
    
    const handleConfChange = (value) => {
        setSelectedConfValue(value)
    }

    const handleInputMediaChange = (value) => {
        setValueMedia(value.target.value)
    }

    const handleInputTicketToSendChange = (value) => {
        setInputTicketToSendValue(value.target.value)
      }
    
    const handleCollapse = () => {
        setIsOpen(!isOpen)
    }

    const handleCollapseTicket = () => {
        setIsOpen2(!isOpen2)
        setInputTicketToSendValue(null)
        setSelectedConfValue(null)
     }

    const handleEdit = (props) => {
        setSelectedData(props)
        setIndex(0)
        toggle()
    };

    
    const onClose = () => {
        setSelectedData();
        toggle();
    };

    const onSuccess = () => {
        loadData(1);
        toggle();
    };

    const handleStatutChange = (selectedOption) => {
        setSelectedStatutValue(selectedOption);
    };

    const testTicketList = (list) => {
        //Vérification des numéro de job 
        const regexJobTicket = /^\d{0,10}$/;
        let res = true
        list.map((ticket, index) => {
            if(regexJobTicket.test(ticket) === false){
                toast.error(`Problème dans la saisie, ${ticket} n'est pas une saisie valide`)
                res = false
            }
        })
        return res
    }
    const handleSubmitSend = () => {
        if(selectedConfValue && inputTicketToSendValue){
            const ticketList = inputTicketToSendValue
            const ticketListWithoutSpace = ticketList.replace(/\s/g, '');
            const splitTicket = ticketListWithoutSpace.split(',')
            const isTicketValide = testTicketList(splitTicket)
            if(isTicketValide === true){
                splitTicket.map(async (ticket, index) => {
                    //récupération id
                    const query = {
                        jobTicket: ticket
                    }
                    const result = await fetchJobs(query)
                    //update en bdd
                    if(result.data[0]){
                        const payload = {
                            status: 40,
                            makerId: selectedConfValue.value
                        }
                        try{
                            await updateJobs(result.data[0].id,payload)
                            window.location.reload()
                            loadData(1)
                        }catch(error){
                            console.log("try")
                            toast.error(`Une erreur est survenue sur le ticket ${ticket}`)
                        }
                    }else{
                        console.log("catch")
                        toast.error(`Une erreur est survenue sur le ticket ${ticket}`)
                    }
                })
                setInputTicketToSendValue(null)
                setSelectedConfValue(null)
            }

        }else{
            toast.error("Veuillez selectionner un confectionneur")
        }

    }

    const handleSubmitReturn = async () => {
        if(inputTicketToSendValue){
            const ticketList = inputTicketToSendValue
            const ticketListWithoutSpace = ticketList.replace(/\s/g, '');
            const splitTicket = ticketListWithoutSpace.split(',')
            const isTicketValide = testTicketList(splitTicket)
    
            if(isTicketValide === true){
                await splitTicket.map(async (ticket, index) => {
                    //récupération id
                    const query = {
                        jobTicket: ticket
                    }
                    const result = await fetchJobs(query)
                    //update en bdd
                    if(result.data[0]){
                        const payload = {
                            status: 50,
                        }
                        try{
                            await updateJobs(result.data[0].id,payload)
                            loadData(1)
                            window.location.reload()
                        }catch(error){
                            toast.error(`Une erreur est survenue sur le ticket ${ticket}`)
                        }
                    }else{
                        toast.error("erreur")
                    }
                })
                setInputTicketToSendValue(null)
                setSelectedConfValue(null)
            }

        }
    }

    const onSubmit = async  (props) => {
        if(props.status || props.comment || props.maker){
           if(props.status.value === 40 && props.maker.value === null){
            toast.error("Veuillez selectionner un confectionneur")
           }else{
            //update en bdd
            const payload = {
                status: props.status ? props.status.value : null,
                comment: props.comment ? props.comment : null,
                makerId: props.maker ? props.maker.value : null
            }
            try{
                const resp = await updateJobs(selectedData.id,payload)
                loadData(1)
                toggle()
            }catch(error){
                console.log(error)
            }
           }

        }

    }

    const columns = useMemo(() => [
        {
            id: "jobTicket",
            accessor: "jobTicket",
            Header: "Job ticket",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.jobTicket}
                  </span>
                );
            }
        },
        {
            id: "name",
            accessor: "name",
            Header: "Nom",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.name}
                  </span>
                );
            }
        },
        {
            id: "status",
            accessor: "status",
            Header: "Statut",
            Cell: ({ row }) => {
                return (
                  <span className={`label status-${row.original.status}`}>
                      {row.original.currentStatusName}
                  </span>
                );
            }
        },
        {
            id: "date",
            accessor: "date",
            Header: "Date création",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.date}
                  </span>
                );
            }
        },
        {
            id: "departureDate",
            accessor: "departureDate",
            Header: "Date départ conféction",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.departureDate}
                  </span>
                );
            }
        },
        {
            id: "returnDate",
            accessor: "returnDate",
            Header: "Date retour conféction",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.returnDate}
                  </span>
                );
            }
        },
        {
            id: "comment",
            accessor: "comment",
            Header: "Commentaire",
            Cell: ({ row }) => {
                return (
                  <span>
                      {row.original.comment}
                  </span>
                );
            }
        },
        {
            id: "action",
            Header: "Action",
            accessor: "action",
            Cell: (row) => {
                const handleViewMore = async (props) => {
                    setSelectedData(props)
                    if(props.mediaId){
                        setLoading(true)
                        const res = await fetchBase64Media(props.mediaId)
                        setFile(res)
                        setLoading(false)
                    }else{
                      toast.error("Le média est trop lourd pour être visonné ou téléchargé")
                    }

                    const information = JSON.parse(props.mediaInfo)
                    
                    const infoList = information.map((item, index) =>{
                        return(
                            <>
                                <div className="widthInfo">
                                    <Row>
                                        <div>
                                            Nom du fichier : {item.fileName}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div>
                                            Quantité : {item.qty}
                                        </div>
                                    </Row>
                                </div>
                            </>
                        )
                    })
                    setInfosJob(infoList)
                    setIndex(1)
                    toggle()
                }

                return(
                    <div className={"text-center"}>

                        {isAdmin ?
                            <>
                                <Button
                                    color={"transparent"}
                                    onClick={() => handleEdit(row.row.original)}
                                >
                                    <i className={"fa fa-edit"} />
                                </Button>
                                <Button
                                    color={"transparent"}
                                    onClick={() => handleViewMore(row.row.original)}
                                >
                                    <i className={"fa fa-eye"} />
                                </Button>
                            </>
                        : null}
                    </div>
                )
            }
      },

    ])
    return(
        <>  
            <CardHeader>
                <h1>Liste des jobs</h1>
            </CardHeader>
            <Col xs="10">
              <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
              <Collapse isOpen={isOpen} className="filterButton">
                <Row className="filterRow">
                  <Label className="filterLabel">Recherche par job ticket </Label>
                  <Select.Async
                    placeholder="Numéro ticket"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onInputChange={handleInputTicketChange}
                    onChange={handleTicketChange}
                    value={selectedTicketValue}
                    loadOptions={loadJobTicket}
                  />  
                </Row>
                <Row className="filterRow">
                  <Label className="filterLabel">Recherche par statut </Label>
                  <Select
                    placeholder="Statut"
                    noResultsText="Pas de résultat"
                    className="searchSaler"
                    onChange={handleStatutChange}
                    value={selectedStatutValue}
                    options={options}
                  />  
                </Row>
                <Row className="filterRow">
                  <Label className="filterLabel">Recherche par media </Label>
                  <Input
                    placeholder="Nom du media"
                    className="searchSaler"
                    onChange={handleInputMediaChange}
                    value={selectedMediaValue}
                  />  
                </Row>
                </Collapse>
            </Col>
            <Col xs="10">
                <Button className="filterButton" color={isOpen2 ? "teal" : "gray" } onClick={handleCollapseTicket}>Gestion rapide confection</Button>
                <Collapse isOpen={isOpen2} className="filterButton">
                    <Row className="filterRow">
                    <Label className="filterLabel">Tickets à modifier </Label>
                    <Input
                        placeholder="Numéro des tickets"
                        className="inputTicket"
                        onChange={handleInputTicketToSendChange}
                        value={inputTicketToSendValue}
                    />  
                    </Row>
                    <Row className="filterRow">
                        <Label className="filterLabel">Choix confectionneur </Label>
                        <Select.Async
                            placeholder="Confectioneur"
                            noResultsText="Pas de résultat"
                            className="searchSaler"
                            onInputChange={handleInputConfChange}
                            onChange={handleConfChange}
                            value={selectedConfValue}
                            loadOptions={loadConf}
                        /> 
                    </Row>
                    <Button onClick={handleSubmitSend} color="blue">Envois</Button>
                    <Button onClick={handleSubmitReturn} color="primary">Retour</Button>
                </Collapse>
            </Col>
            <Table
            className="dashMarg"
            data={data} 
            columns={columns}
            loadData={loadData}
            paginated
            pagination={pagination}
            />
            <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}>
                <ModalHeader>
                    {index === 0 ? "Editer le job" : "Informations Job"} 
                </ModalHeader>
                <ModalBody toggle={toggle}>
                    {index ===0 ? <JobForm
                                    initialValues={selectedData ? {
                                        status:{
                                            value: selectedData.status,
                                            label: selectedData.currentStatusName
                                        },
                                        comment: selectedData.comment,
                                        maker:{
                                            value: selectedData.makerId ? selectedData.makerId :  null,
                                            label: selectedData.maker ? selectedData.maker.name : null
                                        }
                                    }:undefined}
                                    active={active}
                                    onClose={onClose}
                                    onSuccess={onSuccess}
                                    onSubmit={onSubmit}/>     
                    : null}
                    {index === 1 ? 
                        <>  
                        <Row>
                            <Col sm={4}>
                                <div>
                                    {loading ? null : <img src={`data:image/jpg;base64,${file}`} className="media" alt="Pas de media pour cette commmande"/>}
                                    {loading ? <Loading/>:null}
                                </div>
                            </Col>
                            <Col sm={2}>

                            </Col>
                            <Col sm={5}>
                                    <Row className="dashMarg">
                                        <h2>Liste des medias du Job </h2>
                                    </Row>
                                <div>
                                    {infosJob}
                                </div>
                            </Col>
                        </Row>
                        </>
                    :null}
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(JobList);