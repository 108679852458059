import moment from 'moment';


export const required = (value) => {
  return Boolean(value) ? undefined : "Ce champs est obligatoire";
};

export const validEmail = (value) =>
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
    ? undefined
    : "Veuillez renseigner une adresse e-mail valide";

export const validMultipleSelect = (value) =>
  Array.isArray(value) && value.length > 0
    ? undefined
    : "Veuillez sélectionner au moins une option";

export const validPassword = (value) =>
  value && value.length >= 8
    ? undefined
    : "Votre mot de passe doit faire au moins 8 caractères";

export const validTel = (value) =>
  /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value)
    ? undefined
    : "Veuillez renseigner un numéro de téléphone valide";

export const validDate = (value) => {
  const dateRegex = /^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|((?:16|[2468][048]|[3579][26])00))))|(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9]|1[0-2])\5)?(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  return dateRegex.test(value)
    ? undefined
    : "Veuillez renseigner une date valide au format JJ-MM-AAAA";
};



// Validator to check if the date is at least 2 days after today
export const dateAfterToday = value => {
  if (value && moment(value).isSameOrBefore(moment().add(2, 'days'), 'day')) {
    return "La date doit être supérieure d'au moins 2 jours à partir d'aujourd'hui";
  }
  return undefined;
};
