import React, { Component } from "react";
import { Form } from "reactstrap";
import { ModalBody, ModalFooter } from "reactstrap";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { FieldNestedSelect } from "../../../components/Form/whFields/FieldNestedSelect";
import whFields, { WhFieldTxt } from "../../../components/Form/whFields";
import { connect } from "react-redux";

class FormStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const { handleSubmit, submitting, initialValues, currentValues, isQuantity} = this.props;
		let isComment = false

		if(currentValues === "none"){

		}else{
			if(currentValues){
				if(currentValues.status){
					if(currentValues.status.parentVal === 140 ||  currentValues.status.val === 20 || currentValues.status === 20 || currentValues.status === 140){
						isComment = true
					}else{
						isComment = false
					}
				}
	
			}

		}

        const options = [
            {val:0, label:"Panier"},
            {val:5, label:"A traiter"},
            {val:10, label:"Confirmée par Myditex"},
            {val:20, label:"Refusée non conforme"},
            {val:30, label:"Fichier ok"},
            {val:100, label:"En impression transfert"},
            {val:110, label:"En transfert"},
            {val:120, label:"En cours de confection"},
            {val:130, label:"En visite"},
            {
				val:140,
				label:"En défaut",
				items: [
					{parentVal: 140, val: 10, label: 'Defaut tissu fournisseur'},
					{parentVal: 140, val: 20, label: 'Défault conféction'},
					{parentVal: 140, val: 30, label: 'Erreur fichier client'},
					{parentVal: 140, val: 40, label: 'Erreur adresse'},
					{parentVal: 140, val: 50, label: 'Défaut impréssion'},
					{parentVal: 140, val: 60, label: 'Défaut papier'},
					{parentVal: 140, val: 70, label: "Défaut tâches d'huiles"},
					{parentVal: 140, val: 80, label: 'Problème métrage'},
					{parentVal: 140, val: 90, label: 'Autres'},
				]
			},
            {val:200, label:"A expédier"},
            {val:210, label:"Expédiée"},
            {val:230, label:"Reçue par le client"},
            {val:300, label:"A facturer"},
            {val:310, label:"Facturée"},
            {val:320, label:"En attente de paiement"},
            {val:330, label:"En retard de paiement"},
            {val:340, label:"Clôturée"},
        ]
		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Status : "
						name="status"
						placeholder={initialValues ? `${initialValues.encloseRaisonName ? initialValues.encloseRaisonName : initialValues.statusName}` : null}
						className="test"
						component={FieldNestedSelect}
                        list={options}
					/>
					{isComment ? 
						<Field
							label="Commentaire défaut: "
							name="enclosComment"
							component={WhFieldTxt}
							type="text"
						/>
					:null}
					<Field
						label="Commentaire Myditex : "
						placeholder={initialValues ? initialValues.mdtComment ? initialValues.mdtComment : null : null}
						name="mdtComment"
						component={WhFieldTxt}
						type="text"
					/>
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formStatus = "FormStatus"

FormStatus = reduxForm({
	form: formStatus,
})(FormStatus);

const mapStateToProps = state => {

	return {
	currentValues: getFormValues(formStatus)(state),
	formErrors: getFormSyncErrors(formStatus)(state),
  }};
  
  const mapDispatchToProps = (dispatch) => {
	return {
	  change: (field, value) => dispatch(change(formStatus, field, value))
	}
  }
  
  export default connect(
	  mapStateToProps,
	mapDispatchToProps
  )(FormStatus);