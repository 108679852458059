import React, {useEffect, useState} from "react";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import roles from "../../constants/roles";
import { toast } from "react-toastify";
import { Button, Row, Input } from "reactstrap";
import { updateSubCommands, uploadMediaLink } from "../../services/crm";
import { update } from "../../services/manufacture";

const FilesAddDbLink = (props) => {
    const {userRoles, commandId, isSaler, userId, mediaId, handleToggle} = props
    const organisationId = props.user.organisationId
    const [inputDbValue, setInputDbValue] = useState()

    const handleAddMediaDropBox = async () => {
        const dropboxLinkRegex = /^https:\/\/www\.dropbox\.com\/scl\/fi\//;
        if (dropboxLinkRegex.test(inputDbValue)) {
            let payload
            if(!mediaId){
                payload= {
                    url: inputDbValue,
                    organisationId: organisationId
                }
        
            }else{
                payload= {
                    url: inputDbValue,
                    organisationId: organisationId,
                    mediaId:mediaId
                }
            }
            try{
                const media = await uploadMediaLink(payload)
                console.log(commandId)
                if(commandId && media){
                    console.log("flag", commandId, media.id)
                    const id = commandId

                    const query = {
                        mediaId: media.id
                    }
                    const resp = await updateSubCommands(id, query)
                }
                toast.success("Fichier enregistré")
                setTimeout(async () => {
                    const value = "toggle"
                    handleToggle(value)
                    //window.location.reload()
                    }, 2000)
            }catch(error){
                toast.error("Une erreur est survenue")
            }
          } else {
            toast.error("Lien Dropbox invalide ! Vérifier que vous avez le bon lien de partage dropbox");
          }
    };

    const handleChangeDbInput = (props) => {
        setInputDbValue(props.target.value)
    };

    return(
        <>
            <div className="containerColCenter" style={{ marginBottom: '65px' }} >
                <Row>
                    <div className="centerTitle" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <h3>Ajout d'un media DropBox</h3>
                        <div></div>
                    </div>
                </Row>
                <Row>
                    <Input 
                    className="inputMediaDB"
                    value={inputDbValue}
                    onChange={(event) => handleChangeDbInput(event)}
                    >
                    </Input>
                    <Button onClick={handleAddMediaDropBox} color="dark">Enregistrer</Button>
                </Row>
                <Row>
                    Ajouter ici le lien de partage de votre fichier dropbox (copier le lien d'accès au fichier disponible dans dropbox)
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
    userRoles: getUserRoles(state),
  });
  
export default connect(mapStateToProps)(FilesAddDbLink);