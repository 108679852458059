import React from "react";
import { Col, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import "ladda/dist/ladda-themeless.min.css";
import { WhFieldTxt } from "../../../components/Form/whFields";
import { required } from "../../../components/Form/whFields/WhValidator";
import cgu from "./cgu";
import "./cgu.css";
import CguField from "./CguField";

const CreatePasswordForm = ({ error, handleSubmit, submitting }) => {
  return (
    <form onSubmit={handleSubmit} className="create-password-form">
      {error && <Alert color="danger">{error}</Alert>}
      <Col>
        <div className="password-section">
          <Field
            name="passOld"
            placeholder="Entrer un nouveau mot de passe"
            component={WhFieldTxt}
            type="password"
            validate={required}
          />
          <Field
            name="passNew"
            placeholder="Confirmer le mot de passe"
            component={WhFieldTxt}
            type="password"
            validate={required}
          />
          <Field
            name="cguAccepted"
            component={CguField}
          />
        </div>
      </Col>
      <LaddaButton
        className="btn btn-gray btn-padded btn-fullWidth	"
        loading={submitting}
        data-style={EXPAND_LEFT}
        type={"submit"}
      >
        <i class="fa fa-paper-plane-o"></i>
      </LaddaButton>
    </form>
  );
};

export default reduxForm({
  form: "createPasswordForm",
  initialValues: {
    cguAccepted: false,
    passOld: "",
    passNew: "",
  },
})(CreatePasswordForm);
