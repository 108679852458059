import React, { Component } from "react";
import { Form, Col, Row, Collapse, Label } from "reactstrap";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change } from "redux-form";
import { WhFieldTxt, WhFieldSelectAsync } from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { fetchUsers } from "../../services/iam";
import { required, validEmail } from "../../utils/validators";
import { connect } from "react-redux";

class UserFormPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active: false
    };

    this.clearContact = this.clearContact.bind(this);
  }

  async loadSellerOptions(input) {
    return fetchUsers({
      roles: { $like: `%ROLE_SALER%` },
      $or: [
        { firstname: { $like: `%${input}%` } },
        { lastname: { $like: `%${input}%` } },
      ],
    }).then((result) => {
      const options = result.data.map((item) => ({
        value: item.id,
        label: `${item.firstname} ${item.lastname}`,
      }));

      return { options };
    });
  }

  handleToggle = () => {
    this.setState({active:!this.state.active})
  }

  clearContact(sectionName) {
    this.props.change(sectionName, null)
  }

  render() {
    const { handleSubmit, submitting, currentValues, formErrors, initialValues } = this.props;
    const active = this.state.active;
    return (
      <Form onSubmit={handleSubmit} className="form-horizontal">
        <ModalBody>
          <Row>
            <Col xs="12" sm="6">
              <Field
                label="Société : "
                name="name"
                component={WhFieldTxt}
                type="text"
                validate={[required]}
              />
            </Col>
            <Col xs="12" sm="6">
              <Field
                label="Raison sociale : "
                name="socialReason"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="4">
              <Field
                label="Téléphone : "
                name="phone"
                component={WhFieldTxt}
                type="text"
                validate={[required]}
              />
            </Col>
            <Col xs="12" sm="8">
              <Field
                label="Numéro siret : "
                name="siret"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                label="Email : "
                name="email"
                component={WhFieldTxt}
                type="text"
                validate={[required, validEmail]}
              />
              <Field
                label="Code Pennylane : "
                name="pennyCode"
                component={WhFieldTxt}
                type="text"

              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="accordion">
                <div className="accordionDiv" >
                  <div className="dropButton">
                    <i onClick={this.handleToggle} className={"fa fa-edit icon"} />
                  </div>
                  <Label className="accordionLabel" onClick={this.handleToggle}>Contact commercial : {initialValues.contactComId ? initialValues.contactComName : "Pas de contact"}</Label>
                </div>
                  <Collapse 
                    className="marg"
                    isOpen={active}>
                      <Field
                        label="Rechercher : "
                        name="contactComId"
                        component={WhFieldSelectAsync}
                        multi={false}
                        loadOptions={this.loadSellerOptions}
                        //validate={required}
                      />
                  </Collapse>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
                <div className="accordion">
                  Contact administratif : compta@myditex.com 
                </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
                <div className="accordion">
                  Contact expedition : logistique@myditex.com
                </div>
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <Field
                label="N°: "
                name="addressNumber"
                component={WhFieldTxt}
                type="text"
              />{" "}
            </Col>
            <Col xs="9">
              <Field
                label="Rue : "
                name="addressStreet"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                label="Complement : "
                name="addressComplement"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <Field
                label="Code postal : "
                name="zipCode"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
            <Col xs="5">
              <Field
                label="Ville : "
                name="city"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
            <Col xs="4">
              <Field
                label="Pays : "
                name="country"
                component={WhFieldTxt}
                type="text"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <LaddaButton
            className="btn btn-gray btn-padded btn-ladda"
            loading={submitting}
            data-style={EXPAND_LEFT}
            type={"submit"}
          >
            Enregistrer{" "}
          </LaddaButton>
        </ModalFooter>
      </Form>
    );
  }
}

const formName = "UserFormPopup"


UserFormPopup = reduxForm({
  form: formName
})(UserFormPopup);

const mapStateToProps = state => {

  return {
	currentValues: getFormValues(formName)(state),
  formErrors: getFormSyncErrors(formName)(state),
}};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (field, value) => dispatch(change(formName, field, value))
  }
}

export default connect(
	mapStateToProps,
  mapDispatchToProps
)(UserFormPopup);
