import React, { Component, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Header from "./Header/";
import Sidebar from "./Sidebar/";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import * as session from "../../utils/session";
import Dashboard from "../Dashboard/";
import DashboardCostumer from "../DashboardCostumer";
import { bindActionCreators } from "redux";
import { setUser, getUserRoles, getUser } from "../Authentication/redux";
import MyInformations from "../Account/MyInformations";
import MyPassword from "../Account/MyPassword";
import Users from "../Users";
import { setParams } from "../../reducers/params";
import Loading from "../../components/Loading";

import Customers from "../Customers";
import StockGestion from "../StockGestion";
import Manufactures from "../Manufactures";
import PriceManufacture from "../PriceManufactures";
import Commands from "../Commands";
import Files from "../Files";
import jwtDecode from "jwt-decode";
import { isBefore } from "date-fns";
import parameters from "../../constants/parameters";
import API_CONFIG from "../../constants/ApiConfig";
import roles from "../../constants/roles";
import CommandsHistory from "../Account/CommandsHistory";
import InProgressCommands from "../Account/InProgressCommands";
import ProductSelection from "../commandTunnel/productSelection";
import Basket from "../Account/basket/basket";
import TissuIndex from "../commandTunnel/tissuIndex";
import ConfectionIndex from "../commandTunnel/confectionIndex";
import StockAccessoires from "../StockAccessoires";
import FabricProducts from "../FabricProducts";
import Gabarits from "../Gabarits";
import Maintenance from "../Parameters/maintenance";
import { fetchMaintenance } from "../../services/crm";
import MaintenanceScreen from "./maintenanceScreen";
import Banner from "../Parameters/banner";
import DataImportGestion from "../Parameters/dataImportGestion"
import ShippingDelay from "../Parameters/shippingDelay";
import ProductList from "../brandProducts/productsList";
import Jobs from "../JobsList/jobList"
import SpecificProducts from "../SpecificProducts/specificProducts";
import FastImport from "../Parameters/fastImport";

const Layout = (props) => {
  const [loaded, setLoaded] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    componentDidMount();
    checkMaintenance();
  }, []);

  const initializeJiraScript = () => {
    const script = document.createElement("script");

    script.src = parameters.JIRA_TICKET_COLLECTOR_SRC_URL;
    script.async = true;

    document.body.appendChild(script);
  };

  const componentDidMount = () => {
    checkAuthentication();
    if (API_CONFIG.environment === "staging") {
      initializeJiraScript();
    }
    
  };

  const checkMaintenance = async () => {
    const data = await fetchMaintenance()
    if(data.enabled === true){
      if(user.roles[0] === 'ROLE_USER'){
        navigate('/maintenanceScreen')
      }
    }
  }

  const checkAuthentication = async () => {
    const { setUser } = props;
    const user = session.getUserData();
    const token = session.getJwtToken();

    if (token && user) {
      const decodedToken = jwtDecode(token);
      const tokenExpiration = new Date(decodedToken.exp * 1000);
      const hasTokenExpired = isBefore(tokenExpiration, new Date());

      if (hasTokenExpired) {
        toast.info("Votre session a expiré, veuillez vous reconnecter");
        navigate("/login");
      } else {
        setLoaded(true);
        setUser(user);
      }
    } else {
      toast.info("Pour accéder à cette page, veuillez vous reconnecter");
      navigate("/login");
    }
  };

  const { userRoles, user } = props;

  if (!loaded) return <Loading />;

  const checkRoles = (...rolesToCheck) => {
    const match = rolesToCheck.find((role) => userRoles.includes(role));

    return Boolean(match);
  };

  return (
    <div className="app">
      <Header />
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} />
      <div className="app-body">
        <Sidebar {...props} />
        <main className="main">
          {/* <Breadcrumb /> */}
          <Container fluid>
            <Routes>
              {user &&
                checkRoles(
                  roles.ROLE_ADMIN,
                  roles.ROLE_SALER,
                  roles.ROLE_USER
                ) && (
                  <Route
                    path="/dashboard"
                    name="Dashboard"
                    element={<Dashboard />}
                  />
                )}
              <Route path="/home" name="Home" element={<DashboardCostumer />} />
              {user && checkRoles(roles.ROLE_ADMIN, roles.ROLE_SALER) && (
                <Route
                  path="/customers"
                  name="Customers"
                  element={<Customers />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN) && (
                <Route
                  path="/products_manufactures"
                  name="Confectionné"
                  element={<Manufactures />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN, roles.ROLE_SALER) && (
                <Route
                  path="/fabrics"
                  name="Tissus"
                  element={<FabricProducts />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN, roles.ROLE_SALER) && (
                <Route
                  path="/specific-products"
                  name="Produits spécifiques"
                  element={<SpecificProducts />}
                />
              )}
              {user && checkRoles(roles.ROLE_USER) && (
                <Route path="/fabrics" name="Tissus" element={<TissuIndex />} />
              )}
              {user && checkRoles(roles.ROLE_USER) && (
                <Route
                  path="/products_manufactures"
                  name="Produits confectionnés"
                  element={<ConfectionIndex />}
                />
              )}
              <Route
                path="/maintenance"
                name="Maintenance"
                element={<Maintenance />}
              />
              <Route
                path="/shipping-delay"
                name="Shipping delay"
                element={<ShippingDelay/>}
              />
              <Route
                path="/data-import"
                name="Data import"
                element={<DataImportGestion />}
              />
              <Route
                path="/information-banner"
                name="Information banner"
                element={<Banner />}
              />
              <Route
                path="/commands"
                name="Produits manufacturés"
                element={<Commands />}
              />
              <Route
                path="/files"
                name="Produits manufacturés"
                element={<Files />}
              />
              <Route
                path="/jobs"
                name="Liste des jobs"
                element={<Jobs />}
              />
              <Route
                path="/gabarits"
                name="Gabarits"
                element={<Gabarits/>}
              />
              <Route
                path="/account/informations"
                name="Mon compte"
                element={<MyInformations />}
              />
              <Route
                path="/brand-products/:id"
                name="Produits marques"
                element={<ProductList />}
              />
              <Route
                path="/account/cmd-history"
                name="Historique de commandes"
                element={<CommandsHistory />}
              />
              <Route
                path="/account/cmd"
                name="Commandes en cours"
                element={<InProgressCommands />}
              />

              <Route
                path="/product-selection"
                name="Commander"
                element={<ProductSelection />}
              />

              <Route
                path="/account/basket"
                name="Mon panier"
                element={<Basket />}
              />
              <Route
                path="/account/password"
                name="Mon compte"
                element={<MyPassword />}
              />
              {user && checkRoles(roles.ROLE_ADMIN) && (
                <Route
                  path="/users"
                  name="Gestion des membres"
                  element={<Users />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN) && (
                <Route
                  path="/stocks"
                  name="Gestion des stocks tissus"
                  element={<StockGestion />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN) && (
                <Route
                  path="/fast-import"
                  name="Import dropbox"
                  element={<FastImport />}
                />
              )}
              {user && checkRoles(roles.ROLE_ADMIN) && (
                <Route
                  path="/accessoires"
                  name="Gestion des stocks accessoires"
                  element={<StockAccessoires />}
                />
              )}
              {/* {user && userRoles.includes("ROLE_ADMIN") && (
                <Redirect from="/" to="/dashboard" />
              )}
              {!user ||
                (!userRoles.includes("ROLE_ADMIN") && (
                  <Redirect from="/" to="/home" />
                ))} */}
            </Routes>
          </Container>
        </main>
      </div>
      {/* 				<Footer />
       */}{" "}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  userRoles: getUserRoles(state),
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, setParams }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
