import React, { Component, useState } from "react";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change, Form } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";
import { WhFieldTxt } from "../../../components/Form/whFields";

import { required, validDate } from "../../../utils/validators";

class CommandsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          active: false
        };
      }
      
    render() {
        const { error, handleSubmit, submitting, roles, initialValues, currentValues } = this.props;
        
        return(
            <Form onSubmit={handleSubmit} className="form-horizontal">
                <Field
                    label="Entreprise de livraison :"
                    name="deliverName"
                    component={WhFieldTxt}
                    type="text"
                />
                <Field
                    label="Numéro de suivi :"
                    name="trackingNumber"
                    component={WhFieldTxt}
                    type="text"
                />
                <Field
                    label="Poid du colis :"
                    name="deliverWeight"
                    component={WhFieldTxt}
                    type="text"
                />
                <Field
                    label="Date d'expédition :"
                    name="deliverDate"
                    component={WhFieldTxt}
                    type="text"
                    validate={[required, validDate]}
                />
                
                <LaddaButton
                    className="btn btn-gray btn-padded btn-ladda submitParam"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    Enregistrer
                </LaddaButton>
            </Form>
        )
    }
}

const formName = "DeliveryForm"


CommandsForm = reduxForm({
  form: formName
})(CommandsForm);

const mapStateToProps = state => {
  return {
	currentValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
}};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (field, value) => dispatch(change(formName, field, value))
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(CommandsForm);