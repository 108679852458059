import React, { useMemo, useState, useEffect } from "react";
import { Card, Button, Input } from "reactstrap";
import Moment from "react-moment";
import EuroFormat from "../../../components/EuroFormat/EuroFormat";
import Table from "../../../components/Table";
import useSubCommandService from "../../../hooks/services/useSubCommandService";
import useModal from "../../../hooks/useModal";
import EditPopup from "./EditPopup";
import roles from "../../../constants/roles";
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Collapse, Label } from "reactstrap";
import { fetchBase64, fetchBase64Media, fetchOrganisations, updateSubCommands } from "../../../services/crm";
import { getUser, getUserRoles } from "../../Authentication/redux";
import { connect } from "react-redux";
import Select from "react-select";
import { fetchProducts } from "../../../services/pim";
import { toast } from "react-toastify"
import Loading from "../../../components/Loading";
import FilesAdd from "../../Files/filesAdd";
import CommandsForm from "../../Account/commands/commandsForm";
import FilesAddDbLink from "../../Files/filesAddDbLink";
import FormPopup from "./FormPopup";
import FormStatus from "./FormStatus";
import ShippingInfo from "../../Account/commands/shippingInfo";
import FileUploadLink from "../../Files/fileUploadLink";

const SubCommandsList = (props) => {

  const {userRoles, commandId, isSaler, userId} = props
  const isAdmin = userRoles.includes(roles.ROLE_ADMIN);
  const isClient = userRoles.includes(roles.ROLE_USER);
  const [filter, setFilter] = useState({});
  const [selectedData, setSelectedData] = useState();
  const { toggle, active } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedStatutValue, setSelectedStatutValue] = useState(null);
  const [inputStatutValue, setInputStatutValue] = useState('')
  const [array, setArray] = useState([])
  const [isClosed, setIsClosed] = useState(props.isClosed ? props.isClosed : false)

  const StatutOptions = [
      {
          value:5,
          label: "A traiter"
      },
      {
          value:10,
          label: "Confirmée par Myditex"
      },
      {
          value:20,
          label: "Refusée non conforme"
      },
      {
          value:30,
          label: "Fichiers ok"
      },
      {
          value:100,
          label: "En impression transfert"
      },
      {
          value:110,
          label: "En transfert"
      },
      {
          value:120,
          label: "En cours de confection"
      },
      {
          value:130,
          label: "En visite"
      },
      {
          value:140,
          label: "En défaut"
      },
      {
          value:200,
          label: "À expédier"
      },
      {
          value:210,
          label: "Expédiée"
      },
      {
          value:230,
          label: "Reçue par le client"
      },
      {
          value:300,
          label: "À facturer"
      },
      {
          value:310,
          label: "Facturée"
      },
      {
          value:320,
          label: "En attente de paiement"
      },
      {
          value:330,
          label: "En retard de paiement"
      },
      {
          value:340,
          label: "Clôturée"
      },
  ]
  
  let arraySelected = []
  const { loadData, onSubmit, onDelete, data, pagination } = useSubCommandService({
    selectedData,
    toggle,
    filter,
    isSaler,
    userId,
    isCommand:false,
    commandId,
    selectedValue,
    selectedStatutValue
  });

  const onEdit = (data) => {
    setSelectedData(data);
    toggle();
  };

  const handleSubmit = async (props) => {
    const newStatus = props.status
    const newComment = props.mdtComment
    const enclos = props.enclosComment

    if(array.length === 0){
      toast.error("Veuillez sélectionner au moins une sous commande")
    }else if(newStatus === undefined  && newComment === undefined){
      toast.error("Veuillez sélectionner un status ou entrer un commentaire")
    }else{
      await array.map(async (item, index) => {
        const payload = {
          status : newStatus ? newStatus.val : item.status,
          mdtComment: newComment ? newComment : item.mdtComment,
          enclosComment: enclos ? enclos : null
        }
        try{
          await updateSubCommands(item.id,payload)
          loadData(1)
        }catch(error){
          console.log(error)
        }
      })
    }
  }

  const onClose = () => {
    setSelectedData();
    toggle();
  };

  const onSuccess = () => {
    loadData(1);
    toggle();
  };

  const handleView = (props) => {
    onEdit(props)
  }

  const handleInputChange = (value) => {
    setInputValue(value)
  }
  
  const handleCollapse = () => {
      setIsOpen(!isOpen)
      setSelectedValue(null)
    }
    
  const handleChange = (value) => {
    setSelectedValue(value.target.value)
  }

  const handleInputStatutChange = (value) => {
    setInputStatutValue(value)
  }

  const handleStatutChange = (value) => {
    setSelectedStatutValue(value)
  }
  
    useEffect(() => {
      loadData(1)
  },[selectedValue, selectedStatutValue])

  const tagParameters = [
    {
      label: "Nouvelles commandes",
      name: "prise_de_besoin",
      statu: 10,
      query: {
        status: {
          $lt: 100,
        },
      },
    },
    {
      label: "Expédition",
      name: "expedition",
      statu: 210,
      query: {
        status: {
          $gte: 200,
        },
      },
    },
    {
      label: "Production",
      name: "production",
      statu: 100,
      query: {
        status: {
          $gte: 100,
          $lt: 200,
        },
      },
    },
  ];

  const [selectedTag, setSelectedTag] = useState(null);

  const columns = useMemo(
    () => [
      {
        id: "productRef",
        accessor: "productRef",
        Header: "Réference",
      },
      {
        id: "status",
        accessor: "statusName",
        Header: "Statut",
        Cell: ({ row }) => {
          const [isChecked, setChecked] = useState(false);
          let statusName
          
          if(row.original.encloseRaisonName){
            statusName = row.original.encloseRaisonName
          }

          const handleCheckboxChange = () => {
            if(isChecked === false){
              arraySelected.push({id:row.original.id, status:row.original.status})
            }else{
              arraySelected.map((item,index) => {

                if(item.id === row.original.id){
                  arraySelected.splice(index, 1)
                }
              })

            }
            setChecked(!isChecked);
            setArray(arraySelected)
          };
          
          
          if(isClient == true){
              return (
                <span className={`label status-${row.original.statusCustomer}`}>
                {row.original.statusCustomerName ? row.original.statusCustomerName : null }
              </span>
                
            )
          }else{
            return (
              <>
                {commandId ? 
                <input
                  type="checkbox"
                  className="checkboxStatus"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                :null
                }
                <span className={`label status-${row.original.status}`}>
                    {statusName ? statusName : row.values.status}
                </span>
              </>
            );
          }
        },
      },
      {
        id: "date",
        Header: "Date de création",
        accessor: "date",
        Cell: (row) => <Moment date={row.value} format={"DD/MM/YYYY"} />,
      },
      {
        id: "information",
        accessor: "comment",
        Header: "Informations",
        Cell: (row) => {
          const { toggle, active } = useModal();
          let isDefault

          const handleViewMore = () => {
            toggle()
          }

          const mdtComment = row.row.original.mdtComment ? row.row.original.mdtComment : null
          const enclosComment = row.row.original.enclosComment ? row.row.original.enclosComment : null
          const clientComment = row.row.original.comment ? row.row.original.comment : null
          let isClientComment = false, isEnclosComment = false, isMdtComment = false


          switch(true){
            case enclosComment != null :
              isEnclosComment = true
              break;
            case clientComment != null :
              isClientComment = true
              break;
            case mdtComment != null:
              isMdtComment = true
              break;
            default:
              console.log("default")
          }

          if(row.row.original.encloseRaison != null){
            isDefault = true
          }else{
            isDefault = false
          }

          return(
            <>
              <div onClick={handleViewMore} className="divInfo">
                {isDefault ? <i class='fa fa-warning'></i> : null}
                {isAdmin ? 
                  <div style={{ whiteSpace: 'pre-wrap' }}> 
                    {isDefault ? enclosComment : isClientComment ? clientComment : isAdmin ? isMdtComment ? mdtComment : null : null} 
                  </div> 
                : 
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {isDefault ? enclosComment : isClientComment ? clientComment : null}
                  </div> }
              </div>
              <Modal
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}>
              <ModalHeader toggle={toggle}>
                    <h1 className="modalTitle">Informations de la commande</h1>
              </ModalHeader>
                  {isDefault ? <i class='fa fa-warning'></i> : null}
                    <div className="informationModal">
                      <li className="infoList">
                          {
                            clientComment ?
                            <>
                              <div className="commentTitle">
                                Commentaire client :
                              </div>
                              <ul style={{ whiteSpace: 'pre-wrap' }} className="comment">
                                  {clientComment}            
                              </ul>
                            </>

                            :null
                          }
                          {
                            enclosComment ?
                            <>
                              <div className="commentTitle">
                                Commentaire défaut :
                              </div>
                              <ul style={{ whiteSpace: 'pre-wrap' }} className="comment">
                                  {enclosComment}            
                              </ul>
                            </>

                            :null
                          }
                          {
                            mdtComment && isAdmin ?
                            <>
                              <div className="commentTitle">
                                Commentaire Myditex :
                              </div>
                              <ul style={{ whiteSpace: 'pre-wrap' }} className="comment">
                                  {mdtComment}            
                              </ul>
                            </>

                            :null
                          }
                      </li>
                    </div>
                <ModalFooter></ModalFooter>
              </Modal>
            </>
          )
        },
      },
      /* {
        id: "metrage",
        accessor: "metrage",
        Header: "Métrage",
        Cell: (row) => {
          const metrage = row.row.original.metrage
          let toHide
          if(metrage === 0){
            toHide = true;
          }else{
            toHide = false
          }
          return(
            <div className={"text-center"}>
              {!toHide ? `${metrage.toFixed(2)} ML` : null}
            </div>

          )
        },
      }, */
      {
        id: "tissu",
        Header: "Tissu",
        accessor: "tissuId",
        Cell: (row) => {
          return(
            <>
              {row.row.original.support}
            </>
          )
        }

      },
      {
        id: "media",
        Header: "Media",
        accessor: "mediaid",
        Cell: (row) => {
          const { toggle, active } = useModal();
          const [indexToggle, setIndexToggle] = useState(0);
    
          const colorArray = [
            { 
              value:"#19411f",
              label:"C1"
            },
            { 
              value:"#f2c908",
              label:"C2"
            },
            { 
              value:"#95211b",
              label:"C3"
            },
            { 
              value:"#9f9c91",
              label:"C4"
            },
            { 
              value:"#cf5f00",
              label:"C5"
            },
            { 
              value:"#0d375e",
              label:"C6"
            },
            { 
              value:"#ddcca8",
              label:"C7"
            },
            { 
              value:"#e09713",
              label:"C8"
            },
            { 
              value:"#cecdcd",
              label:"C9"
            },
            { 
              value:"#85a497",
              label:"C10"
            },
            
          ]
          const isCatalogue = row.row.original.isCatalogue
          let isCustom = false
          let isMedia = false
          let isMediaUpload = false
          let mediaName, finalMedia, colorCode, idPicto
    
          if(row.row.original.mediaPath){
            const mediaPathArray = row.row.original.mediaPath.split('/')
            mediaName = mediaPathArray[mediaPathArray.length -1]
          }
    
          if(row.row.original.media){
            isMedia = true
            finalMedia = row.row.original.media.name
            if(row.row.original.media.url === null){
              isMediaUpload = false
            }else{
              isMediaUpload = true
            }
          }
    
    
    
          if(row.row.original.customText || row.row.original.customColor || row.row.original.customPicto){
            isCustom = true
            if(row.row.original.customColor){
              colorArray.map((color, index) => {
                if(color.value === row.row.original.customColor){
                  colorCode = color.label
                }
              })
            }
    
            if(row.row.original.customPicto){
              const picto = JSON.parse(JSON.parse(row.row.original.customPicto))
              idPicto = picto.label
            }
    
          }
          const handleCopyClipBoard = (value) => {
            if(row.row.original.media.url !== null){
              if(value.mediaPath){
                const path = value.mediaPath.split('/')
                const parsedPath = path.pop()
                const finalPath = path.join('/')
                const pathToCopy = `\\Myditexserveur\dropbox${finalPath}`
                navigator.clipboard.writeText(pathToCopy)
                toast.success("Chemin d'accès au dossier copié dans le presse papier")
              }
            }
          }
    
          const  handleOpenAddMedia = () => {
            setIndexToggle(0)
            toggle()
          }
    
          const handleLinkMedia = (value) => {
            setIndexToggle(1)
            toggle()
          }
        
          const handleToggle = (value) => {
            if(value === "toggle"){
              loadData(1)
              toggle()

            }
          }
          const handleAddFile = async (media) => {
            const id = row.row.original.id
            let payload
            if(media.id){
              payload = {
                mediaId:media.id
              }
            }
    
            if(media.mediaId){
              payload = {
                mediaId:media.mediaId
              }
            }
            try{
              await updateSubCommands(id,payload)
              setTimeout(() => {
                loadData()
              }, 2000);
            }catch(error){
              toast.error("Une erreur est survenue")
            }
    
          }
          return(
            <>
              <div className="mediaDivSubCo">
                  {
                    isCatalogue ?
                      isCustom ?
                        isAdmin ?
                          <div onClick={handleOpenAddMedia}>
                            {!isMedia ? "❌" : finalMedia}
                          </div>
                        :
                          null
                      :
                      <div>
                        {mediaName}
                      </div>
                    :
                    isMediaUpload ? 
                    <div>
                      {finalMedia}
                    </div>
                    :
                    isAdmin ?
                      <div >
                        <div>
                          {finalMedia}
                        </div>
                        <Button onClick={() => handleLinkMedia(row.row.original)}>
                            ❌
                        </Button>
                      </div>
                      :
                      <div>
                      {finalMedia}
                    </div>
                  }
              </div>
    
              <Modal 
                isOpen={active}
                toggle={toggle}
                style={{maxWidth: '1200px', width: '100%'}}>
                <ModalHeader>
                  {indexToggle === 0 ? <h1 className="modalTitle">Récap customisation</h1> : null}
                  {indexToggle === 1 ? <h1 className="modalTitle">Ajout du média</h1> : null}
                </ModalHeader>
                <ModalBody>
                  {indexToggle === 0 ? 
                    <>
                      <div className="dashMarg">
                        <h2>Informations sur la personalisation</h2>
                        {row.row.original.customColor ?
                          <Row>
                            Couleur : {row.row.original.customColor} / {colorCode}
                          </Row>
                        :null}
                        {row.row.original.customPicto ? 
                          <Row>
                            Picto : {idPicto}
                          </Row>
                        :null}
                        {row.row.original.customText ? 
                          <Row>
                            Texte personalisé : "{row.row.original.customText}"
                          </Row>
                        :null}
                        <Row>
                          <div>
                            Nom du template: {mediaName}
                          </div>
                          <div>
                            <Button
                              color={"transparent"}
                              onClick={() => handleCopyClipBoard(row.row.original)}
                            >
                              <i className={"fa fa-copy"} />
                            </Button>
                          </div>
                        </Row>
                      </div>
                      <div className="addCustomFile">
                        {!isMedia ? 
                        //<FilesAdd handleAddFile={handleAddFile} recupId={true}/>
                        <FilesAddDbLink commandId={row.row.original.id} handleToggle={handleToggle}/>
                        : 
                        <div className="customMediaName">
                          {finalMedia}
                        </div>}
                      </div>
                    </>
                    :
                    null
                  }
    
                  {indexToggle === 1 ? 
                    <>
                      <h2 className="stepMedia">Etape 1 : Télécharger le fichier </h2>
                          <div> Lien pour télécharger le fichier : </div>
                          <a href={row.row.original.media.downloadUrl}>{row.row.original.media.downloadUrl }</a>
                      <h2 className="stepMedia">Etape 2 : Ajouter le média sur le dropbox de myditex dans le dossier souhaité</h2>
    
                      <h2 className="stepMedia">Etape 3 : Lier le fichier dans l'erp </h2>
                          <FilesAddDbLink mediaId={row.row.original.media.id} handleToggle={handleToggle}/> 
                    </>
                    :
                    null
                  }
    
                </ModalBody>
                <ModalFooter>
    
                </ModalFooter>
              </Modal>
            </>
          )
        }
        
      },
      {
        id: "quantity",
        Header: "Quantité",
        accessor: "quantity",
        Cell: (row) => {
          let isMl = false
          if(row.row.original.type === 100){
            isMl = true
          }
          return(
            <div className={"text-center"}>
              {`${row.row.original.quantity.toFixed(2)} ${isMl ? "Ml": "Pièce(s)"}`}
            </div>
          )
        }
      },
      {
        id: "realQuantity",
        Header: "Quantité réel",
        accessor: "realQuantity",
        Cell: (row) => {
          let isMl = false
          if(row.row.original.type === 100){
            isMl = true
          }
          const realQuantity = row.row.original.realQuantity
          if(realQuantity !== null){
            return(
                <div className={"text-center"}>
                  {`${row.row.original.realQuantity} ${isMl ? "Ml": "Pièce(s)"}`}
                </div>
            )
          }else{
            return(
              <div>
  
              </div>
            )
          }
        }
      },
      {
        id: "totalPrice",
        Header: "Prix",
        accessor: "totalPrice",
        Cell: (row) => (
          <div className={"text-center"}>
            <EuroFormat value={row.value.toFixed(2)} />
          </div>
        ),
      },
      {
        id: "trackingNumber",
        Header: "Numéro de suivi",
        accessor: "trackingNumber",
        Cell: (row) => {
          const tracking = row.row.original.trackingNumber
          let isLink = false
          if(tracking){
            if(tracking.substring(0, 4) === "http"){
              isLink = true
            }
          }
        
          return(
            <div className={"text-center"}>
              {isLink ? 
                <a className="linkVanilla" href={tracking}>{tracking}</a>
                :
                tracking
              }
            </div>
          )
        }
        
      },
      {
        id: "action",
        Header: "Action",
        accessor: "action",
        Cell: (row) => {
          const urlMedia = row.row.original.fileUrlBucket
          var path = require('path')
          const pathExt = path.extname(urlMedia)
          const [loading, setLoading] = useState(false)
          const [file, setFile] = useState()
          const [finalExt, setFinalExt] = useState()
          const { toggle, active } = useModal();
          const [index, setIndex] = useState(0);
          const [contact, setContact] = useState(null);
          
          const contactInfo = async () => {
            const query = {id : row.row.original.organisationId}
            const contact = await fetchOrganisations(query)
            setContact(contact.data[0])
          }

          const onSubmitDeliverInfo = async (props) => {
            const payload = {
              status: props.status,
              deliverName: props.deliverName ? props.deliverName : null,
              deliverDate: props.deliverDate ? props.deliverDate : null,
              deliverWeight: props.deliverWeight ? props.deliverWeight : null,
              trackingNumber: props.trackingNumber ? props.trackingNumber : null
            }
        
            try{
              await updateSubCommands(props.id, payload)
              loadData(1)
              toggle()
            }catch(error){
                console.log(error)
            }
          }
          const handleViewMedia = async () => {
            if(row.row.original.weight < 150000000){
              setIndex(0)
              toggle() 
              if(row.row.original.mediaId){
                setLoading(true)
                const res = await fetchBase64Media(row.row.original.mediaId)
                const ext = pathExt.substring(1)
                const newFinalExt = ext.slice(0, 3)
                setFinalExt(newFinalExt)
                setFile(res)
                setLoading(false)
              }
            }else{
              toast.error("Le média est trop lourd pour être visonné ou téléchargé")
            }
          }


          const handleViewShipping = async () => {
            contactInfo()
            setSelectedData(row.row.original)
            setIndex(1)
            toggle()
          }

          const handleCopyClipBoard = (value) => {
            if(value.isCatalogue === true){
              if(value.media){
                const path = value.media.path.split('/')
                const parsedPath = path.pop()
                const finalPath = path.join('/')
  
                navigator.clipboard.writeText(finalPath)
                toast.success("Chemin d'accès copié dans le presse papier")
              }else{
                const path = value.mediaPath.split('/')
                const parsedPath = path.pop()
                const finalPath = path.join('/')
  
                navigator.clipboard.writeText(finalPath)
                toast.success("Chemin d'accès copié dans le presse papier")
              }
            }else{
              if(value.media){
                const path = value.media.path.split('/')
                const parsedPath = path.pop()
                const finalPath = path.join('/')
  
                navigator.clipboard.writeText(finalPath)
                toast.success("Chemin d'accès copié dans le presse papier")
              }else{
                toast.error("Aucun media")
              }
            }
          }

          const handleDownloadMedia = async () => {
              setLoading(true)
              if(row.row.original.mediaId){
                  //Cas d'un fichier sur dropbox
                  var a = document.createElement("a");
                  a.href = `data:image/${finalExt};base64,` + file;
                  a.download = `${row.row.original.ref}-media.${finalExt}`;
                  a.click();
                  setLoading(false)
                  toggle()
              }else{
                //Cas d'une fichier sur bucket
                const res = await fetchBase64(row.row.original.id)
                setFile(res)
                if(pathExt === ".eps"){
                  var a = document.createElement("a");
                  a.href = "data:image/eps;base64," + file;
                  a.download = `${row.row.original.ref}-media.eps`;
                  a.click();
                  setLoading(false)
                  toggle()
                }else{
                  const ext = pathExt.substring(1)
                  const newFinalExt = ext.slice(0, -5)
                  setFinalExt(newFinalExt)
                  var a = document.createElement("a");
                  a.href = `data:image/${newFinalExt};base64,` + file;
                  a.download = `${row.row.original.ref}-media.${newFinalExt}`;
                  a.click();
                  setLoading(false)
                  toggle()
  
                }
              }
          }
          return(
            <div className={"text-center"}>
              <Row>
                {isAdmin ?
                  <>
                    <Button
                      color={"transparent"}
                      onClick={() => handleCopyClipBoard(row.row.original)}
                    >
                      <i className={"fa fa-copy"} />
                    </Button>
                    <Button
                      color={"transparent"}
                      onClick={() => handleViewShipping(row.row.original)}
                    >
                      <i class='fas fa-shipping-fast'></i>
                    </Button>
                  {!isClosed ?
                    <>
                      <Button
                        color={"transparent"}
                        onClick={() => handleView(row.row.original)}
                      >
                        <i className={"fa fa-edit"} />
                      </Button>

                    </>
                  : null}

                  </>
                : null}
                <Button
                  color={"transparent"}
                  onClick={() => handleViewMedia(row.row.original)}
                >
                  <i className={"fa fa-eye"} />
                </Button>
              </Row>
                <Modal
                    isOpen={active}
                    toggle={toggle}
                    style={{maxWidth: '1200px', width: '100%'}}
                >

                      {index === 0 ?
                        <>
                          <ModalHeader toggle={toggle}>
                              <h1 className="modalTitle">Média de la sous commande</h1>
                          </ModalHeader>
                          <ModalBody>
                            {pathExt === ".eps"  || finalExt === "eps" ? 
                              loading ?  null : <div>Le media de la commande est un .eps et ne peut pas être affiché</div>
                              :
                              loading ? null : <img src={`data:image/${finalExt};base64,${file}`} className="media" alt="Pas de media pour cette commmande"/>
                            }
                            {loading ? <Loading/>:null}
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={handleDownloadMedia} className={"btn btn-gray btn-padded"}>
                              Télécharger
                            </Button>
                          </ModalFooter>
                        </>
                      :null}
                      {index === 1 ?
                        <>
                          <ModalHeader toggle={toggle}>
                                <h1 className="modalTitle">Informations de livraison  </h1>
                            </ModalHeader>
                          <ModalBody>
                            {isClosed ? 
                              <ShippingInfo selectedData={row.row.original} contact={contact} isSubCo={true}/>
                            :
                              <>
                                <div className="widthInfo">
                                  <div>{`Adresse de livraison : ${row.row.original.deliveryAddress}`}</div>
                                  <div>{contact ? contact.email ? `Email client : ${contact.email}` : "Aucun email" : null}</div>
                                  <div>{contact ? contact.phone ? `Téléphone client : ${contact.phone}` : "Aucun numéro de téléphone" : null}</div>
                                </div>
                                <CommandsForm 
                                  initialValues={row.row.original}
                                  onSubmit={onSubmitDeliverInfo}
                                  onClose={onClose}
                                  onSuccess={onSuccess}
                                  /> 
                              </>
                            }
                          </ModalBody>
                          <ModalFooter>
                          </ModalFooter>
                        </>
                      :null}
                </Modal>   
            </div>
          )
        }
      },
    ],
    []
  );

  return (
    <>
      {commandId ? 
      null
      :
        <>
          <div className={"row"}>
            <div className="dashMarg">Sélectionnez un tag : </div>
            {tagParameters.map((param, index) => {
              const isChecked = selectedTag === param.name;

              const handleClick = () => {
                if (isChecked === false) {
                  setFilter(param.query);
                  setSelectedTag(param.name);
                } else {
                  setFilter({});
                  setSelectedTag(null);
                }
              };

              return (
                <div className="margeResp">
                  <Button
                    className={`label status-${param.statu} dashMarg`}
                    color={isChecked ? "green" : "gray-600"}
                    key={index}
                    onClick={handleClick}
                  >
                    {param.label}
                  </Button>
                </div>
              );
            })}
          </div>
          <Col xs="10">
            <Button className="filterButton" color={isOpen ? "teal" : "gray" } onClick={handleCollapse}>Filtres</Button>
            <Collapse isOpen={isOpen} className="filterButton">
              <Col>
                <Row className="filterRow">
                <Label className="filterLabel">Recherche par référence produit</Label>
                <Input
                    placeholder="Référence"
                    type="text"
                    className="searchSaler"
                    onChange={handleChange}
                    value={selectedValue}
                />  
                 
                </Row>
                  <Row>
                    <Label className="filterLabel">Recherche par statut </Label>
                    <Select
                        placeholder="Statut"
                        noResultsText="Pas de résultat"
                        className="searchSaler"
                        onInputChange={handleInputStatutChange}
                        onChange={handleStatutChange}
                        value={selectedStatutValue}
                        options={StatutOptions}
                    />  
                  </Row>
              </Col>
              </Collapse> 
            </Col>
        </>
      }
      <EditPopup 
          data={selectedData}
          active={active}
          onClose={onClose}
          onSuccess={onSuccess}
          onSubmit={onSubmit}/>
      <Card>
        <Table
          data={data}
          columns={columns}
          loadData={loadData}
          paginated={commandId ? false : true}
          pagination={pagination}
          customFilters={filter}
        />
      {commandId ? 
        isAdmin && isClosed === false ? 
        <>
          <FormStatus onSubmit={handleSubmit} initialValues={"none"}/>
        </>
        :null
      :null}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(SubCommandsList);
