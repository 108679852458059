export const save = (name, value, booléan) => {
    if(booléan === false){
        value = JSON.stringify(value);
        localStorage.setItem(name, value);
    }else{
        let roles
        if(typeof value.roles === "string"){
            roles = JSON.parse(value.roles)

        }else{
            roles = value.roles
        }
        
        if(roles.length > 1){
            let isAdmin
            let isSaler
            let isClient
    
            roles.map((role, index) => {
                if(role === "ROLE_ADMIN"){
                    isAdmin = true
                }
    
                if(role === "ROLE_SALER"){
                    isSaler = true
                }
    
                if(role === "ROLE_USER"){
                    isClient = true
                }
            }) 
            
            switch(true){
                case isAdmin === true:
                    value.roles = ["ROLE_ADMIN"]
                break
                case isSaler === true:
                    value.roles = ["ROLE_SALER"]
                break
                case isClient === true:
                    value.roles = ["ROLE_USER"]
                break
                default : console.log("default")
            }
            
            value = JSON.stringify(value);
            localStorage.setItem(name, value);
        }else{
            value = JSON.stringify(value);
            localStorage.setItem(name, value);
        }
    }

};

export const get = (name) => {
    let value = localStorage.getItem(name);

    if (!value) return false;

    let parsing = JSON.parse(value);

    if (parsing) {
        return parsing;
    } else {
        return value;
    }
};

export const destroy = (name) => {
    localStorage.removeItem(name);
};