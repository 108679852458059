import React, { useEffect, useState } from "react";
import useModal from "../../hooks/useModal";
import Modal from "react-modal";
import { Button, Row } from "reactstrap";
import { toast } from "react-toastify";
import { fetchMetrage } from "../../services/crm";
import MetrageIndicator from "../Dashboard/metrageIndicator";

const RefSelection = () => {
  const items = JSON.parse(sessionStorage.getItem("Refs"))
  const [selectedRef, setSelectedRef] = useState([]);
  const [toggleStorage, setToggleStorage] = useState(false);
  const [allRef, setAllRef] = useState([]);
  const [data, setData] = useState([{}])
  const { toggle, active } = useModal();

  const loadData = async () => {
    const refSelection = JSON.parse(sessionStorage.getItem('Refs'));
    
    if(refSelection){
        const data = [] = await fetchMetrage();
        let newData = []
        refSelection.map((refSelect, index) => {
            data.map((ref, index) => {
                if(ref.productRef === refSelect){
                    newData.push(ref)
                }
            })
        })
        newData.sort((a, b) => (a.total < b.total) ? 1 : -1)
        setData(newData)
    }else{
        const results = await fetchMetrage();
        setData(results)
    }
    setToggleStorage(!toggleStorage)
};

  const fetchFabricsRefs = async () => {
    
    const data = await fetchMetrage();
    if(items){
      
      const allRefs = data.map((refs) =>{
        selectedRef.map((ref) => {
          if(ref === refs.productRef){
            return refs.productRef
          }
        })
      })
    }else{
      const allRefs = data.map((refs) => refs.productRef);
      setAllRef(allRefs);
    }
  };

  useEffect(() => {
    {items ? setSelectedRef(items) : setSelectedRef([])}
    fetchFabricsRefs();
    loadData()
  }, []);

  const handleClick = () => {
    toggle();
  };

  const handleClickSave = () => {
    if(selectedRef.length == 3){
      sessionStorage.removeItem("Refs");
      sessionStorage.setItem("Refs", JSON.stringify(selectedRef));
      toggle();
      setToggleStorage(!toggleStorage)
      toast.success("Changements enregistrées");
    }else if(selectedRef.length == 0){
      sessionStorage.removeItem("Refs");
      setToggleStorage(!toggleStorage)
      toggle()
      toast.success("Changements enregistrées");
    }else{
      toast.error("Il faut sélectionner 0 ou 3 références")
    }
  };

  const handleClickAdd = (index) => {
    if (selectedRef.length < 3) {
      setSelectedRef([...selectedRef, allRef[index]]);
      const newAllRef = [...allRef];
      newAllRef.splice(index, 1);
      setAllRef(newAllRef);
      toast.success("référence enregistrée");
    } else {
      toast.error("Il y a déjà 3 références");
    }
  };

  const handleClickRemove = (index) => {
    setAllRef([...allRef, selectedRef[index]]);
    const newSelectedRef = [...selectedRef];
    newSelectedRef.splice(index, 1);
    setSelectedRef(newSelectedRef);
    toast.success("référence retirée");
  };
  return (
    <>
      <div>
        <MetrageIndicator isUpdate={toggleStorage} data={data}/>
        <button className="refButton" onClick={handleClick}>
          Sélectionnez une référence
        </button>
      </div>
      <div>
        <Modal
          className="modalDash"
          isOpen={active}
          onRequestClose={toggle}
        >
          <button className="refButton" onClick={toggle}>
            x
          </button>
          <h2 className="dashMarg">Sélectionnez une référence</h2>
          <h3 className="dashMarg">Mes 3 références affichées</h3>
          {selectedRef ? (
            <div>
              {selectedRef.map((ref, index) => {
                return (
                  <Button
                    className="marg"
                    key={index}
                    onClick={(e) => handleClickRemove(index, e)}
                  >
                    {ref}
                  </Button>
                );
              })}
            </div>
          ) : (
            <div>Pas de référence sélectionnées</div>
          )}
          <h3 className="dashMarg">Mes autres références</h3>
          <div>
            {allRef.map((ref, index) => {
              return (
                <>
                  <Button
                    className="marg"
                    key={index}
                    onClick={(e) => handleClickAdd(index, e)}
                  >
                    {ref}
                  </Button>
                </>
              );
            })}
          </div>
          <button className="refButton dashMarg" onClick={handleClickSave}>
            Enregistrer
          </button>
        </Modal>
      </div>
    </>
  );
};

export default RefSelection;
