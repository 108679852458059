import { update } from "lodash";
import { useState } from "react";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import { fetchAccessory, createAccessory, updateAccessory, deleteAccessory, fetchProducts, updatePrices, createProduct, createPrices, deleteProduct, deletePrices } from "../../services/pim";

const useAccessoryService = ({selectedData, toggle, isCreated}) => {
    const toUpdate = isCreated
    const [data, setData] = useState([]); 

    const loadStock = async () => {
        const result = await fetchAccessory();
        const data = result.data
        const newData = data.sort((a, b) => ((a.stock / a.stockMaxi) * 100 ) - ((b.stock / b.stockMaxi) * 100 ) )
        setData(newData)
    };

    const onDelete = async (data) => {
        try { 
            const confirmed = window.confirm(
              `Êtes vous sûr de vouloir supprimer l'accessoire ${data.label} ?`
            );
            if (confirmed) {
                const query = {
                    accessoryId: data.id,
                    type:300
                }
                let accesProduct
                await Promise.all([accesProduct = await fetchProducts(query)])

                await deletePrices(accesProduct.data[0].myditexPrices.id)
                await deleteProduct(accesProduct.data[0].id)
                await deleteAccessory(data.id)
                

            }
              loadStock();
              toast.success("L'accessoire a été supprimé avec succès");
            
        } catch (error) {
            console.log(error)
            toast.error("Erreur");
        }
    };
 
    const onSubmit = async (newData) => {
        let accessProduct
        if(selectedData){
            const query = {
                accessoryId: newData.id,
                type:300
            }
    
            accessProduct = await fetchProducts(query)
        }

        let payload

            if(newData.addStock && !newData.unStock){
                const newStock = parseInt(newData.stock) + parseInt(newData.addStock);
                payload = {
                    stock: newStock,
                    stockAlert: newData.stockAlert,
                }
            } else if(newData.unStock && !newData.addStock){
                const newStock = (parseInt(newData.stock) - parseInt(newData.unStock));
                payload = {
                    stock: newStock,
                    stockAlert: newData.stockAlert,
                }
            } else if(newData.addStock && newData.unStock){
                const newStock = (parseInt(newData.stock) + parseInt(newData.addStock)) - parseInt(newData.unStock);
                payload = {
                    stock: newStock,
                    stockAlert: newData.stockAlert,
                }
            }else{
                payload = {
                    ...newData,
                    price: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                }
            }

        try {
            if(selectedData){
                await updateAccessory(selectedData.id, payload)

                const pricePayload = {
                    price1: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price2: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price3: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price4: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price5: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price6: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price7: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                    price8: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.myditexPrices,
                }
                await updatePrices(accessProduct.data[0].myditexPrices.id, pricePayload)

            }else{

                const createdAcces = await createAccessory(payload)

                const productPayload = {
                    description: "",
                    reference: `ACCES${newData.ref}`,
                    type:300,
                    genericReference: newData.ref,
                    accessoryId: createdAcces.id
                }
                const createdProduct = await createProduct(productPayload)

                const pricePayload = {
                    organisationId:null,
                    priceType:"price",
                    productId: createdProduct.id,
                    price1: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price2: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price3: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price4: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price5: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price6: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price7: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                    price8: typeof newData.price === "string" ? newData.price.replace(/,/g, ".") : newData.price,
                }

                await createPrices(pricePayload)

            }
            loadStock()
            toggle();
            /* window.location.reload(); */
        } catch (error) {
            toast.error("Une erreur est survenue")
            throw new SubmissionError(error);
        }; 
    };

    return {
        data,
        loadStock,
        onDelete,
        onSubmit,
        toUpdate
    };
};

export default useAccessoryService;
