import React, { Component, useState } from "react";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change, Form } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { WhFieldTxt } from "../../../components/Form/whFields";
import { FieldCheckbox } from "../../../components/Form/whFields/FieldCheckbox";
import { Collapse } from "reactstrap";
import { required } from "../../../utils/validators";

class DeliveryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          active: false
        };
      }
      
    render() {
        const { error, handleSubmit, submitting, roles, initialValues, currentValues } = this.props;
        const active = this.state.active;

        const handleToggleCollapse = () => {
            this.setState({active:!this.state.active})
        }
        
        return(
            <Form onSubmit={handleSubmit} className="form-horizontal">
                <Field
                    label="Adresse de facturation :"
                    name="billAddress"
                    component={WhFieldTxt}
                    type="text"
                    validate={[required]}
                />
                <Collapse
                    isOpen={!active}
                    >
                    <Field
                        label="Adresse de livraison :"
                        name="deliveryAddress"
                        component={WhFieldTxt}
                        type="text"
                    />
                </Collapse>
                <Field
                    label="Retirer ma commande sur place"
                    name="pickupInfo"
                    component={FieldCheckbox}
                    value={active}
                    onChange={() => handleToggleCollapse()}
                    type="checkbox"
                    className="checkBoxBanner"
                />
                <LaddaButton
                    className="btn btn-gray btn-padded btn-ladda submitParam"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    Valider ma commande
                </LaddaButton>
            </Form>
        )
    }
}

const formName = "DeliveryForm"


DeliveryForm = reduxForm({
  form: formName
})(DeliveryForm);

const mapStateToProps = state => {
  return {
	currentValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
}};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (field, value) => dispatch(change(formName, field, value))
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(DeliveryForm);