import React, { Component } from "react";
import { Button } from "reactstrap";
import * as downloadService from "../../services/download";
import WHTable from "../../components/WHTable";
import configApi from "../../constants/ApiConfig";


class Download extends Component {
	state = {
		loaded: false,
		data: [],
	};

	download = document => {
		window.open(`${configApi.url}/media/download/`+document,'_blank');
	};

	columns = () => {
		return [
			{
				Header: "",
				accessor: "path",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"info"}
							onClick={() =>
								this.download(row.value)
							}
						>
							<i className={"fa fa-download"} />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "name",
				accessor: "filename",
			},
		];
	};

	load = async state => {
		this.setState({
			loaded: false,
		});

		try {
			const data = await downloadService.search(state);

			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data,
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
					});
				},
			);
		} finally {
			this.setState({ loaded: true });
		}
	};

	render() {
		const { data, loaded, pages } = this.state;
		return (
			<WHTable
				data={data}
				pages={pages}
				columns={this.columns()}
				filterable={false}
				onFetchData={this.load}
				loading={!loaded}
			/>
		);
	}
}

export default Download;
