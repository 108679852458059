import React, { Component, useState } from "react";
import { Field, getFormSyncErrors, getFormValues, reduxForm, change, Form } from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { WhFieldTxt } from "../../../components/Form/whFields";
import { FieldCheckbox } from "../../../components/Form/whFields/FieldCheckbox";
import { Collapse } from "reactstrap";
import { required } from "../../../utils/validators";
import { dateAfterToday } from "../../../utils/validators";

class WantedDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          active: false
        };
      }
      
    render() {
        const { error, handleSubmit, submitting, roles, initialValues, currentValues } = this.props;

        
        return(
            <Form onSubmit={handleSubmit} className="form-horizontal">
                <Field
                    label="Date souhaitée :"
                    name="date"
                    component={WhFieldTxt}
                    type="date"
                    validate={[dateAfterToday]}
                />
                <LaddaButton
                    className="btn btn-gray btn-padded btn-ladda submitParam"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    Suivant
                </LaddaButton>
            </Form>
        )
    }
}

const formName = "WantedDate"


WantedDate = reduxForm({
  form: formName
})(WantedDate);

const mapStateToProps = state => {
  return {
	currentValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
}};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (field, value) => dispatch(change(formName, field, value))
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(WantedDate);