import React,{ useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { SubmissionError } from "redux-form";
import { fetchSubCommands, updateSubCommands } from "../../services/crm";

const useSubCommandService = ({ selectedData, toggle, filter, isSaler, userId, isCommand, commandId, selectedValue, selectedStatutValue}) => {
  const [pagination, setPagination] = useState();
  const [data, setData] = useState([]);
  const loadData = async (pageNumber, limit = 50) => {
    let query
    if(commandId){
      if(isSaler === true){
        query = { commandId:commandId, $skip: (pageNumber - 1) * limit, $limit: limit, salerId: userId, ...filter };
      }else{
        query = { commandId:commandId, $skip: (pageNumber - 1) * limit, $limit: limit, ...filter };
      }
    }else{
      if(isSaler === true){
        query = { $skip: (pageNumber - 1) * limit, $limit: limit, salerId: userId, status:{$lt:340}, ...filter};
      }else{
        query = { $skip: (pageNumber - 1) * limit, $limit: limit, status:{$lt:340}, ...filter};
      }
    }

    if(selectedValue){
      query = {$or: [
        { productRef: { $like: `%${selectedValue}%` } },
      ], ...query}
    }

    if(selectedStatutValue){
      query = {...query, status: selectedStatutValue.value}
    }

    try{
      const { data, ...paginationParams } = await fetchSubCommands(query);
      setPagination(paginationParams);
      setData(data);

    }catch(error){
      console.log(error)
    }

  }

  const onDelete = async (product) => {
  };
 
  const onSubmit = async (newData) => {
    let payload
    if(newData.id){
      if(newData.status){
        if(newData.status.parentVal){
          payload = { 
            status: newData.status.parentVal, 
            trackingNumber: newData.trackingNumber ? newData.trackingNumber : null,
            deliverName: newData.deliverName ? newData.deliverName : null,
            encloseRaison: newData.status.val, 
            enclosComment: newData.enclosComment ? newData.enclosComment : null,
            mdtComment: newData.mdtComment ? newData.mdtComment : null,
            realQuantity: newData.realQuantity ? newData.realQuantity : null,
          }
    
        }else{
    
          payload = { 
            status: newData.status.val, 
            enclosComment: newData.enclosComment ? newData.enclosComment : null,
            trackingNumber: newData.trackingNumber ? newData.trackingNumber : null,
            deliverName: newData.deliverName ? newData.deliverName : null,
            mdtComment: newData.mdtComment ? newData.mdtComment : null,
            realQuantity: newData.realQuantity ? newData.realQuantity : null,
          }
        }
      }else{
        payload = {
          realQuantity: newData.realQuantity ? newData.realQuantity : null,
        }
      }
    }else{
      console.log("ici", newData)
    }
    
      try{
       await updateSubCommands(selectedData.id, payload);
       toggle();
       loadData(1);
       toast.success("Enregistrement réussi");
      }catch(error){
        throw new SubmissionError(error);
      }
  };

  return {
    pagination,
    data,
    loadData,
    onDelete,
    onSubmit,
    
  };
};

export default useSubCommandService;
