import { fetch } from "../../utils/fetch.old";
import QueryBuilder from "../../components/WHTable/queryBuilder";

const endPoints = "/api/products";

export const search = (data) => {
	let query = QueryBuilder(data);

	query.type = 200;

	return fetch(endPoints, "get", query);
};

export const iri = (iri) => fetch(`${iri}`, "get");

export const view = (id) => fetch(`${endPoints}/${id}`, "get");

export const create = (data) => fetch(`${endPoints}`, "post", data);

export const update = (data) => fetch(`${endPoints}/${data.id}`, "put", data);

export const remove = (id) => fetch(`${endPoints}/${id}`, "delete", {});
