import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "./FormPopup";
import * as productService from "../../../services/manufacture";
import Loading from "../../../components/Loading";

class EditPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            data : {}, 
            loaded : true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);

    }

    
    handleOpen = (id = null) => {
        this.setState({
        create : true,
          open: true, 

        });
        if (id) {
          this.setState({
            create : false,
            loaded: false,
          });
    
          productService.view(id).then((data) => {
            this.setState({
              data,
              loaded: true,
            });
          });
        }
      };

      handleClose = () => {
        this.props.onClose();
      };


    render() { 
        const {className, active, data } = this.props;
        const {loaded} = this.state;
        return (
            <Modal 
                isOpen={active} 
                className={className} 
                fade={false}
                toggle={this.handleClose}
            >
                <ModalHeader toggle={() => this.handleClose()}>Editer la sous commande</ModalHeader>
            
                {!loaded && <Loading />}
                {loaded && 
                <>
                  {data ? <h2 className="centerTitle">{`${data.ref}`}</h2> : null}
                  <FormPopup onSubmit={this.props.onSubmit} initialValues={data} />
                </>}
            </Modal>
        );
    }
}

export default EditPopup;
