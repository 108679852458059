import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import TissuIndex from "./tissuIndex";
import ConfectionIndex from "./confectionIndex";
import confectionPicto from "../../assets/picto/confectionPicto.png"
import tissuPicto from "../../assets/picto/tissuPicto.png"
import { fetchBrands, fetchProducts, updateCustomPrices } from "../../services/pim";
import { getUser, getUserRoles } from "../Authentication/redux";
import { connect } from "react-redux";
import * as session from "../../utils/session";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import ProdSpecificIndex from "./prodSpecificIndex";

const ProductSelection = (props) => {
    const roles = props.user.roles
    const orgaId = props.user.organisationId
    const [choice, setChoice] = useState(false);
    const [tissu, setTissu] = useState(false);
    const [confection, setConfection] = useState(false);
    const [specifique, setSpecifique] = useState(false);
    const [brands, setBrands] = useState([]);
    const [isBrandClient, setIsBrandClient] = useState(false);
    const token = session.getJwtToken();
    const decodedToken = jwtDecode(token);
    const [isProductSpe, setIsProductSpe] = useState(false)

    let Navigate = useNavigate();

    useEffect(() => {
        loadBrands()
        checkProductSpe()
    }, [])

    const handleClick = (type) => {
        setChoice(true);

        switch(type){
            case "tissu": 
                setTissu(true)
            break;
            case "confection": 
                setConfection(true)
            break;
            case "specific":
                setSpecifique(true)
            break;
        }
    }

    const checkProductSpe = async () => {
        const query = {
            organisationId : orgaId
        }
        const data = await fetchProducts(query)
        if(data.data[0]){
            setIsProductSpe(true)
        }else{
            setIsProductSpe(false)
        }
    }
    const loadBrands = async () => {
        const data = await fetchBrands()
        JSON.parse(decodedToken.roles).map((role, index) => {
            
            if(role === "ROLE_USERB2C" || role === "ROLE_USERB2B2C"){

                setIsBrandClient(true)
            }
        })
        const brandList = data.data.map((brand, index) => {
            return(
                <>
                    <div className="divMarqueCatalogue">
                        <div>
                            <img className="imageMarque" src={brand.visual} alt="Error" onClick={() => handleClickBrand(brand.id)}></img>
                        </div>
                    </div>
                </>
            )
        })
        setBrands(brandList)
    }
    
    const handleClickBrand = (props) => {
        Navigate(`/brand-products/${props}`)
    }
    
    return(
        <>
            {choice ? tissu ? <TissuIndex/> : confection ? <ConfectionIndex/> : specifique ? <ProdSpecificIndex/>: null : null}
            {isBrandClient ?
                <>
                    <div className="etapeActive">Nos marques</div>
                    <Row>
                        {brands}
                    </Row>
                </>
            :
                !choice ? 
                    <div>
                        <div className="etapeActive">Etape 1 : Choisissez un produit </div>
                        <Row>
                            <button className="iconSelection2" onClick={() => handleClick('tissu')}>
                                <img src={tissuPicto} className="tunnelPicto"></img>
                                <label className="labelPicto">ML / Tissu seul</label>
                            </button>
                            <button className="iconSelection2" onClick={() => handleClick('confection')}>
                                <img src={confectionPicto} className="tunnelPicto"></img>
                                <label className="labelPicto">Produits confectionnés</label>
                            </button>
                            {isProductSpe ? 
                                <button className="iconSelection2" onClick={() => handleClick('specific')}>
                                    <img src={confectionPicto} className="tunnelPicto"></img>
                                    <label className="labelPicto">Produits spécifiques</label>
                                </button>
                            :null}
                        </Row>
                    </div>
                : null 
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state),
});

export default connect(mapStateToProps)(ProductSelection);