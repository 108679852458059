import React, { useEffect, useState } from "react";
import EditPopup from "./EditPopup";
import useProductService from "../../hooks/services/useProductService";
import useModal from "../../hooks/useModal";
import { Card, CardHeader, CardFooter, Row, Label, Input } from "reactstrap";
import ProductList from "../../components/Products/ProductList";
import { fetchProducts } from "../../services/pim";

const Manufactures = () => {
  const [selectedData, setSelectedData] = useState();
  const { toggle, active } = useModal();
  const [isCreated, setIsCreated] = useState(false);
  const [inputProductValue, setInputProductValue] = useState('');
  const type = 200;
  const { loadData, onSubmit, onDelete, data, pagination, customPrices } = useProductService({
    selectedData,
    toggle,
    type,
    inputProductValue
  });


  const onEdit = (data) => {
    setSelectedData({...data, tissu:{label:data.manufacture.confections[0].fabric.ref, value: data.manufacture.confections[0].fabric.id}});
    toggle();
  };

  const onClose = () => {
    setIsCreated(false);
    setSelectedData();
    toggle();
  };

  const onCreate = () => {
    setIsCreated(true);
    setSelectedData();
    toggle();
  };

  const onSuccess = () => {
    loadData(1);
    toggle();
  };

  const handleProductChange = (event) => {
    setInputProductValue(event.target.value)
  }

    return (
      <div className="animated fadeIn">
        <EditPopup 
          data={{...selectedData, customPrices}}
          active={active}
          onClose={onClose}
          onSuccess={onSuccess}
          onSubmit={onSubmit}
          isCreated={isCreated} />

        <Card>
          <CardHeader>
            <h1>Produits confectionnés</h1>
            <a
              href={`/help/#/configuration?id=gestion-des-produits`}
              target={"_blank"}
              className={"card-header-tooltip"}
            >
              ?
            </a>
          </CardHeader>
          <Row className="filterRow">
            <Label className="filterLabel">Recherche par référence</Label>
            <Input
              placeholder="référence"
              className="searchSaler"
              onChange={handleProductChange}
              value={inputProductValue}
            /> 
          </Row>
          <ProductList 
            type="manufacture"
            loadData={loadData} 
            data={data}
            onEdit={onEdit}
            onDelete={onDelete}
            pagination={pagination} />

          <CardFooter>
            <div className="card-actions">
              <button
                onClick={onCreate}
                className={"btn btn-gray btn-padded"}
              >
                Ajouter
              </button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }


export default Manufactures;
